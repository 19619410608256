import React from 'react';

const GlobalContext = React.createContext(); // Global login and theme information

const month_converter = ["января", "февраля", "марта", "апреля", "мая", "июня", "июля", "августа", "сентября", "октября", "ноября", "декабря"];
const month_straight_converter = ["январь", "февраль", "март", "апрель", "май", "июнь", "июль", "август", "сентября", "октябрь", "ноябрь", "декабрь"];

function addLeadingZero(number) {
  const string_number = number.toString();
  if (string_number.length < 2) {
    return "0" + string_number;
  } else {
    return string_number;
  }
}

function dateConverter(unix_seconds_time) {
  // Дата формата 21 декабря[ 2021] в 21:21
  const date = new Date(unix_seconds_time * 1000);
  if (date.getFullYear() !== (new Date().getFullYear())) {
    return `${date.getDate()} ${month_converter[date.getMonth()]} ${date.getFullYear()} в ${addLeadingZero(date.getHours())}:${addLeadingZero(date.getMinutes())}`;
  } else {
    return `${date.getDate()} ${month_converter[date.getMonth()]} в ${addLeadingZero(date.getHours())}:${addLeadingZero(date.getMinutes())}`;
  }
}

function contestDateConverter(unix_seconds_time) {
  // Дата формата 21 декабря[ 2021], 21:21 МСК
  const date = new Date(unix_seconds_time * 1000).toLocaleString("ru-RU", { timeZone: "Europe/Moscow", });
  const parsed_date = date.split(", ")[0].split(".");
  const parsed_time = date.split(", ")[1].split(":");
  if (parseInt(parsed_date[2]) !== new Date().getFullYear()) {
    return `${parsed_date[0]} ${month_converter[parseInt(parsed_date[1]) - 1]} ${parsed_date[2]}, ${parseInt(parsed_time[0])}:${parsed_time[1]} МСК`;
  } else {
    return `${parsed_date[0]} ${month_converter[parseInt(parsed_date[1]) - 1]}, ${parseInt(parsed_time[0])}:${parsed_time[1]} МСК`;
  }
}

function juryConnectionDateConverter(unix_seconds_time) {
  // Дата формата [21 декабря [2021], ]21:21
  let date = new Date(unix_seconds_time * 1000);
  if (date.getDate() === new Date().getDate()) {
    date = new Date(unix_seconds_time * 1000).toLocaleString("ru-RU", {timeZone: "Europe/Moscow",});
    const parsed_time = date.split(",")[1].split(":");
    return `${parseInt(parsed_time[0])}:${parsed_time[1]}`;
  } else {
    date = new Date(unix_seconds_time * 1000).toLocaleString("ru-RU", {timeZone: "Europe/Moscow",});
    const parsed_date = date.split(",")[0].split(".");
    const parsed_time = date.split(",")[1].split(":");
    if (parseInt(parsed_date[2]) !== new Date().getFullYear()) {
      return `${parsed_date[0]} ${month_converter[parseInt(parsed_date[1]) - 1]} ${parsed_date[2]}, ${parseInt(parsed_time[0])}:${parsed_time[1]}`;
    } else {
      return `${parsed_date[0]} ${month_converter[parseInt(parsed_date[1]) - 1]}, ${parseInt(parsed_time[0])}:${parsed_time[1]}`;
    }
  }
}

function articleDateConverter(unix_seconds_time) {
  // Дата формата 21 декабря 2021
  const date = new Date(unix_seconds_time * 1000);
  return `${date.getDate()} ${month_converter[date.getMonth()]} ${date.getFullYear()}`;
}

function trophyDateConverter(unix_seconds_time) {
  // Дата формата декабрь 2021
  const date = new Date(unix_seconds_time * 1000);
  return `${month_straight_converter[date.getMonth()]} ${date.getFullYear()}`;
}

function adminSubmissionDateConverter(unix_seconds_time) {
  // Дата формата 21.12.2021, 21:21
  const date = new Date(unix_seconds_time * 1000);
  return `${addLeadingZero(date.getDate())}.${addLeadingZero(date.getMonth() + 1)}.${date.getFullYear()}, ${addLeadingZero(date.getHours())}:${addLeadingZero(date.getMinutes())}`;
}

function timeConverter(unix_seconds_time) {
  let result = [];
  const hours = Math.floor(unix_seconds_time / 3600);
  const minutes = Math.floor(unix_seconds_time % 3600 / 60);

  if (hours !== 0) {
    if ((hours % 100 >= 11 && hours % 100 <= 19) || (hours % 10 >= 5 || hours % 10 === 0)) {
      result.push(hours + " часов");
    } else if (hours % 10 >= 2) {
      result.push(hours + " часа");
    } else {
      result.push(hours + " час");
    }
  }
  if (minutes !== 0) {
    if ((minutes >= 11 && minutes <= 19) || (minutes % 10 >= 5 || minutes % 10 === 0)) {
      result.push(minutes + " минут");
    } else if (minutes % 10 >= 2) {
      result.push(minutes + " минуты");
    } else {
      result.push(minutes + " минута");
    }
  }
  if (hours === 0 && minutes === 0) {
    if ((unix_seconds_time >= 11 && unix_seconds_time <= 19) || (unix_seconds_time % 10 >= 5 || unix_seconds_time % 10 === 0)) {
      result.push(unix_seconds_time + " секунд");
    } else if (unix_seconds_time % 10 >= 2) {
      result.push(unix_seconds_time + " секунды");
    } else {
      result.push(unix_seconds_time + " секунда");
    }
  }
  return result.join(" ");
}

function updateSize() {
  let min_width = 1392;
  let window_width = document.body.offsetWidth;
  if (window_width < min_width) {
    let zoom_level = (window_width - 48) / min_width;
    document.documentElement.style.setProperty("--zoom-level", zoom_level);
  } else {
    document.documentElement.style.setProperty("--zoom-level", 1);
  }
  
  try { // Only for home page and after DOM loaded
    const more_button = document.getElementsByClassName("news-more-button")[0];
    more_button.style.minWidth = more_button.offsetHeight + "px";
  } catch (error) {
    if (!(error instanceof TypeError)) {
      throw error;
    }
  }
}

// Я не знаю как это рабоает
function a2b(a) {
  let b;
  let c;
  let d;
  let e = {};
  let f = 0;
  let g = 0;
  let h = "";
  let i = String.fromCharCode;
  let j = a.length;
  for (b = 0; 64 > b; b++) {
    e["ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/".charAt(b)] = b;
  }
  for (c = 0; j > c; c++) {
    for (b = e[a.charAt(c)], f = (f << 6) + b, g += 6; g >= 8; ) {
      ((d = 255 & f >>> (g -= 8)) || j - 2 > c) && (h += i(d));
    }
  }
  return h;
}

function parseToken(token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(a2b(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}

const titleEnding = " • Sort Me";

export default GlobalContext;
export { dateConverter, contestDateConverter, updateSize, timeConverter, juryConnectionDateConverter, articleDateConverter, adminSubmissionDateConverter, trophyDateConverter, parseToken, titleEnding };
