import clsx from 'clsx';
import React from 'react';
import { Link } from 'react-router-dom';
import MainButton, { SecondaryButton } from '../../../components/buttons';
import { LoaderNarrow } from '../../../components/loaders';
import fetchApi from "../../../utils/fetchApi";


export class PrivateTaskList extends React.Component {
  constructor(props) {
    super(props);

    this.handleCreateClick = async () => {
      const apiResponse = await fetchApi({
        url: "/createNewTask",
        method: "post",
      });
      this.props.history.push(`/tasks/${apiResponse.id}/easy-edit`);
    };

    this.state = {
      tasks: [],
      canCreateTasks: null,
      loading: true,
    };
  }

  getMoreTasks(props) {
    this.setState({
      loading: true,
      tasks: [],
    });
    fetchApi({
      url: "/getManagedTasks",
      method: "get",
      params: {
        "text": props.search || null,
      },
    }).then(result => {
      this.setState({
        tasks: result.tasks ?? [],
        canCreateTasks: result.can_create_tasks,
        loading: false,
      });
    });
  }

  render() {
    if (this.state.canCreateTasks && this.state.tasks.length === 0 && !this.state.loading) {
      return (
        <div className={clsx("task-list-visible", "empty-list")}>
          <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M24.0007 45.3573C36.5191 45.3573 46.6673 35.209 46.6673 22.6906C46.6673 10.1721 36.5191 0.0239258 24.0007 0.0239258C11.4822 0.0239258 1.33398 10.1721 1.33398 22.6906C1.33398 35.209 11.4822 45.3573 24.0007 45.3573Z" fill="#FFCB4C" />
            <path d="M19.3648 28.048C19.1715 27.8933 19.0208 27.6826 18.9488 27.4293C18.7702 26.816 19.1222 26.2053 19.7355 26.068C25.7728 24.7066 29.8382 27.8853 30.0102 28.0213C30.5222 28.4293 30.6222 29.148 30.2408 29.628C29.8595 30.1053 29.1368 30.1626 28.6262 29.7573C28.4795 29.6453 25.2622 27.1933 20.3835 28.2933C20.0182 28.3746 19.6448 28.272 19.3648 28.048Z" fill="#65471B" />
            <path d="M17.4915 18.4401C19.0563 18.4401 20.3249 16.8546 20.3249 14.8988C20.3249 12.9429 19.0563 11.3574 17.4915 11.3574C15.9267 11.3574 14.6582 12.9429 14.6582 14.8988C14.6582 16.8546 15.9267 18.4401 17.4915 18.4401Z" fill="#65471B" />
            <path d="M32.4993 19.8561C34.0642 19.8561 35.3327 18.2706 35.3327 16.3148C35.3327 14.3589 34.0642 12.7734 32.4993 12.7734C30.9345 12.7734 29.666 14.3589 29.666 16.3148C29.666 18.2706 30.9345 19.8561 32.4993 19.8561Z" fill="#65471B" />
            <path d="M23.0347 46.8654C23.0347 46.8654 24.7214 46.3174 24.94 45.0627C25.1707 43.7667 24.108 43.5067 24.108 43.5067C24.108 43.5067 25.496 43.2294 25.6707 41.6721C25.8347 40.2041 24.5227 39.8547 24.5227 39.8547C24.5227 39.8547 25.816 39.3214 25.8774 37.8027C25.928 36.5241 24.5507 35.8987 24.5507 35.8987C24.5507 35.8987 31.268 34.2707 31.9587 34.1107C32.6467 33.9507 33.7187 33.2907 33.384 31.8521C33.052 30.4121 31.7787 30.3614 31.1214 30.5147C30.4627 30.6681 22.1294 32.6027 19.2547 33.2721L17.336 33.7174C16.616 33.8867 16.2894 33.5707 16.7974 33.0347C17.4747 32.3201 17.908 31.5294 18.0587 30.2174C18.2174 28.8374 17.7494 27.1334 17.4814 26.4721C16.9827 25.2441 16.1414 24.2734 15.1694 23.9401C13.6534 23.4201 12.576 24.3681 13.1134 26.0214C13.9187 28.4934 13.3907 30.5214 12.0027 31.7454C8.73735 34.6214 7.21869 36.6721 8.22935 41.0427C9.33335 45.8094 14.0654 48.8774 18.832 47.7734L23.0347 46.8654Z" fill="#F19020" />
            <path d="M12.3944 8.468C12.1757 8.35067 11.9904 8.16934 11.8731 7.936C11.5851 7.36534 11.8197 6.7 12.3971 6.45334C18.0851 4.012 22.6624 6.396 22.8544 6.49867C23.4317 6.80667 23.6651 7.49467 23.3757 8.03734C23.0877 8.57734 22.3891 8.76534 21.8117 8.46134C21.6477 8.37734 18.0357 6.552 13.4411 8.524C13.0984 8.66934 12.7117 8.63734 12.3944 8.468ZM28.5744 13.468C28.3664 13.3373 28.1931 13.144 28.0917 12.9027C27.8424 12.3133 28.1224 11.6667 28.7157 11.4573C34.5571 9.40934 38.9624 12.0973 39.1464 12.2133C39.6997 12.56 39.8864 13.2627 39.5624 13.784C39.2397 14.304 38.5278 14.444 37.9744 14.1013C37.8144 14.0053 34.3384 11.94 29.6184 13.596C29.2638 13.7173 28.8811 13.6573 28.5744 13.468Z" fill="#65471B" />
          </svg>
          <h1>
            Хотите создать задачу?
          </h1>
          <p>
            Похвально! Эта функция в очень-очень ранней бете, пока что можно создавать лишь задачи в стиле ICPC, без чекеров или интеракторов.
          </p>
          <MainButton
            onClick={this.handleCreateClick}
            className="task-list__create-button--empty"
          >
            Создать задачу
          </MainButton>
        </div>
      );
    } else if (this.state.canCreateTasks !== null && !this.state.canCreateTasks) {
      return (
        <div className={clsx("task-list-visible", "empty-list")}>
          <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M24.0007 45.3573C36.5191 45.3573 46.6673 35.209 46.6673 22.6906C46.6673 10.1721 36.5191 0.0239258 24.0007 0.0239258C11.4822 0.0239258 1.33398 10.1721 1.33398 22.6906C1.33398 35.209 11.4822 45.3573 24.0007 45.3573Z" fill="#FFCB4C" />
            <path d="M19.3648 28.048C19.1715 27.8933 19.0208 27.6826 18.9488 27.4293C18.7702 26.816 19.1222 26.2053 19.7355 26.068C25.7728 24.7066 29.8382 27.8853 30.0102 28.0213C30.5222 28.4293 30.6222 29.148 30.2408 29.628C29.8595 30.1053 29.1368 30.1626 28.6262 29.7573C28.4795 29.6453 25.2622 27.1933 20.3835 28.2933C20.0182 28.3746 19.6448 28.272 19.3648 28.048Z" fill="#65471B" />
            <path d="M17.4915 18.4401C19.0563 18.4401 20.3249 16.8546 20.3249 14.8988C20.3249 12.9429 19.0563 11.3574 17.4915 11.3574C15.9267 11.3574 14.6582 12.9429 14.6582 14.8988C14.6582 16.8546 15.9267 18.4401 17.4915 18.4401Z" fill="#65471B" />
            <path d="M32.4993 19.8561C34.0642 19.8561 35.3327 18.2706 35.3327 16.3148C35.3327 14.3589 34.0642 12.7734 32.4993 12.7734C30.9345 12.7734 29.666 14.3589 29.666 16.3148C29.666 18.2706 30.9345 19.8561 32.4993 19.8561Z" fill="#65471B" />
            <path d="M23.0347 46.8654C23.0347 46.8654 24.7214 46.3174 24.94 45.0627C25.1707 43.7667 24.108 43.5067 24.108 43.5067C24.108 43.5067 25.496 43.2294 25.6707 41.6721C25.8347 40.2041 24.5227 39.8547 24.5227 39.8547C24.5227 39.8547 25.816 39.3214 25.8774 37.8027C25.928 36.5241 24.5507 35.8987 24.5507 35.8987C24.5507 35.8987 31.268 34.2707 31.9587 34.1107C32.6467 33.9507 33.7187 33.2907 33.384 31.8521C33.052 30.4121 31.7787 30.3614 31.1214 30.5147C30.4627 30.6681 22.1294 32.6027 19.2547 33.2721L17.336 33.7174C16.616 33.8867 16.2894 33.5707 16.7974 33.0347C17.4747 32.3201 17.908 31.5294 18.0587 30.2174C18.2174 28.8374 17.7494 27.1334 17.4814 26.4721C16.9827 25.2441 16.1414 24.2734 15.1694 23.9401C13.6534 23.4201 12.576 24.3681 13.1134 26.0214C13.9187 28.4934 13.3907 30.5214 12.0027 31.7454C8.73735 34.6214 7.21869 36.6721 8.22935 41.0427C9.33335 45.8094 14.0654 48.8774 18.832 47.7734L23.0347 46.8654Z" fill="#F19020" />
            <path d="M12.3944 8.468C12.1757 8.35067 11.9904 8.16934 11.8731 7.936C11.5851 7.36534 11.8197 6.7 12.3971 6.45334C18.0851 4.012 22.6624 6.396 22.8544 6.49867C23.4317 6.80667 23.6651 7.49467 23.3757 8.03734C23.0877 8.57734 22.3891 8.76534 21.8117 8.46134C21.6477 8.37734 18.0357 6.552 13.4411 8.524C13.0984 8.66934 12.7117 8.63734 12.3944 8.468ZM28.5744 13.468C28.3664 13.3373 28.1931 13.144 28.0917 12.9027C27.8424 12.3133 28.1224 11.6667 28.7157 11.4573C34.5571 9.40934 38.9624 12.0973 39.1464 12.2133C39.6997 12.56 39.8864 13.2627 39.5624 13.784C39.2397 14.304 38.5278 14.444 37.9744 14.1013C37.8144 14.0053 34.3384 11.94 29.6184 13.596C29.2638 13.7173 28.8811 13.6573 28.5744 13.468Z" fill="#65471B" />
          </svg>
          <h1>
            Хотите создать задачу?
          </h1>
          <p>
            Похвально! Но фича ещё в ранней бете и доступна не всем. Чтобы получить доступ, напишите на почту <a href="mailto: guys@sort-me.org">guys@sort-me.org</a>.
          </p>
        </div>
      );
    } else {
      return (
        <div className="task-list-visible">
          {this.state.tasks &&
            <table className="task-list__private-table" cellSpacing="0" style={{ borderCollapse: "separate", borderSpacing: "0 11.5px", }}>
              <thead>
                <tr>
                  <th style={{ width: "78px", }}>ID</th>
                  <th style={{ width: "869px", }}>Название</th>
                </tr>
              </thead>
              <tbody>
                {this.state.canCreateTasks && <tr>
                  <td colSpan={2}>
                    <SecondaryButton
                      className="task-list__create-button"
                      onClick={this.handleCreateClick}
                    >Создать новую задачу</SecondaryButton>
                  </td>
                </tr>}
                {this.state.tasks.map((element, index) => {
                  const bar = <svg width="6" height="20" viewBox="0 0 2 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 0V20" stroke="var(--subtask-stroke)" /></svg>;

                  return <tr className="tasklist-item" key={index} id={"task-" + element.id}>
                    <td>
                      <Link to={"/tasks/" + element.id + this.filter_query} className="tasklist-link-scrollable" />
                      <div className="tasklist-item-container tasklist-item-scrollable">
                        <p style={{ width: "56px", }}>
                          {element.id}
                        </p>
                      </div>
                    </td>
                    <td>
                      <Link to={{
                        pathname: "/tasks/" + element.id,
                        state: {
                          category: "private",
                          search: document.getElementById("task-list-search").value,
                        },
                      }} className="tasklist-link-scrollable" />
                      <div className="tasklist-item-container tasklist-item-scrollable">
                        {bar}
                        <p>{element.name}</p>
                      </div>
                    </td>
                  </tr>;
                })}
              </tbody>
            </table>}
          {this.state.loading && <LoaderNarrow width="60" height="60" className="tasklist-loader" />}
        </div>
      );
    }
  }
}
