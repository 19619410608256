import React from "react";

class Search extends React.Component {
  constructor(props) {
    super(props);

    this.timer = null;
  }

  updateTimer() {
    const value = this.props.id ? document.getElementById(this.props.id).value :
                  document.getElementsByClassName("search-field")[0].value;
    if (this.props.onValueChange) {
      this.props.onValueChange(value); 
    }
    
    clearTimeout(this.timer);

    if (!value) {
      this.props.onTypingEnd(value)
    } else {
      this.timer = setTimeout(() => this.props.onTypingEnd(value), 1000);
    }
  }

  componentDidUpdate() {
    clearTimeout(this.timer);
  }

  render() {
    return <form onSubmit={ event => {
        event.preventDefault();
        clearTimeout(this.timer);
        const value = this.props.id ? document.getElementById(this.props.id).value :
                  document.getElementsByClassName("search-field")[0].value;
        this.props.onTypingEnd(value);
      } }>
      <input
        defaultValue={this.props.value || ""}
        autoComplete="off"
        type="search"
        placeholder={this.props.placeholder}
        className={"search-field " + (this.props.className ? this.props.className : "")}
        id={this.props.id}
        onChange={() => this.updateTimer()}
      />
    </form>;
  }
}

export default Search;
