import { useMemo } from "react"
import styled from "styled-components"

import { ReactComponent as FolderIcon } from "../../static/other/folder.svg"

export default function SubmittedArchiveViewer({ descriptionString }) {
    const [filename, size, count] = useMemo(() => {
      const [filename, size, count] = descriptionString.split(":")
      return [filename, size, count]
    }, [descriptionString])

  const filesWord = useMemo(() => {
    return count >= 11 && count <= 19 ? "файлов"
      : count % 10 === 1 ? "файл"
      : count % 10 >= 2 && count % 10 <= 4 ? "файла"
      : "файлов"
  }, [count])

  const sizeString = useMemo(() => {
    if (size !== undefined) {
      if (size / 1024 ** 2 < 1)
        return `${Math.round(size / 1024)} КБ`
      return `${Math.round(size / 1024 ** 2)} МБ`
    }
  }, [size])

  return (
    <Wrapper>
      <FileDescription>
        <FolderIcon />
        <h4>{filename}</h4>
        <span>{sizeString}, {count} {filesWord}</span>
      </FileDescription>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background-color: var(--body-background);
  border: 1px solid var(--code-editor-outline);
  border-radius: 4px;
  transition: border-color var(--transition-timing) var(--transition-function),
    background-color var(--transition-timing) var(--transition-function);

  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 204px;

  margin-top: 32px;
`

const FileDescription = styled.div`
  display: grid;
  grid-template-areas:
    "icon name"
    ". size";
  column-gap: 14px;
  margin: auto auto;

  color: #888888;

  > svg {
    grid-area: icon;
  }

  > h4 {
    margin: 0;

    grid-area: name;
    font-family: PT-Root-UI-Medium, Tahoma, Sans-Serif;
    font-weight: 700;
    font-size: 16px;
  }

  > span {
    grid-area: size;
    font-family: PT-Root-UI-Medium, Tahoma, Sans-Serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
  }
`
