import { useState, useEffect, useMemo, useCallback } from 'react';
import { useParams, Link, useHistory } from 'react-router-dom';
import Helmet from 'react-helmet';
import useSWR, { useSWRConfig } from 'swr';
import clsx from 'clsx';
import TextareaAutosize from 'react-textarea-autosize';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';

import '../static/css/EasyTaskEdit.css';

import { dateConverter, titleEnding } from '../global';
import fetchApi from '../utils/fetchApi';

import Loader from '../components/loaders';
import MarkdownInput from '../components/markdownInput';
import MainButton from '../components/buttons';
import AdminList from '../components/adminlist';
import { LabeledNumberInput } from '../components/inputs';

function EasyTaskEdit({ taskData, error }) { 
  const { mutate } = useSWRConfig();

  const searchParams = new URLSearchParams(window.location.search);
  const [currentPage, setCurrentPage] = useState(searchParams.get("page") ?? "legend");

  /* Data inputs */
  const [title, setTitle] = useState(taskData?.name ?? "");
  const [legend, setLegend] = useState(taskData?.main_description ?? "");
  const [inputData, setInputData] = useState(taskData?.in_description ?? "");
  const [outputData, setOutputData] = useState(taskData?.out_description ?? "");
  const [samples, setSamples] = useState(taskData?.samples ?? [{ in: "", out: "", }]);
  const [comment, setComment] = useState(taskData?.comment ?? "");

  /* Current task data */
  const currentTaskData = useMemo(() => {
    return {
      ...taskData,
      name: title,
      main_description: legend,
      in_description: inputData,
      out_description: outputData,
      samples: samples,
      comment: comment,
    };
  }, [taskData, title, legend, inputData, outputData, samples, comment]);

  const setNewLegend = async () => {
    const apiResponse = await fetchApi({
      url: "/setTaskLegend",
      method: "POST",
      data: {
        task_id: currentTaskData.id,
        data: {
          name: currentTaskData.name || "empty",
          main_description: currentTaskData.main_description || "empty",
          in_description: currentTaskData.in_description || "empty",
          out_description: currentTaskData.out_description || "empty",
          samples: currentTaskData.samples,
          comment: currentTaskData.comment || "empty",
        },
      },
    }).catch(apiError => {
      return { error: apiError.response, };
    });
    mutate({
      url: "/getTaskById",
      method: "get",
      params: { id: taskData.id, similar: 0, }
    });
    return apiResponse;
  };

  useEffect(() => {
    if (taskData) {
      setTitle(taskData.name === "empty" ? "" : taskData.name);
      setLegend(taskData.main_description === "empty" ? "" : taskData.main_description);
      setInputData(taskData.in_description === "empty" ? "" : taskData.in_description);
      setOutputData(taskData.out_description === "empty" ? "" : taskData.out_description);
      setSamples(taskData.samples ?? [{ in: "", out: "", }]);
      setComment(taskData.comment === "empty" ? "" : taskData.comment)
    }
  }, [ taskData, ]);

  return <>
    <Helmet>
      <title>{"Редактирование задачи" + titleEnding}</title>
    </Helmet>

    <EditSidebar
      taskData={taskData === undefined ? undefined : currentTaskData}
      error={error}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
    />
    <EditMain
      taskData={taskData === undefined ? undefined : currentTaskData}
      error={error}
      title={title}
      setTitle={setTitle}
      legend={legend}
      setLegend={setLegend}
      inputData={inputData}
      setInputData={setInputData}
      outputData={outputData}
      setOutputData={setOutputData}
      samples={samples}
      setSamples={setSamples}
      comment={comment}
      setComment={setComment}
      currentPage={currentPage}
      setNewLegend={setNewLegend}
    />
  </>;
}

function SidebarButton({ pageName, verboseName, currentPage, setCurrentPage }) {
  const history = useHistory();

  return <button
    className="task-sidebar__link"
    onClick={() => {
      setCurrentPage(pageName);
      history.push({
        search: `page=${pageName}`,
      })
    }}
    disabled={currentPage === pageName}
  >{verboseName}</button>;
}

function EditSidebar({ taskData, currentPage, setCurrentPage }) {
  const buttons = {
    legend: "Легенда",
    tests: "Тесты",
    access: "Доступ",
  };

  const transformSidebarScroll = event => {
    const sidebar = document.getElementsByClassName("task-sidebar__scroll-wrapper")[0];
    sidebar.scrollTop += event.deltaY;
    event.preventDefault();
  };

  useEffect(() => {
    const sidebar = document.getElementsByClassName("task-sidebar__scroll-wrapper")[0];
    sidebar.addEventListener("wheel", transformSidebarScroll, { passive: false, });

    return () => {
      sidebar.removeEventListener("wheel", transformSidebarScroll, { passive: false, });
    };
  }, []);

  return <div className="task-edit-sidebar scalable">
    <div className="task-sidebar__scroll-wrapper">
      <Link 
        to={{
          pathname: "/tasks",
          state: {
            category: "private",
          },
        }}
      >← К списку задач</Link>
      {taskData && <>
        <p>Редактирование задачи {taskData.name && `"${taskData.name}"`} (ID {taskData.id})</p>
        <div className="task-sidebar__link-list">
          {Object.entries(buttons).map(([key, value]) => <SidebarButton
            key={key}
            pageName={key}
            verboseName={value}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />)}
        </div>
      </>}
    </div>
  </div>;
}

function EditMain(props) {
  const { taskData, error, currentPage } = props;

  if (!taskData && !error) {
    return <div className={clsx("task-edit-content", "scalable")}>
      <Loader />
    </div>;
  } else if (error) {
    return <div className={clsx("task-edit-content", "scalable", "has-error", "full-height")}>
      <h2>Упс... Такой задачи у нас пока нет</h2>
      <p>Возможно, у вас нет прав на редактирование этой задачи</p>
    </div>;
  } else {
    if (currentPage === "legend") {
      return <EditLegend {...props} />;
    } else if (currentPage === "tests") {
      return <EditArchive {...props} />;
    } else { // access
      return <EditAccess {...props} />;
    }
  }
}

function EditLegend({
  title,
  setTitle,
  legend,
  setLegend,
  inputData,
  setInputData,
  outputData,
  setOutputData,
  samples,
  setSamples,
  comment,
  setComment,
  setNewLegend
}) {
  const deleteSamples = index => {
    let newSamples = [ ...samples, ];
    if (samples.length > 1) {
      newSamples.splice(index, 1);
    } else {
      newSamples = [ { in: "", out: "", }, ];
    }
    setSamples(newSamples);
  };

  const appendSamples = () => {
    let newSamples = [ ...samples, ];
    newSamples.push({ in: "", out: "", });
    setSamples(newSamples);
  };

  return <div className={clsx("task-edit-content", "scalable")}>
    <MarkdownInput
      placeholder="Название задачи"
      value={title}
      setValue={setTitle}
      className="task-edit__title"
      placeholderClassName="title__placeholder"
      inputClassName="title__input"
      markdownClassName="title__markdown"
    />
    <MarkdownInput
      placeholder="Начните писать условие..."
      value={legend}
      setValue={setLegend}
      className="task-edit__legend"
      placeholderClassName="legend__placeholder"
      inputClassName="legend__input"
      markdownClassName="legend__markdown"
    />
    <div className="task-edit__block">
      <h3>Входные данные</h3>
      <MarkdownInput
        placeholder="В первой строке записано..."
        value={inputData}
        setValue={setInputData}
        className="task-edit__input-data"
        placeholderClassName="input-data__placeholder"
        inputClassName="input-data__input-data"
        markdownClassName="input-data__markdown"
      />
    </div>
    <div className="task-edit__block">
      <h3>Выходные данные</h3>
      <MarkdownInput
        placeholder="В единственную строку нужно вывести..."
        value={outputData}
        setValue={setOutputData}
        className="task-edit__output-data"
        placeholderClassName="output-data__placeholder"
        inputClassName="output-data__input"
        markdownClassName="output-data__markdown"
      />
    </div>
    <div className="task-edit__samples-block">
      <table cellSpacing={0} className="task-edit__samples-table">
        <thead>
          <tr>
            <th>STDIN</th>
            <th style={{ borderLeft: "10px solid var(--background)", transition: "border var(--transition-timing) var(--transition-function)", }}>STDOUT</th>
          </tr>
        </thead>
        <tbody>
          {samples.map((element, index) => {
            const inputSetter = event => {
              let newSamples = [ ...samples, ];
              newSamples[index] = { ...element, in: event.target.value, };
              setSamples(newSamples);
            };
            const outputSetter = event => {
              let newSamples = [ ...samples, ];
              newSamples[index] = { ...element, out: event.target.value, };
              setSamples(newSamples);
            };

            return <tr key={index}>
              <td><TextareaAutosize className="task-edit__sample-input" value={element.in} onChange={inputSetter} placeholder="пример входных данных" /></td>
              <td><TextareaAutosize className="task-edit__sample-input" value={element.out} onChange={outputSetter} placeholder="пример выходных данных" /></td>
              <td>
                <button onClick={() => deleteSamples(index)}>
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M9.41425 8.00001L12.7072 4.70701C13.0982 4.31601 13.0982 3.68401 12.7072 3.29301C12.3162 2.90201 11.6842 2.90201 11.2933 3.29301L8.00025 6.58601L4.70725 3.29301C4.31625 2.90201 3.68425 2.90201 3.29325 3.29301C2.90225 3.68401 2.90225 4.31601 3.29325 4.70701L6.58625 8.00001L3.29325 11.293C2.90225 11.684 2.90225 12.316 3.29325 12.707C3.48825 12.902 3.74425 13 4.00025 13C4.25625 13 4.51225 12.902 4.70725 12.707L8.00025 9.41401L11.2933 12.707C11.4882 12.902 11.7443 13 12.0002 13C12.2562 13 12.5122 12.902 12.7072 12.707C13.0982 12.316 13.0982 11.684 12.7072 11.293L9.41425 8.00001Z" fill="var(--tasklist-id)" transition="fill var(--transition-timing) var(--transition-function)" />
                  </svg>
                </button>
              </td>
            </tr>;
          })}
        </tbody>
      </table>
      <button
        className="task-edit__add-sample"
        onClick={appendSamples}>
        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M8 2.5C8.55228 2.5 9 2.94772 9 3.5V7.5H13C13.5523 7.5 14 7.94772 14 8.5C14 9.05228 13.5523 9.5 13 9.5H9V13.5C9 14.0523 8.55228 14.5 8 14.5C7.44772 14.5 7 14.0523 7 13.5V9.5H3C2.44772 9.5 2 9.05228 2 8.5C2 7.94772 2.44772 7.5 3 7.5H7V3.5C7 2.94772 7.44772 2.5 8 2.5Z" fill="var(--button-color)" transition="fill var(--transition-timing) var(--transition-function)" />
        </svg>
        Ещё пример
      </button>
    </div>
    <div className="task-edit__block">
      <h3>Примечание</h3>
      <MarkdownInput
        placeholder="Необязательно..."
        value={comment}
        setValue={setComment}
        className="task-edit__comment"
        placeholderClassName="comment__placeholder"
        inputClassName="comment__input"
        markdownClassName="comment__markdown"
      />
    </div>
    <MainButton
      className="task-edit__save-button"
      onClick={setNewLegend}
    >Сохранить</MainButton>
  </div>;
}

function EditArchive({ taskData }) {
  const [currentStatus, setCurrentStatus] = useState("ready");
  const [isDownloading, setIsDownloading] = useState(false);

  const { data: testData, error: testError } = useSWR({
    url: "/getTaskTestsInfo",
    method: "get",
    params: { "task_id": taskData.id, }
  }, fetchApi, {
    revalidateOnFocus: false,
    shouldRetryOnError: false,
    revalidateOnMount: true,
  });

  const { mutate } = useSWRConfig();

  const onDrop = useCallback(acceptedFile => {
    acceptedFile.forEach(file => {
      setCurrentStatus("loading");

      const reader = new FileReader();
      
      reader.onabort = () => setCurrentStatus("error");
      reader.onerror = () => setCurrentStatus("error");
      reader.onload = async () => {
        let formData = new FormData();
        formData.append("document", file);

        const apiResponse = await fetchApi({
          url: "/uploadTaskTests",
          method: "post",
          data: formData,
          params: { "task_id": taskData.id, },
          headers: { "Content-Type": "multipart/form-data", },
        }).catch(() => setCurrentStatus("error"));

        if (apiResponse?.status === "ok") {
          await mutate({
            url: "/getTaskTestsInfo",
            method: "get",
            params: { "task_id": taskData.id, },
          });
          setCurrentStatus("ready");
        }
      };

      reader.readAsDataURL(file);
    });
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, multiple: false, accept: ".zip, .7zip", });

  const downloadArchive = useCallback(async () => {
    setIsDownloading(true);
    const apiResponse = await axios({
      url: "/exportTests",
      baseURL: "https://api.sort-me.org",
      method: "get",
      params: { "task_id": taskData.id, },
      responseType: "blob",
    });

    const filename = JSON.parse(apiResponse.headers["content-disposition"].split("filename=")[1]);
    const fileDownload = require("js-file-download");
    await fileDownload(apiResponse.data, filename);
    setIsDownloading(false);
  }, []);

  if (!testData && !testError) {
    return <div className={clsx("task-edit-content", "scalable")}>
      <Loader />
    </div>
  } else if (testError) {
    return <div className={clsx("task-edit-content", "scalable", "full-height", "has-error")}>
      <h2>Не удалось загрузить тесты для задачи</h2>
      <p>Попробуйте перезагрузить страницу</p>
    </div>;
  } else if (testData.status === "empty" || currentStatus !== "ready") {
    return <div className={clsx("task-edit-content", "full-height", "scalable")}>
      <div {...getRootProps({
        className: "task-edit__drop-container"
      })}>
        <input {...getInputProps()} />
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M19 2C20.6569 2 22 3.34315 22 5V19C22 20.6569 20.6569 22 19 22H5C3.34315 22 2 20.6569 2 19V5C2 3.34315 3.34315 2 5 2H19ZM20 8H4V19C4 19.5523 4.44772 20 5 20H19C19.5523 20 20 19.5523 20 19V8ZM12.1168 17.9933L12.0001 18L11.916 17.996L11.7994 17.9798L11.688 17.9503L11.5769 17.9063L11.4794 17.854L11.4049 17.8037L11.293 17.7071L8.2929 14.7071C7.90237 14.3166 7.90237 13.6834 8.2929 13.2929C8.65339 12.9324 9.22064 12.9047 9.61294 13.2097L9.70715 13.2929L11 14.585L11.0001 11C11.0001 10.4872 11.3861 10.0645 11.8835 10.0067L12.0001 10C12.5524 10 13.0001 10.4477 13.0001 11L13 14.585L14.2931 13.2929C14.6535 12.9324 15.2208 12.9047 15.6131 13.2097L15.7073 13.2929C16.0678 13.6534 16.0955 14.2206 15.7905 14.6129L15.7073 14.7071L12.7072 17.7071L12.6217 17.7834L12.5519 17.8341L12.454 17.8913L12.3401 17.9407L12.2661 17.9642L12.1168 17.9933ZM19 4H5C4.44772 4 4 4.44772 4 5V6H20V5C20 4.44772 19.5523 4 19 4Z" fill="#888888" />
        </svg>
        <span>{(() => {
          if (isDragActive) {
            return <>Отпускайте!</>;
          } else if (currentStatus === "error") {
            return <>Произошла ошибка. Повторите загрузку</>;
          } else if (currentStatus === "loading") {
            return <>Загрузка...</>;
          } else {
            return <>Щёлкните или перетащите сюда ZIP с тестами</>;
          }
        })()}</span>
      </div>
    </div>;
  } else {
    return <div className={clsx("task-edit-content", "scalable")}>
      <div className="task-edit__loaded-message">
        <svg width="19" height="13" viewBox="0 0 19 13" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 6.21052L6.72063 12L18 1" stroke="var(--text)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" style={{ transition: "stroke var(--transition-timing) var(--transition-function)", }} />
        </svg>
        <p>Тесты загружены {dateConverter(testData.updated)}</p> 
      </div>
      <div className="task-edit__loaded-buttons">
        <button
          className="download"
          onClick={downloadArchive}
        >
          {isDownloading ? "Подождите..." : "Скачать архив"}
        </button>
        <button
          className="delete"
          onClick={() => setCurrentStatus("deleted")}
        >
          Удалить и загрузить новый
        </button>
      </div>
      <EditLimits
        taskId={taskData.id}
        time={taskData.time_limit_milliseconds}
        memory={taskData.memory_limit_megabytes} 
      />
    </div>;
  }
}

function EditLimits({ taskId, time = 1000, memory = 128 }) {
  const [timeError, setTimeError] = useState(null);
  const [memoryError, setMemoryError] = useState(null);

  return (
    <div className="task-edit__limits">
      <h3>Лимиты</h3>
      <div className="task-edit__limits-input-container">
        <LabeledNumberInput
          title="По времени (мс)"
          defaultValue={time}
          id="task-edit__time-input"
          max={10000}
          errorText={timeError}
          // errorText="Максимум – 10000 мс"
          onChange={() => setTimeError(false)}
        />
        <LabeledNumberInput
          title="По памяти (мб)"
          defaultValue={memory}
          id="task-edit__memory-input"
          max={512}
          errorText={memoryError}
          // errorText="Максимум – 512 мб"
          onChange={() => setMemoryError(false)}
        />
      </div>
      <MainButton
        className="task-edit__limits-submit"
        onClick={async () => {
          const timeLimit = parseInt(document.getElementById("task-edit__time-input").value);
          const memoryLimit = parseInt(document.getElementById("task-edit__memory-input").value);

          let hasError = false;
          if (timeLimit > 10000) {
            setTimeError("Максимум – 10000 мс")
            hasError = true;
          }
          if (memoryLimit > 512) {
            setMemoryError("Максимум – 512 мб")
            hasError = true;
          }
          if (isNaN(timeLimit)) {
            setTimeError("Введите число")
            hasError = true;
          }
          if (isNaN(memoryLimit)) {
            setMemoryError("Введите число")
            hasError = true;
          }
          
          if (!hasError) {
            return fetchApi({
              url: "/setTaskLimits",
              method: "post",
              params: {
                "task_id": taskId,
                "time": timeLimit,
                "memory": memoryLimit,
              }
            })
          }
        }}
      >
        Сохранить лимиты
      </MainButton>
    </div>
  )
}

function EditAccess({ taskData }) {
  const { mutate } = useSWRConfig()

  const [error, setError] = useState(null)

  const { data: admins } = useSWR({
    url: "/getTaskAdmins",
    method: "get",
    params: {
      "task_id": taskData.id,
    },
  }, params => fetchApi(params).then(response => response.admins), {
    revalidateOnFocus: false,
    shouldRetryOnError: false,
  })

  const addAdmin = useCallback(async () => {
    const apiResponse = await fetchApi({
      url: "/addAdminToTask",
      method: "post",
      params: {
        "task_id": taskData.id,
        "user_handle": document.getElementById("admin-list-add-input").value,
      },
    }).catch(() => setError("Пользователь не найден"))
    if (apiResponse) {
      mutate({
        url: "/getTaskAdmins",
        method: "get",
        params: {
          "task_id": taskData.id,
        },
      }, async () => apiResponse.admins, { revalidate: false, })
      document.getElementById("admin-list-add-input").value = ""
    }
  }, [ taskData, mutate, ])

  const deleteAdmin = useCallback(async id => {
    const apiResponse = await fetchApi({
      url: "/removeAdminFromTask",
      method: "post",
      params: {
        "task_id": taskData.id,
        "user_id": id,
      },
    }).catch(() => setError("Пользователь не найден"))
    if (apiResponse) {
      mutate({
        url: "/getTaskAdmins",
        method: "get",
        params: {
          "task_id": taskData.id,
        },
      }, async () => apiResponse.admins, { revalidate: false, })
    }
  }, [ taskData, mutate, ])

  if (!admins) {
    return (
      <div className={clsx("task-edit-content", "scalable")}>
        <Loader />
      </div>
    )
  } else {
    return (
      <div className={clsx("task-edit-content", "scalable")}>
        <AdminList 
          admins={admins}
          addFunc={addAdmin}
          deleteFunc={deleteAdmin}
          error={error}
          setError={setError}
          className="task-edit__admin-list"
        />
      </div>
    )
  }
}

export default function TaskEditWrapper(props) {
  const params = useParams()
  
  const { data: taskData, error } = useSWR({
    url: "/getTaskById",
    method: "get",
    params: { id: params.id, similar: 0, }
  }, fetchApi, {
    revalidateOnFocus: false,
    errorRetryInterval: 10000,
    errorRetryCount: 3,
    revalidateOnMount: true,
  })
  
  return (
    <EasyTaskEdit
      taskData={taskData?.task}
      error={error}
      {...props}
    />
  )
}
