import Button from '@material-ui/core/Button';
import React from 'react';
import { CheckBoxGroup } from '../../../components/checkbox';
import MyRadio from '../../../components/radio';
import Search from '../../../components/search';
import MySwitch from '../../../components/switch';
import _fetchApi from '../../../fetch';
import GlobalContext from '../../../global';
import { getTaskIcon } from '../Tasks';


export class ListFilter extends React.Component {
  static contextType = GlobalContext;

  constructor(props) {
    super(props);

    this.search_context = new URLSearchParams(window.location.search);

    this.state = {
      hide_solved: !props.location.state?.hidesolved ? true : props.location.state.hidesolved === "1",
      sort_by: props.location.state?.order || "id",
      difficulty_selected: !props.location.state?.difficulties ? [true, true, true, true, true] : (() => {
        const difficulties = props.location.state.difficulties.split(",");
        return Array(5).fill(null).map((_, index) => {
          if (parseInt(difficulties[0]) === index + 1) {
            difficulties.shift();
            return true;
          }
          return false;
        });
      })(),
      categories_selected: undefined,
      categories_verbose: window.sessionStorage.getItem("task_categories") ? Object.keys(JSON.parse(window.sessionStorage.getItem("task_categories"))) : undefined,
      has_search_text: Boolean(props.location.state?.search),
      reversed: !props.location.state?.reversed ? false : props.location.state.reversed === "1",
    };

    this.search_timeout = null;
  }

  componentDidMount() {
    if (!this.state.categories_verbose) {
      let response = _fetchApi(`/getAvaliableCategories`, this.context.token ? {
        method: "GET",
        headers: { "Authorization": "Bearer " + this.context.token, },
      } : { method: "GET", }).then(response => response.json());
      response.then(result => {
        const sorted = Object.fromEntries(Object.entries(result).sort(([, a], [, b]) => a - b));
        window.sessionStorage.setItem("task_categories", JSON.stringify(sorted));
        const len = Object.values(sorted).length;
        if (this.props.location.state?.categories) {
          const categories = this.props.location.state.categories.split(",");
          this.setState({
            categories_selected: Array(len).fill(null).map((_, index) => {
              if (parseInt(categories[0]) === index + 1) {
                categories.shift();
                return true;
              }
              return false;
            }),
            categories_verbose: Object.keys(sorted),
          }, () => {
            this.makeSearch(this.props.location.state?.search || "", true);
          });
        } else {
          this.setState({
            categories_selected: Array(len).fill(true),
            categories_verbose: Object.keys(sorted),
          }, () => {
            this.makeSearch(this.props.location.state?.search || "", true);
          });
        }
      });
    } else {
      const len = this.state.categories_verbose.length;
      if (this.props.location.state?.categories) {
        const categories = this.props.location.state.categories.split(",");
        this.setState({
          categories_selected: Array(len).fill(null).map((_, index) => {
            if (parseInt(categories[0]) === index + 1) {
              categories.shift();
              return true;
            }
            return false;
          }),
        }, () => {
          this.makeSearch(this.props.location.state?.search || "", true);
        });
      } else {
        this.setState({
          categories_selected: Array(len).fill(true),
        }, () => {
          this.makeSearch(this.props.location.state?.search || "", true);
        });
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.category !== this.props.category) {
      this.search_timeout = setTimeout(() => this.makeSearch(document.getElementById("task-list-search").value, true)
      );
    }
  }

  makeSearch(value, first_load = false) {
    clearTimeout(this.search_timeout);

    if (this.props.category === "private") {
      this.props.updateTasks({
        search: value,
      });
    } else {
      if (value) {
        this.props.updateTasks({
          search: value,
        });
      } else {
        if (first_load) {
          this.props.updateTasks({
            hide_solved: this.state.hide_solved,
            sort_by: this.state.sort_by,
            difficulty_selected: this.state.difficulty_selected,
            categories_selected: this.state.categories_selected,
            reversed: this.state.reversed,
          });
        } else {
          this.search_timeout = setTimeout(() => {
            this.props.updateTasks({
              hide_solved: this.state.hide_solved,
              sort_by: this.state.sort_by,
              difficulty_selected: this.state.difficulty_selected,
              categories_selected: this.state.categories_selected,
              reversed: this.state.reversed,
            });
          }, 500);
        }
      }
    }
  }

  render() {
    function Separator(props) {
      return <svg height="2" viewBox="0 0 298 1" fill="none" xmlns="http://www.w3.org/2000/svg" className="tasklist-separator" {...props}>
        <line y1="0.5" x2="298" y2="0.5" stroke="var(--alert-separator)" />
      </svg>;
    }

    return <div className="task-list-filter">
      <Search
        id="task-list-search"
        value={this.props.location.state?.search || this.search_context.get("query") || ""}
        placeholder="Поиск по названию"
        onTypingEnd={value => this.makeSearch(value)}
        onValueChange={value => {
          if (Boolean(value) !== this.state.has_search_text) {
            this.setState({ has_search_text: Boolean(value), });
          }
        }} />
      <div style={{ display: "flex", alignItems: "center", marginTop: "32px", }}>
        <svg height="2" viewBox="0 0 100 1" fill="none" xmlns="http://www.w3.org/2000/svg">
          <line y1="0.5" x2="100" y2="0.5" stroke="var(--alert-separator)" />
        </svg>
        <p style={{ margin: 0, padding: "0 33px", color: "#9E9E9E", fontFamily: "PT-Root-UI-Medium", fontSize: "18px", }}>или</p>
        <svg height="2" viewBox="0 0 100 1" fill="none" xmlns="http://www.w3.org/2000/svg">
          <line y1="0.5" x2="100" y2="0.5" stroke="var(--alert-separator)" />
        </svg>
      </div>
      {(this.state.has_search_text || this.props.category === "private") && <div className="task-filter-dim" style={{
        opacity: "1",
        pointerEvents: "auto",
      }} />}
      {/* TODO: undo <MyCheckBox
              label="Скрыть решённые"
              checked={this.state.hide_solved}
              className="hide-solved"
              onChange={ () => this.setState({hide_solved: !this.state.hide_solved,}, () => {
                this.makeSearch();
              }) }
            />
            <Separator style={ {paddingTop: "28px",} } /> */}
      <MyRadio
        containerClassName="sort-form"
        formLabel="Сортировка"
        onChange={event => this.setState({ sort_by: event.target.value, }, () => this.makeSearch()
        )}
        value={this.state.sort_by}
        radios={[
          {
            label: "По новизне",
            value: "id",
          },
          {
            label: "По сложности",
            value: "difficulty",
          },
          {
            label: "По количеству решивших",
            value: "solvedby",
          },
        ]} />
      <MySwitch
        checked={this.state.reversed}
        label="Обратный порядок"
        style={{ paddingTop: "24px", }}
        onChange={() => this.setState({ reversed: !this.state.reversed, }, () => this.makeSearch()
        )} />
      <Separator />
      <CheckBoxGroup
        containerClassName="sort-form"
        formLabel="Сложность"
        checkboxes={[
          {
            label: <div style={{ display: "flex", alignItems: "center", marginLeft: "6px", }}>
              {getTaskIcon(0)}
              <span className="MuiTypography-root MuiFormControlLabel-label MuiTypography-body1 checkbox-difficulty">Простая</span>
            </div>,
            checked: this.state.difficulty_selected[0],
            onChange: () => {
              let new_difficulty = this.state.difficulty_selected;
              new_difficulty[0] = !new_difficulty[0];
              this.setState({ difficulty_selected: new_difficulty, }, () => {
                this.makeSearch();
              });
            },
          },
          {
            label: <div style={{ display: "flex", alignItems: "center", marginLeft: "6px", }}>
              {getTaskIcon(30)}
              <span className="MuiTypography-root MuiFormControlLabel-label MuiTypography-body1 checkbox-difficulty">Средняя</span>
            </div>,
            checked: this.state.difficulty_selected[1],
            onChange: () => {
              let new_difficulty = this.state.difficulty_selected;
              new_difficulty[1] = !new_difficulty[1];
              this.setState({ difficulty_selected: new_difficulty, }, () => {
                this.makeSearch();
              });
            },
          },
          {
            label: <div style={{ display: "flex", alignItems: "center", marginLeft: "6px", }}>
              {getTaskIcon(50)}
              <span className="MuiTypography-root MuiFormControlLabel-label MuiTypography-body1 checkbox-difficulty">Сложная</span>
            </div>,
            checked: this.state.difficulty_selected[2],
            onChange: () => {
              let new_difficulty = this.state.difficulty_selected;
              new_difficulty[2] = !new_difficulty[2];
              this.setState({ difficulty_selected: new_difficulty, }, () => {
                this.makeSearch();
              });
            },
          },
          {
            label: <div style={{ display: "flex", alignItems: "center", marginLeft: "6px", }}>
              {getTaskIcon(70)}
              <span className="MuiTypography-root MuiFormControlLabel-label MuiTypography-body1 checkbox-difficulty">Очень сложная</span>
            </div>,
            checked: this.state.difficulty_selected[3],
            onChange: () => {
              let new_difficulty = this.state.difficulty_selected;
              new_difficulty[3] = !new_difficulty[3];
              this.setState({ difficulty_selected: new_difficulty, }, () => {
                this.makeSearch();
              });
            },
          },
          {
            label: <div style={{ display: "flex", alignItems: "center", marginLeft: "6px", }}>
              {getTaskIcon(90)}
              <span className="MuiTypography-root MuiFormControlLabel-label MuiTypography-body1 checkbox-difficulty">Невыполнимая</span>
            </div>,
            checked: this.state.difficulty_selected[4],
            onChange: () => {
              let new_difficulty = this.state.difficulty_selected;
              new_difficulty[4] = !new_difficulty[4];
              this.setState({ difficulty_selected: new_difficulty, }, () => {
                this.makeSearch();
              });
            },
          },
        ]} />
      <Separator style={{ paddingTop: "28px", }} />
      {this.state.categories_selected ? <div style={{ position: "relative", }}>
        <CheckBoxGroup
          containerClassName="sort-form"
          formLabel="Темы"
          checkboxes={this.state.categories_verbose.map((element, index) => {
            return {
              label: element,
              checked: this.state.categories_selected[index],
              onChange: () => {
                let new_categories = this.state.categories_selected;
                new_categories[index] = !new_categories[index];
                this.setState({ categories_selected: new_categories, }, () => {
                  this.makeSearch();
                });
              },
            };
          })} />
        <Button
          disableElevation
          disableRipple
          className="categories-select-all"
          onClick={() => {
            if (this.state.categories_selected.every(element => element)) {
              this.setState({
                categories_selected: this.state.categories_selected.fill(false),
              }, () => {
                this.makeSearch();
              });
            } else {
              this.setState({
                categories_selected: this.state.categories_selected.fill(true),
              }, () => {
                this.makeSearch();
              });
            }
          }}>Выбрать всё</Button>
      </div> : <></>}
    </div>;
  }
}
