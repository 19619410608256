import React, { useEffect, useState, useContext }  from 'react';
import useSWR from 'swr';
import { Link, useHistory, useParams } from 'react-router-dom';
import clsx from 'clsx';
import { Helmet } from 'react-helmet';

import '../static/css/Contest.css';
import GlobalContext, { updateSize, contestDateConverter, titleEnding } from  '../global';
import _fetchApi from '../fetch';
import fetchApi from '../utils/fetchApi';

import Loader, { LoaderNarrow } from '../components/loaders';
import SelectList from '../components/selectlist';
import MyCheckBox from '../components/checkbox';
import Search from '../components/search';
import MainButton, { NoloadButton } from '../components/buttons';
import Timer from '../components/timer';
import Markdown from '../components/markdown';
import LoginContainer from '../components/login';

const ContestListButton = ({ setCategory, isSelected, children }) => {
  return (
    <button
      className={clsx("contest-category-button", isSelected && "selected")}
      onClick={() => setCategory()}
    >
      {children}
    </button>
  )
}

function ContestList(props) {
  const context = useContext(GlobalContext);

  const searchContext = new URLSearchParams(props.location.search);
  const [category, setCategory] = useState(searchContext.get("show") ?? "public");

  useEffect(() => {
    document.title = "Соревнования" + titleEnding;
  });

  return <div className="main-content scalable">
    <div className="top-text contest-flex">
      <h1 className="header">Соревнования</h1>
      {context.token && <div className="contest-switch">
        <ContestListButton
          setCategory={() => setCategory("public")}
          isSelected={category === "public"}
        >
          Публичные
        </ContestListButton>
        <ContestListButton
          setCategory={() => setCategory("history")}
          isSelected={category === "history"}
        >
          История участия
        </ContestListButton>
        <ContestListButton
          setCategory={() => setCategory("managed")}
          isSelected={category === "managed"}
        >
          Управление
        </ContestListButton>
      </div>}
    </div>
    {(() => {
      if (category === "managed" && context.token) {
        return (
          <ManagedContests />
        )
      } else if (category === "history" && context.token) {
        return (
          <ContestHistory />
        )
      } else {
        return (
          <>
            <UpcomingContests />
            <ContestArchive />
          </>
        )
      }
    })()}
  </div>;
}

const ManagedContests = () => {
  const context = useContext(GlobalContext);
  const history = useHistory();

  const [myContests, setMyContests] = useState(null);
  const [canCreate, setCanCreate] = useState(false);

  useEffect(() => {
    const apiResponse = _fetchApi("/getManagedContests", {
      method: "GET",
      headers: { "Authorization": "Bearer " + context.token, },
    }).then(response => response.json());
    apiResponse.then(result => {
      if (!result.hasOwnProperty("error")) {
        setCanCreate(result.can_create_contests);
        setMyContests(result.contests);
      }
    });
  }, []);

  const createContest = async () => {
    const apiResponse = await _fetchApi("/createNewContest", {
      method: "POST",
      headers: { "Authorization": "Bearer " + context.token, },
    }).then(response => response.json());
    if (apiResponse.hasOwnProperty("error")) {
      return apiResponse;
    }
    history.push(`/contest/${apiResponse.id}?show=managed`);
  };

  const CreateContest = () => <MainButton
    className="create-contest-button"
    onClick={createContest}
  >Создать контест</MainButton>;

  if (myContests === null) {
    return <div className="contest-list">
      <Loader />
    </div>;
  } else if (myContests.length === 0) {
    return <div className="contest-list empty">
      <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M24.0007 45.3573C36.5191 45.3573 46.6673 35.209 46.6673 22.6906C46.6673 10.1721 36.5191 0.0239258 24.0007 0.0239258C11.4822 0.0239258 1.33398 10.1721 1.33398 22.6906C1.33398 35.209 11.4822 45.3573 24.0007 45.3573Z" fill="#FFCB4C"/>
        <path d="M19.3648 28.048C19.1715 27.8933 19.0208 27.6826 18.9488 27.4293C18.7702 26.816 19.1222 26.2053 19.7355 26.068C25.7728 24.7066 29.8382 27.8853 30.0102 28.0213C30.5222 28.4293 30.6222 29.148 30.2408 29.628C29.8595 30.1053 29.1368 30.1626 28.6262 29.7573C28.4795 29.6453 25.2622 27.1933 20.3835 28.2933C20.0182 28.3746 19.6448 28.272 19.3648 28.048Z" fill="#65471B"/>
        <path d="M17.4915 18.4401C19.0563 18.4401 20.3249 16.8546 20.3249 14.8988C20.3249 12.9429 19.0563 11.3574 17.4915 11.3574C15.9267 11.3574 14.6582 12.9429 14.6582 14.8988C14.6582 16.8546 15.9267 18.4401 17.4915 18.4401Z" fill="#65471B"/>
        <path d="M32.4993 19.8561C34.0642 19.8561 35.3327 18.2706 35.3327 16.3148C35.3327 14.3589 34.0642 12.7734 32.4993 12.7734C30.9345 12.7734 29.666 14.3589 29.666 16.3148C29.666 18.2706 30.9345 19.8561 32.4993 19.8561Z" fill="#65471B"/>
        <path d="M23.0347 46.8654C23.0347 46.8654 24.7214 46.3174 24.94 45.0627C25.1707 43.7667 24.108 43.5067 24.108 43.5067C24.108 43.5067 25.496 43.2294 25.6707 41.6721C25.8347 40.2041 24.5227 39.8547 24.5227 39.8547C24.5227 39.8547 25.816 39.3214 25.8774 37.8027C25.928 36.5241 24.5507 35.8987 24.5507 35.8987C24.5507 35.8987 31.268 34.2707 31.9587 34.1107C32.6467 33.9507 33.7187 33.2907 33.384 31.8521C33.052 30.4121 31.7787 30.3614 31.1214 30.5147C30.4627 30.6681 22.1294 32.6027 19.2547 33.2721L17.336 33.7174C16.616 33.8867 16.2894 33.5707 16.7974 33.0347C17.4747 32.3201 17.908 31.5294 18.0587 30.2174C18.2174 28.8374 17.7494 27.1334 17.4814 26.4721C16.9827 25.2441 16.1414 24.2734 15.1694 23.9401C13.6534 23.4201 12.576 24.3681 13.1134 26.0214C13.9187 28.4934 13.3907 30.5214 12.0027 31.7454C8.73735 34.6214 7.21869 36.6721 8.22935 41.0427C9.33335 45.8094 14.0654 48.8774 18.832 47.7734L23.0347 46.8654Z" fill="#F19020"/>
        <path d="M12.3944 8.468C12.1757 8.35067 11.9904 8.16934 11.8731 7.936C11.5851 7.36534 11.8197 6.7 12.3971 6.45334C18.0851 4.012 22.6624 6.396 22.8544 6.49867C23.4317 6.80667 23.6651 7.49467 23.3757 8.03734C23.0877 8.57734 22.3891 8.76534 21.8117 8.46134C21.6477 8.37734 18.0357 6.552 13.4411 8.524C13.0984 8.66934 12.7117 8.63734 12.3944 8.468ZM28.5744 13.468C28.3664 13.3373 28.1931 13.144 28.0917 12.9027C27.8424 12.3133 28.1224 11.6667 28.7157 11.4573C34.5571 9.40934 38.9624 12.0973 39.1464 12.2133C39.6997 12.56 39.8864 13.2627 39.5624 13.784C39.2397 14.304 38.5278 14.444 37.9744 14.1013C37.8144 14.0053 34.3384 11.94 29.6184 13.596C29.2638 13.7173 28.8811 13.6573 28.5744 13.468Z" fill="#65471B"/>
      </svg>
      <h1>
        Хотите провести контест?
      </h1>
      {canCreate ? <>
        <p>
          Похвально! Создайте соревнование из задач, укажите дату и время начала, и вперёд.<br />
          Контест будет доступен по приватной ссылке.
        </p>
        <CreateContest />
      </> : <p>
        Похвально! Но пока что эта фича доступна не всем.<br />
        Чтобы получить доступ, напишите на почту <a href="mailto: guys@sort-me.org">guys@sort-me.org</a>.
      </p>}
    </div>;
  } else {
    return <div className="contest-list">
      <p>Показаны все соревнования, к которым у вас есть доступ администратора.</p>
      <div className="contest-event-list" style={{ margin: "0", }}>
        {myContests.map((element, index) => <UpcomingContainer {...element} key={index} href={`/contest/${element.id}?show=managed`} />)}
      </div>
      {canCreate && <CreateContest />}
    </div>;
  }
}

const ContestHistory = () => {
  const { data: contests } = useSWR({
    url: "/getHistoryOfContests",
    method: "get",
  }, params => fetchApi(params).then(response => response.contests), {
    revalidateOnFocus: false,
    shouldRetryOnError: false,
    revalidateOnMount: true,
  })

  if (!contests) {
    return (
      <div className="contest-list">
        <Loader />
      </div>
    )
  } else if (contests.length === 0) {
    return (
      <div className="contest-list empty">
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_8827_15396)">
            <path d="M48 24C48 37.2547 37.2547 48 24 48C10.7467 48 0 37.2547 0 24C0 10.7467 10.7467 0 24 0C37.2547 0 48 10.7467 48 24Z" fill="#FFCC4D"/>
            <path d="M15.3333 26.6666C17.1743 26.6666 18.6667 24.5772 18.6667 21.9999C18.6667 19.4226 17.1743 17.3333 15.3333 17.3333C13.4924 17.3333 12 19.4226 12 21.9999C12 24.5772 13.4924 26.6666 15.3333 26.6666Z" fill="#664500"/>
            <path d="M32.6666 26.6666C34.5076 26.6666 36 24.5772 36 21.9999C36 19.4226 34.5076 17.3333 32.6666 17.3333C30.8257 17.3333 29.3333 19.4226 29.3333 21.9999C29.3333 24.5772 30.8257 26.6666 32.6666 26.6666Z" fill="#664500"/>
            <path d="M31.3133 37.1719C31.2987 37.1133 29.7867 31.3333 24 31.3333C18.2133 31.3333 16.7013 37.1133 16.6867 37.1719C16.616 37.4559 16.7453 37.7466 16.996 37.8973C17.2467 38.0466 17.5733 38.0119 17.7907 37.8173C17.8027 37.8079 19.1413 36.6666 24 36.6666C28.7867 36.6666 30.156 37.7733 30.208 37.8173C30.3347 37.9386 30.5 37.9999 30.6667 37.9999C30.7787 37.9999 30.892 37.9719 30.9947 37.9146C31.256 37.7666 31.3867 37.4626 31.3133 37.1719Z" fill="#664500"/>
            <path d="M13.3333 40.0001C13.3333 43.6827 10.3493 46.6667 6.66667 46.6667C2.984 46.6667 0 43.6827 0 40.0001C0 36.3174 5.33333 26.6667 6.66667 26.6667C8 26.6667 13.3333 36.3174 13.3333 40.0001Z" fill="#5DADEC"/>
            <path d="M40 17.3333C32.5947 17.3333 29.5974 11.5106 29.4734 11.2626C29.144 10.604 29.4107 9.80262 30.0694 9.47328C30.7254 9.14395 31.5254 9.40928 31.856 10.064C31.956 10.2586 34.284 14.6666 40 14.6666C40.7374 14.6666 41.3334 15.264 41.3334 16C41.3334 16.7373 40.7374 17.3333 40 17.3333ZM8.00002 17.3333C7.26402 17.3333 6.66669 16.736 6.66669 16C6.66669 15.264 7.26402 14.6666 8.00002 14.6666C14.7774 14.6666 15.9947 10.5066 16.044 10.3293C16.2374 9.62528 16.964 9.19862 17.6694 9.38395C18.376 9.56928 18.804 10.2813 18.6267 10.9906C18.5614 11.2493 16.9387 17.3333 8.00002 17.3333Z" fill="#664500"/>
          </g>
          <defs>
            <clipPath id="clip0_8827_15396">
              <rect width="48" height="48" fill="white" />
            </clipPath>
          </defs>
        </svg>
        <h1>
          Вы пока не участвовали в контестах
        </h1>
        <p style={{ width: "595px", }}>
          Чтобы это исправить, дождитесь проведения какого-нибудь контеста, поучаствуйте, и он появится здесь
        </p>
      </div>
    )
  } else {
    return (
      <div className="contest-list">
        <p>Закончившиеся соревнования, на которые вы зарегистрированы.</p>
        <div className="contest-event-list" style={{ margin: "0", }}>
          {contests.map((element, index) => <UpcomingContainer {...element} key={index} href={`/contest/${element.id}?show=history`} />)}
        </div>
      </div>
    )
  }
}

class UpcomingContests extends React.Component {
  static contextType = GlobalContext;

  constructor(props) {
    super(props);

    this.state = {
      upcoming: [],
    };
  }

  getUpcomingContests() {
    let response = _fetchApi("/getUpcomingContests", this.context.token ? {
      method: "GET",
      headers: {"Authorization": "Bearer " + this.context.token,},
    } : {method: "GET",}).then(response => response.json());
    response.then(result => this.setState({upcoming: result,}));
  }

  componentDidMount() {
    this.getUpcomingContests();
  }

  render() {
    if (this.state.upcoming.length > 0) {
      return <>
        <div className="contest-event-list" style={ {marginTop: 0,} }>
          {this.state.upcoming.map((element, index) => <UpcomingContainer {...element} key={index} href={`/contest/${element.id}`} />)}
        </div>
        <div className="top-text" style={ {marginTop: "72px",} }>
          <h2 className="header">Архив</h2>
        </div>
      </>;
    } else {
      return <></>;
    }
  }
}

class ContestArchive extends React.Component{
  static contextType = GlobalContext;

  constructor(props) {
    super(props);

    this.scroller = () => {
      if (this.state.archive && !this.fetching && this.state.count > this.state.archive.length) {
        try {
          const element = document.getElementById("archive-" + this.state.archive[this.state.archive.length - 1].id);
          const rect = element.getBoundingClientRect();
          if (rect.top >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)) {
            this.updateArchive(true, false);
          }
        } catch (error) {
          if (!(error instanceof TypeError)) {
            throw error;
          }
        }
      }
    };

    this.search_context = new URLSearchParams(window.location.search);
    
    this.state = {
      hide_solved: this.search_context.get("hidesolved") !== "0",
      category_selected: this.search_context.get("category") || 0,
      categories: window.sessionStorage.getItem("archive_categories") ? JSON.parse(window.sessionStorage.getItem("archive_categories")) : undefined,
      archive: [],
      count: undefined,
      loading: true,
      search_text: this.search_context.get("query") || "",
    };

    this.fetching = false;
    this.search_timeout = null;
    this.prev_search = null;
  }

  componentDidMount() {
    window.addEventListener("scroll", this.scroller);

    if (!this.state.categories) {
      let response = _fetchApi("/getArchiveCategories", this.context.token ? {
        method: "GET",
        headers: {"Authorization": "Bearer " + this.context.token,},
      } : {method: "GET",}).then(response => response.json());
      response.then(result => {
        const sorted_categories = Object.fromEntries(
          Object.entries(result).sort(([, a], [, b]) => a - b)
        );
        window.sessionStorage.setItem("archive_categories", JSON.stringify(sorted_categories));
        this.setState({categories: sorted_categories,});
      });
    }

    this.updateArchive(false, true);

    updateSize();
  }

  updateArchive(was_scrolled = false, first_load = false) {
    clearTimeout(this.search_timeout);

    let search;
    if (was_scrolled) {
      search = this.prev_search;
    } else {
      search = `hidesolved=${this.state.hide_solved ? 1 : 0}&category=${this.state.category_selected || 0}${this.state.search_text ? "&query=" + this.state.search_text : ""}`;
    }

    if (first_load || was_scrolled) {
      if (was_scrolled) {
        this.setState({
          loading: true,
        });
      } else {
        this.setState({
          loading: true,
          archive: [],
          count: undefined,
        });
      }

      this.fetching = true;
      let response = _fetchApi(`/getArchivePreviews?${search}&offset=${this.state.archive.length > 0 ? this.state.archive[this.state.archive.length - 1].id : 0}`, this.context.token ? {
        method: "GET",
        headers: {"Authorization": "Bearer " + this.context.token,},
      } : {method: "GET",}).then(response => response.json());
      response.then(result => {
        this.prev_search = search;
        this.setState({
          count: result.count,
          archive: this.state.archive.concat(result.items),
          loading: false,
        }, () => {
          this.fetching = false;
          this.scroller();
        });
      });
    } else {
      this.search_timeout = setTimeout(() => {
        this.setState({
          loading: true,
          archive: [],
          count: undefined,
        });

        this.fetching = true;
        let response = _fetchApi(`/getArchivePreviews?${search}&offset=${this.state.archive.length > 0 ? this.state.archive[this.state.archive.length - 1].id : 0}`, this.context.token ? {
          method: "GET",
          headers: {"Authorization": "Bearer " + this.context.token,},
        } : {method: "GET",}).then(response => response.json());
        response.then(result => {
          this.prev_search = search;
          this.setState({
            count: result.count,
            archive: this.state.archive.concat(result.items),
            loading: false,
          }, () => {
            this.fetching = false;
            this.scroller();
          });
        });
      }, 500);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scroller);
  }

  render() {
    return (<>
      <div className="top-text">
        <p className="contest-top-description">Тут собран архив различных олимпиад по программированию. Решайте их, чтобы оценить свои силы или подготовиться к очередному контесту.</p>
      </div>
      <div id="contest-top-bar">
        <Search
          placeholder="Поиск"
          id="contest-search"
          onTypingEnd={query => this.setState({search_text: query,}, () => this.updateArchive())}
          value={this.search_context.get("query") || ""}
        />
        <SelectList
          onChange={(category, index) => this.setState({category_selected: index}, () => this.updateArchive())}
          options={this.state.categories ? ["Все категории"].concat(Object.keys(this.state.categories)) : ["Все категории"]}
          index={this.state.categories ? this.state.category_selected : 0}
        />
        {/* TODO: undo <MyCheckBox
          className="contest-hide-solved"
          checked={this.state.hide_solved}
          onChange={() => this.setState({hide_solved: !this.state.hide_solved}, () => this.updateArchive())}
          label="Скрыть полностью прорешанные"
        /> */}
      </div>
      <div className="contest-event-list">
        {this.state.count !== 0 ?
          this.state.archive.map((element, index) => <ArchiveContainer {...element} key={index} href={`/tasks/0?archive=${element.id}&${this.prev_search}`} />) : <></>}
      </div>
      {this.state.count === 0 ? <p className="archive-end">Ничего не нашлось.</p> : <></>}
      {this.state.loading ? <LoaderNarrow width="60" height="60" /> : <></>}
    </>);
  }
}

function ArchiveContainer(props) {
  function getSeasons(count) {
    const string_count = count.toString();
    if (string_count.endsWith("1") && (count > 19 || count < 11)) {
      return "сезон";
    } else if ((string_count.endsWith("2") || string_count.endsWith("3") || string_count.endsWith("4")) && (count > 19 || count < 11)) {
      return "сезона";
    } else {
      return "сезонов";
    }
  }

  return <Link className="contest-container" id={"archive-" + props.id} to={props.href}>
    <p>{props.name}</p>
    <div className="contest-container-bottom">
      <div style={ {display: "flex", alignItems: "center",} }>
        <span>{props.category}</span>
        <svg width="3" height="3" viewBox="0 0 4 4" fill="none" xmlns="http://www.w3.org/2000/svg" style={ {padding: "0 6px"} }>
          <circle cx="1.5" cy="1.5" r="1.5" fill="var(--difficulty)" />
        </svg>
        <span>{props.seasons} {getSeasons(props.seasons)}</span>
      </div>
      {/* TODO: undo <span>Прорешана на {props.solved_percent}%</span> */}
    </div>
  </Link>;
}

function UpcomingContainer(props) {
  return <Link className="contest-container" id={"upcoming-" + props.id} to={props.href}>
    <p>{props.name}</p>
    <div className="contest-container-bottom">
      {props.running ?
        <p>{contestDateConverter(props.starts)} <span style={ {color: "var(--submission-red)", transition: "transition: color var(--transition-timing) var(--transition-function)",} }>(Идёт прямо сейчас)</span></p> :
        (props.registration_opened ?
        <p>{contestDateConverter(props.starts)} <span style={ {color: "var(--submission-green)", transition: "transition: color var(--transition-timing) var(--transition-function)",} }>(Регистрация открыта)</span></p> :
        <p>{contestDateConverter(props.starts)}</p>)}
      <span>{props.org_name}</span>
    </div>
  </Link>;
}

class UpcomingSingle extends React.Component {
  static contextType = GlobalContext;
  
  constructor(props) {
    super(props);

    this.search_context = new URLSearchParams(this.props.location.search);

    this.state = {
      status: null,
      offset: null,
      starts: null,
      ends: null,
      register_starts: null,
      register_ends: null,
      registered: null,
    };

    this.update_timeouts = [];
  }

  getCurrentContest() {
    let response = _fetchApi(`/getContestById?id=${this.props.params.contest_id}${this.search_context.get("code") ? "&code=" + this.search_context.get("code") : ""}`, this.context.token ? {
      method: "GET",
      headers: {"Authorization": "Bearer " + this.context.token,},
    } : {method: "GET",}).then(response => response.json());
    response.then(result => {
      if (result.hasOwnProperty("error")) {
        this.setState({
          status: "bad_data",
        }, () => document.title = "Контест не найден" + titleEnding);
      } else {
        result["offset"] = Math.floor(Date.now() / 1000) - result.now;
        delete result.now;
        this.setState({
          ...result,
          registered: result.is_admin || result.registered,
          status: "ok",
        }, () => {
          document.title = result.name + titleEnding;

          if (this.state.register_starts > (Math.floor(Date.now() / 1000) - this.state.offset)) {
            this.update_timeouts.push(setTimeout(() => this.forceUpdate(), (this.state.register_starts - (Math.floor(Date.now() / 1000) - this.state.offset)) * 1000 + 100));
          }
          if (this.state.register_ends > (Math.floor(Date.now() / 1000) - this.state.offset)) {
            this.update_timeouts.push(setTimeout(() => this.forceUpdate(), (this.state.register_ends - (Math.floor(Date.now() / 1000) - this.state.offset)) * 1000 + 500));
          }
          if (this.state.starts > (Math.floor(Date.now() / 1000) - this.state.offset)) {
            this.update_timeouts.push(setTimeout(() => this.forceUpdate(), (this.state.starts - (Math.floor(Date.now() / 1000) - this.state.offset)) * 1000 + 300));
          }
          if (this.state.ends > (Math.floor(Date.now() / 1000) - this.state.offset)) {
            this.update_timeouts.push(setTimeout(() => this.forceUpdate(), (this.state.ends - (Math.floor(Date.now() / 1000) - this.state.offset)) * 1000 + 300));
          }
        });
      }
    });
  }

  async register() {
    let response;
    if (this.search_context.get("code")) {
      response = await _fetchApi(`/registerToContest?id=${this.state.id}&code=${this.search_context.get("code")}`, {
        method: "POST",
        headers: {"Authorization": "Bearer " + this.context.token,},
      });
    } else {
      response = await _fetchApi("/registerToContest?id=" + this.state.id, {
        method: "POST",
        headers: {"Authorization": "Bearer " + this.context.token,},
      });
    }
    response = await response.json();
    if (response.status === "ok") {
      this.setState({
        registered: true,
        participants_count: this.state.participants_count + 1,
      });
    }
    return response;
  }

  componentDidMount() {
    this.getCurrentContest();
  }

  componentWillUnmount() {
    this.update_timeouts.forEach(element => clearTimeout(element));
  }

  render() {
    if (this.state.status === "bad_data") {
      return <>
        {this.search_context.get("code") && <Helmet>
          <meta name="robots" content="noindex" />
        </Helmet>}
        
        <div className="main-content scalable">
          <Link to={"/contest" + this.props.location.search} className="upcoming-back-link">← К соревнованиям</Link>
          <div className="contest-info">
            <h2>Упс... Такого контеста у нас пока нет</h2>
          </div>
        </div>
      </>;
    } else if (this.state.status) {
      return <>
        {this.search_context.get("code") && <Helmet>
          <meta name="robots" content="noindex" />
        </Helmet>}

        <div className="main-content scalable">
          <Link to={"/contest" + this.props.location.search} className="upcoming-back-link">← К соревнованиям</Link>
          <div className="contest-info">
            <h2>{this.state.name}</h2>
            <Markdown>{this.state.description}</Markdown>
          </div>
          <div style={ {marginTop: "48px",} }>
            <div className="contest-point-info">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M5 2.00009C5.55228 2.00009 6 2.44781 6 3.00009L5.99984 3.07044C7.17657 2.3897 8.54279 2.00009 10 2.00009C11.4576 2.00009 12.8242 2.38992 14.0012 3.07102L14 3.00009C14 2.44781 14.4477 2.00009 15 2.00009H17C17.5523 2.00009 18 2.44781 18 3.00009V5.00009C18 5.55238 17.5523 6.00009 17 6.00009L16.9297 5.99993C17.6104 7.17666 18 8.54288 18 10.0001C18 14.4184 14.4183 18.0001 10 18.0001C5.58172 18.0001 2 14.4184 2 10.0001C2 8.54288 2.38961 7.17666 3.07035 5.99993L3 6.00009C2.44772 6.00009 2 5.55238 2 5.00009V3.00009C2 2.44781 2.44772 2.00009 3 2.00009H5ZM10 4.00009C6.68629 4.00009 4 6.68638 4 10.0001C4 13.3138 6.68629 16.0001 10 16.0001C13.3137 16.0001 16 13.3138 16 10.0001C16 6.68638 13.3137 4.00009 10 4.00009ZM10 6.00009C10.5128 6.00009 10.9355 6.38613 10.9933 6.88347L11 7.00009V9.58588L12.7071 11.293C13.0976 11.6835 13.0976 12.3167 12.7071 12.7072C12.3466 13.0677 11.7794 13.0954 11.3871 12.7904L11.2929 12.7072L9.29289 10.7072C9.13661 10.5509 9.0374 10.3482 9.00867 10.1315L9 10.0001V7.00009C9 6.44781 9.44772 6.00009 10 6.00009Z" />
              </svg>
              <p>Начало: {contestDateConverter(this.state.starts)}{(() => {
                if (this.state.ends <= (Math.floor(Date.now() / 1000) - this.state.offset)) {
                  return <span style={ {color: "var(--difficulty)",} }> (Закончен)</span>;
                } else if (this.state.starts <= (Math.floor(Date.now() / 1000) - this.state.offset)) {
                  return <span style={ {color: "var(--submission-red)",} }> (Идёт прямо сейчас)</span>;
                } else if (this.state.register_starts <= (Math.floor(Date.now() / 1000) - this.state.offset) && (Math.floor(Date.now() / 1000) - this.state.offset) <= this.state.register_ends) {
                  return <span style={ {color: "var(--submission-green)",} }> (Регистрация открыта)</span>;
                }
              })()}</p>
            </div>
            <div className="contest-point-info">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M12 2C13.5977 2 14.9037 3.24892 14.9949 4.82373L15 5V7C15 7.27541 14.8865 7.53676 14.6895 7.72431L14.6 7.8L11.667 10L14.6 12.2C14.8518 12.3889 15 12.6852 15 13V15C15 16.6569 13.6569 18 12 18H8C6.34315 18 5 16.6569 5 15V13C5 12.6852 5.14819 12.3889 5.4 12.2L8.333 10L5.4 7.8C5.17967 7.63475 5.03867 7.38717 5.00686 7.117L5 7V5C5 3.40232 6.24892 2.09634 7.82373 2.00509L8 2H12ZM10 11.25L7 13.5V15C7 15.5523 7.44772 16 8 16H12L12.1166 15.9933C12.614 15.9355 13 15.5128 13 15V13.5L10 11.25ZM8 4H12L12.1166 4.00673L12.2293 4.02641C12.6711 4.13005 13 4.52661 13 5V6H7V5L7.00673 4.88338C7.06449 4.38604 7.48716 4 8 4Z" />
              </svg>
              <p>Конец: {contestDateConverter(this.state.ends)}</p>
            </div>
            <div className="contest-point-info">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M7 14.0001H13C15.7614 14.0001 18 16.2387 18 19.0001C18 19.5524 17.5523 20.0001 17 20.0001C16.4872 20.0001 16.0645 19.6141 16.0067 19.1167L15.9949 18.8238C15.907 17.3073 14.6928 16.0931 13.1763 16.0052L13 16.0001H7C5.34315 16.0001 4 17.3432 4 19.0001C4 19.5524 3.55228 20.0001 3 20.0001C2.44772 20.0001 2 19.5524 2 19.0001C2 16.3113 4.12231 14.1183 6.78311 14.0047L7 14.0001H13H7ZM10 4.00009C12.7614 4.00009 15 6.23867 15 9.00009C15 11.7615 12.7614 14.0001 10 14.0001C7.23858 14.0001 5 11.7615 5 9.00009C5 6.23867 7.23858 4.00009 10 4.00009ZM10 6.00009C8.34315 6.00009 7 7.34324 7 9.00009C7 10.6569 8.34315 12.0001 10 12.0001C11.6569 12.0001 13 10.6569 13 9.00009C13 7.34324 11.6569 6.00009 10 6.00009Z" />
              </svg>
              <p>Зарегистрирован{this.state.participants_count !== 1 ? "о" : ""} {this.state.participants_count} человек</p>
            </div>
            <div className="contest-point-info">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M7 12H13C15.7614 12 18 14.2386 18 17C18 17.5523 17.5523 18 17 18C16.4872 18 16.0645 17.614 16.0067 17.1166L15.9949 16.8237C15.907 15.3072 14.6928 14.093 13.1763 14.0051L13 14H7C5.34315 14 4 15.3431 4 17C4 17.5523 3.55228 18 3 18C2.44772 18 2 17.5523 2 17C2 14.3112 4.12231 12.1182 6.78311 12.0046L7 12ZM10 2C10.5523 2 11 2.44772 11 3C11 3.55228 10.5523 4 10 4C8.34315 4 7 5.34315 7 7C7 8.65685 8.34315 10 10 10C10.7415 10 11.4387 9.73127 11.9833 9.25098C12.3975 8.88566 13.0294 8.92529 13.3947 9.33949C13.76 9.75369 13.7204 10.3856 13.3062 10.7509C12.3997 11.5504 11.2334 12 10 12C7.23858 12 5 9.76142 5 7C5 4.23858 7.23858 2 10 2ZM15 2C15.5523 2 16 2.44772 16 3V4H17C17.5523 4 18 4.44772 18 5C18 5.55228 17.5523 6 17 6H16V7C16 7.55228 15.5523 8 15 8C14.4477 8 14 7.55228 14 7V6H13C12.4477 6 12 5.55228 12 5C12 4.44772 12.4477 4 13 4H14V3C14 2.44772 14.4477 2 15 2Z" />
              </svg>
              <p>{(Math.floor(Date.now() / 1000) - this.state.offset) < this.state.register_starts ? `Регистрация начнётся ${contestDateConverter(this.state.register_starts)}` : `Регистрация до ${contestDateConverter(this.state.register_ends)}`}</p>
            </div>
          </div>
          {(() => {
            if (!this.context.login) {
              return <LoginContainer id="contest-login" title="Войдите или зарегайтесь, чтобы участвовать" />;
            } else if (this.state.is_admin) {
              return <div style={ {display: "flex", alignItems: "center", marginTop: "48px",} }>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M3 9.50023L7.81818 14.5002L17.3182 5.00024" stroke="var(--button-color)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" style={ {transition: "stroke var(--transition-timing) var(--transition-function)",} } />
                </svg>
                <p className="contest-registered-text">
                  Вы – администратор этого соревнования.<br />
                  Оно доступно вам в любое время
                </p>
              </div>;
            } else {
              if (this.state.registered && (Math.floor(Date.now() / 1000) - this.state.offset) <= this.state.ends) {
                return <div style={ {display: "flex", alignItems: "center", marginTop: "48px",} }>
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3 9.50023L7.81818 14.5002L17.3182 5.00024" stroke="var(--button-color)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" style={ {transition: "stroke var(--transition-timing) var(--transition-function)",} } />
                  </svg>
                  <p className="contest-registered-text">
                    Вы зарегистрированы на соревнование.<br />
                    {this.state.starts <= (Math.floor(Date.now() / 1000) - this.state.offset) ? "Скорее решайте задачки и вырывайтесь вперёд!" : "Ожидайте начала."}
                  </p>
                </div>;
              } else {
                if (this.state.register_starts <= (Math.floor(Date.now() / 1000) - this.state.offset) && (Math.floor(Date.now() / 1000) - this.state.offset) <= this.state.register_ends) {
                  return <MainButton className="submit-button" id="contest-register" onClick={() => this.register()}>Зарегистрироваться на соревнование</MainButton>;
                }
              }
            }
          })()}
          {(() => {
            let EnterText;
            if (this.state.ends <= (Math.floor(Date.now() / 1000) - this.state.offset)) {
              EnterText = () => <p>Соревнование окончено</p>;
            } else if (this.state.starts <= (Math.floor(Date.now() / 1000) - this.state.offset)) {
              EnterText = () => <p>Соревнование идёт<br />До конца: <Timer expiryTimestamp={this.state.ends * 1000} onExpire={() => this.forceUpdate()} now={Date.now() - this.state.offset * 1000} /></p>;
            } else {
              EnterText = () => <p>Соревнование начнётся через: <Timer expiryTimestamp={this.state.starts * 1000} onExpire={() => this.forceUpdate()} now={Date.now() - this.state.offset * 1000} /></p>;
            }
            const Wrapper = () => <div className="contest-running" style={ {width: this.state.registered ? "400px" : "230px",} }>
              <EnterText />
              {this.state.registered && <Link to={`/contest/${this.props.params.contest_id}/0`}><NoloadButton
                onClick={() => {}}
                className="contest-enter-button"
              >Войти</NoloadButton></Link>}
            </div>;

            if (
              (this.state.starts <= (Math.floor(Date.now() / 1000) - this.state.offset) + 5 * 60) // current date + 5 mins
              || this.state.is_admin
            ) {
              return <Wrapper />;
            }
          })()}

        </div>
      </>;
    } else {
      return <div className="main-content scalable">
        <Link to={"/contest" + this.props.location.search} className="upcoming-back-link">← К соревнованиям</Link>
      </div>;
    }
  }
}

const ContestWrapper = (props) => {
  return <UpcomingSingle {...props} params={useParams()} />;
}

export { ContestList, ContestWrapper };
