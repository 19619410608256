import ReactMarkdown from "react-markdown";
import remarkMath from "remark-math";
import rehypeKatex from "rehype-katex";
import clsx from "clsx";
import PropTypes from "prop-types";

export default function Markdown({ className, children }) {
  return <ReactMarkdown
    className={clsx(className)}
    remarkPlugins={[remarkMath]}
    rehypePlugins={[rehypeKatex]}
  >{children}</ReactMarkdown>;
}

ReactMarkdown.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
}
