import { useContext } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import clsx from "clsx";

import '../static/css/Components.css';

import GlobalContext from "../global";
import { UnlabeledInput } from "./inputs";
import { SecondaryButton } from "./buttons";
import UserAvatar from "./avatar";

export default function AdminList({ admins, addFunc, deleteFunc, error, setError, className }) {
  const context = useContext(GlobalContext);

  const canEditAdmins = admins[0].id === context.uid;

  return (
    <div className={clsx("admin-list", className)}>
      <p>Администраторы</p>
      <div className="admin-list-people">
        {admins.map(element => 
          <AdminListItem
            key={element.id}
            id={element.id}
            avatar={element.avatar}
            login={element.login}
            canDelete={canEditAdmins}
            deleteFunc={deleteFunc} 
          />
        )}
      </div>
      {canEditAdmins && <div className="admin-list-add-container">
        <div className="admin-list-add-flex">
          <UnlabeledInput
            id="admin-list-add-input"
            placeholder="ID или никнейм"
            maxLength={32}
            hasError={Boolean(error)}
            onChange={() => setError?.(null)}
          />
          <SecondaryButton onClick={addFunc}>
            <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M6.5714 6.04591L10.8489 6.04594C11.1649 6.04594 11.4202 5.79057 11.4202 5.47459C11.4202 5.15861 11.1648 4.90323 10.8489 4.90323L6.57139 4.9032L6.57136 0.625727C6.57136 0.309745 6.31598 0.0543715 6 0.0543694C5.68402 0.0543672 5.42865 0.309737 5.42865 0.625719L5.42868 4.90319L1.1512 4.90317C0.835222 4.90316 0.579853 5.15853 0.579855 5.47452C0.579856 5.6321 0.6437 5.77514 0.747142 5.87859C0.850584 5.98203 0.993625 6.04587 1.15121 6.04587L5.42869 6.0459L5.42872 10.3234C5.42872 10.481 5.49256 10.624 5.596 10.7274C5.69945 10.8309 5.84249 10.8947 6.00007 10.8947C6.31606 10.8947 6.57143 10.6394 6.57142 10.3234L6.5714 6.04591Z" fill="var(--additional-button-text)" transition="fill var(--transition-timing) var(--transition-function)" />
            </svg>
          </SecondaryButton>
        </div>
        {error && <span className="admin-list-add-error">{error}</span>}
      </div>}
    </div>
  )
}

function AdminListItem({ id, avatar, login, canDelete, deleteFunc }) {
  const context = useContext(GlobalContext);

  return <div className="admin-list-item">
    <Link to={"/profile/" + id}>
      <div>
        <UserAvatar src={avatar} size={30} className="admin-list-item-avatar" />
        <span>{login}</span>
      </div>
    </Link>
    {canDelete && (id === context.uid ? <span>Это вы</span> : <button onClick={() => deleteFunc(id)}>
      <svg width="10" height="10" viewBox="0 1 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M6.00996 5.74643L9.79054 1.96578C10.0698 1.6865 10.0698 1.23508 9.79054 0.955798C9.51127 0.676518 9.05986 0.676518 8.78058 0.955798L5 4.73645L1.21942 0.955798C0.940145 0.676518 0.488733 0.676518 0.209457 0.955798C-0.0698189 1.23508 -0.0698189 1.6865 0.209457 1.96578L3.99004 5.74643L0.209457 9.52707C-0.0698189 9.80635 -0.0698189 10.2578 0.209457 10.5371C0.348738 10.6763 0.531588 10.7463 0.714439 10.7463C0.897289 10.7463 1.08014 10.6763 1.21942 10.5371L5 6.75641L8.78058 10.5371C8.91986 10.6763 9.10271 10.7463 9.28556 10.7463C9.46841 10.7463 9.65126 10.6763 9.79054 10.5371C10.0698 10.2578 10.0698 9.80635 9.79054 9.52707L6.00996 5.74643Z" fill="var(--google-login-disabled-text)" transition="color var(--transition-timing) var(--transition-function)" />
      </svg>
    </button>)}
  </div>;
}

AdminList.propTypes = {
  admins: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    avatar: PropTypes.string.isRequired,
    login: PropTypes.string.isRequired,
  })).isRequired,
  addFunc: PropTypes.func.isRequired,
  deleteFunc: PropTypes.func.isRequired,
  error: PropTypes.node,
  setError: PropTypes.func,
  className: PropTypes.string,
}
