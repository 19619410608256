import axios from "axios";

const baseUrl = "https://api.sort-me.org"

export default async function fetchApi({ url, method = "get", ...other }) {
  const apiResponse = await axios({
    url: url,
    baseURL: baseUrl,
    method: method,
    ...other,
  });
  return apiResponse.data;
};

