import { useState } from "react";
import Button from "@material-ui/core/Button";
import clsx from "clsx";

function MainButton(props) {
  const [is_loading, setLoading] = useState(false);
  const [children, setChildren] = useState(props.children);

  async function handleClick(event) {
    setLoading(true);
    const result = await props.onClick(event);
    if (result?.hasOwnProperty("completed") && !result.completed) {
      switch(result.status) {
        case 429:
          setChildren(<>Ошибка. Слишком<br />много запросов</>);
          break;
        default:
          setChildren(<>Ошибка. {props.children}</>);
      }
    } else if (!result || result.hasOwnProperty("error")) {
      setChildren(result?.error_text || props.error || <>Ошибка. {props.children}</>); // error_text используется только при авторизации Telegram
    } else {
      setChildren(props.children);
    }
    setLoading(false);
  }

  return <Button
    classes={{ disabled: "submit-disabled", }}
    className={clsx("submit-button", props.className)}
    onClick={event => handleClick(event)}
    id={clsx(props.id)}
    disabled={is_loading || props.disabled}
  >
    {is_loading ? props.loading || "Загрузка..." : children}
  </Button>;
}

function NoloadButton(props) {
  return <Button
    classes={ {disabled: "submit-disabled",} }
    className={clsx("submit-button", props.className)}
    onClick={event => props.onClick?.(event)}
    id={clsx(props.id)}
  >
    {props.children}
  </Button>;
}

function SecondaryButton(props) {
  return <Button
    classes={ {disabled: "secondary-disabled",} }
    className={clsx("secondary-button", props.className)}
    onClick={event => props.onClick?.(event)}
    id={clsx(props.id)}
  >
    {props.children}
  </Button>;
}

export default MainButton;
export { NoloadButton, SecondaryButton };
