import axios from "axios";
import Cookies from 'js-cookie';
import React, { useContext, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';

import { Button, Container, MuiThemeProvider } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';

import { BrightnessMedium } from '@material-ui/icons';

import Main from './Main';
import GlobalContext, { parseToken, updateSize } from './global';
import './static/css/App.css';
import './static/css/Components.css';
import computer from './static/other/computer.png';

import UserAvatar from './components/avatar';
import { NoloadButton } from './components/buttons';

const theme = createTheme({
  typography: {
    fontFamily: "PT-Root-UI",
    fontSize: 16,
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "20px",
    letterSpacing: "0em",
    textAlign: "left",
  }
});

class App extends React.Component {
  constructor(props) {
    super(props);

    let theme = window.localStorage.getItem("theme");
    if (theme === null) {
      const user_theme = window.matchMedia("(prefers-color-scheme: dark)");
      window.localStorage.setItem("theme", user_theme.matches ? "dark" : "light");
      theme = user_theme.matches ? "dark" : "light";
    }

    const user_data = Cookies.getJSON("user_data");
    if (user_data !== undefined) {
      const parsed_token = parseToken(user_data.token);
      this.state = {
        login_data: {login: user_data.login, role: parsed_token.role, uid: parsed_token.uid, token: user_data.token},
        theme: theme,
        avatar: "https://api.sort-me.org/getUserAvatar?id=" + parsed_token.uid,
      };
      axios.defaults.headers.common = { "Authorization": `Bearer ${this.state.login_data.token}`, }
    } else {
      this.state = {
        login_data: {login: null, role: null, uid: null, token: null,},
        theme: theme,
        avatar: null,
      } 
    }
  }

  componentDidMount() {
    updateSize();
  }

  render() {
    const context = {
      ...this.state.login_data,
      theme: this.state.theme,
      avatar: this.state.avatar,
      toggleTheme: () => this.setState({theme: this.state.theme === "light" ? "dark" : "light"}),
      setNewData: (new_login, new_avatar) => this.setState({login_data: {...this.state.login_data, login: new_login,}, avatar: new_avatar,}),
    };

    if (window.matchMedia("only screen and (max-width: 600px)").matches && window.location.pathname === "/smolymp2022") {
      window.location.replace("https://t.me/sort_me/64");
      return null;
    }

    return (
      <GlobalContext.Provider value={context}>
        <MuiThemeProvider theme={theme}>
          <div className="theme-background" />
          <Container className="App">
            {window.matchMedia("only screen and (max-width: 600px)").matches && <MobileAlert />}

            <Navbar />
            <div className="content-background scalable" />
            <Main />
            <div className="content-outline scalable" />
          </Container>     
        </MuiThemeProvider>
      </GlobalContext.Provider>
    );
  }
}

function MobileAlert(props) {
  const [display, setDisplay] = useState("block");

  return <div className="mobile-alert" style={ {display: display,} }>
    <div className="mobile-alert-container">
      <svg width="101" height="19" viewBox="0 0 101 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.32 18.336C10.968 18.336 13.56 16.416 13.56 13.176C13.56 9.624 10.704 8.736 8.16 7.968C5.544 7.176 5.136 6.648 5.136 5.88C5.136 5.208 5.736 4.608 6.936 4.608C8.472 4.608 9.264 5.352 9.84 6.552L13.08 4.656C11.856 2.184 9.696 0.863999 6.936 0.863999C4.032 0.863999 1.296 2.736 1.296 5.976C1.296 9.192 3.744 10.392 6.24 11.088C8.76 11.784 9.72 12.192 9.72 13.224C9.72 13.872 9.264 14.592 7.44 14.592C5.544 14.592 4.512 13.656 3.912 12.264L0.6 14.184C1.536 16.656 3.792 18.336 7.32 18.336ZM23.7379 18.336C28.5619 18.336 32.4739 14.544 32.4739 9.6C32.4739 4.656 28.5619 0.863999 23.7379 0.863999C18.9139 0.863999 15.0019 4.656 15.0019 9.6C15.0019 14.544 18.9139 18.336 23.7379 18.336ZM23.7379 14.592C21.0019 14.592 18.8419 12.6 18.8419 9.6C18.8419 6.6 21.0019 4.608 23.7379 4.608C26.4739 4.608 28.6339 6.6 28.6339 9.6C28.6339 12.6 26.4739 14.592 23.7379 14.592ZM43.8279 18H47.9559L44.3799 11.832C46.2279 10.896 47.4759 9.024 47.4759 6.96C47.4759 3.768 44.9079 1.2 41.7159 1.2H34.9959V18H38.8359V12.48H40.6599L43.8279 18ZM38.8359 4.8H41.7159C42.7719 4.8 43.6359 5.736 43.6359 6.96C43.6359 8.184 42.7719 9.12 41.7159 9.12H38.8359V4.8ZM61.05 1.2H48.57V4.896H52.89V18H56.73V4.896H61.05V1.2ZM86.2509 1.2H82.4109L77.8509 8.664L73.2909 1.2H69.4509V18H73.2909V8.232L77.6349 15.36H78.0669L82.4109 8.232V18H86.2509V1.2ZM93.3384 14.304V11.328H99.3384V7.68H93.3384V4.896H99.9384V1.2H89.4984V18H100.058V14.304H93.3384Z" fill="var(--text)" transition="color var(--transition-timing) var(--transition-function)" />
      </svg>
      <img alt="computer" src={computer} />
      <h2>Мы пока сделали только десктопную версию</h2>
      <p>С телефона на Sort Me сидеть неудобно. Если рядом есть компьютер, советуем пересесть за него.</p>
      <NoloadButton className="mobile-ok" onClick={() => setDisplay("none")}>Ок</NoloadButton>
    </div>
  </div>;
}

function Navbar(props) {
  function updateTheme(new_theme) {
    const root = document.documentElement;
    document.body.classList.add("body-transitioning");
    setTimeout(() => document.body.classList.remove("body-transitioning"), 200);

    if (new_theme === "light") {
      // going light
      root.style.setProperty("--body-background", "#F8F8F8");
      root.style.setProperty("--text", "#222222");
      root.style.setProperty("--nav-hover-color", "#EBEBEB");
      root.style.setProperty("--background", "#FFFFFF");
      root.style.setProperty("--container-outline", "#0000001A");
      root.style.setProperty("--news-border", "#EDEDED");
      root.style.setProperty("--news-background", "#22222205");
      root.style.setProperty("--contest-blue", "#0B7CE6");
      root.style.setProperty("--tasks-red", "#E50B0B");
      root.style.setProperty("--courses-yellow", "#FFA96B");
      root.style.setProperty("--input-color", "#0000000D");
      root.style.setProperty("--input-outline", "#C4C4C4");
      root.style.setProperty("--input-text", "#515151");
      root.style.setProperty("--input-invalid-background", "#FB2A2A0D");
      root.style.setProperty("--input-invalid-border", "#FB2A2A80");
      root.style.setProperty("--input-invalid-placeholder", "#FB2A2A70");
      root.style.setProperty("--input-invalid-text", "#F95858");
      root.style.setProperty("--button-color", "#1771F1");
      root.style.setProperty("--additional-button-color-light", "#1771F10D");
      root.style.setProperty("--additional-button-color-regular", "#1771F11A");
      root.style.setProperty("--additional-button-text", "#1771F1");
      root.style.setProperty("--additional-button-color-red", "#D72A2A1A");
      root.style.setProperty("--additional-button-text-red", "#D72A2A");
      root.style.setProperty("--news-date", "#2222223B");
      root.style.setProperty("--difficulty", "#22222280");
      root.style.setProperty("--task-link-hover", "#222222C0");
      root.style.setProperty("--task-table-header", "#0000004D");
      root.style.setProperty("--subtask-background", "#FBFBFB");
      root.style.setProperty("--sample-background", "#F8F8F8");
      root.style.setProperty("--subtask-index", "#00000040");
      root.style.setProperty("--subtask-stroke", "#0000001A");
      root.style.setProperty("--code-editor-outline", "#E5E5E5");
      root.style.setProperty("--code-line", "#EBEBEB");
      root.style.setProperty("--code-numeration", "#C4C4C4");
      root.style.setProperty("--submission-green", "#327D18");
      root.style.setProperty("--submission-red", "#D72A2A");
      root.style.setProperty("--alert-separator", "#2222221A");
      root.style.setProperty("--alert-background", "#22222280");
      root.style.setProperty("--compilation-error", "#8C2929");
      root.style.setProperty("--placeholder-text", "#2222224D");
      root.style.setProperty("--tasklist-id", "#22222240");
      root.style.setProperty("--highlighted-text", "#000000");
      root.style.setProperty("--courses-estimated-time", "#222222BF");
      root.style.setProperty("--rating-secondary", "#F2F2F2");
      root.style.setProperty("--rating-primary", "#E1F1FF");
      root.style.setProperty("--rating-frozen-text", "#2B6997");
      root.style.setProperty("--rating-frozen-background", "#E5F3FE");
      root.style.setProperty("--button-disabled", "#8BB8F8");
      root.style.setProperty("--rating-page", "#DDDDDD");
      root.style.setProperty("--rating-page-hover", "#C4C4C4");
      root.style.setProperty("--google-login-border", "#E7E8EC");
      root.style.setProperty("--google-login-disabled-text", "#909090");
      root.style.setProperty("--rank-gray-background", "#C4C4C4");
      root.style.setProperty("--rank-gray-text", "#999999");
      root.style.setProperty("--rank-silver-background", "#E2ECF2");
      root.style.setProperty("--rank-silver-text", "#627077");
      root.style.setProperty("--rank-silver-inline", "#CCD6DD");
      root.style.setProperty("--rank-gold-background", "#F9E8D0");
      root.style.setProperty("--rank-gold-text", "#FFAC33");
      root.style.setProperty("--rank-gold-inline", "#FFD983");
      root.style.setProperty("--rank-gold-border", "#FFDCA9");
      root.style.setProperty("--rank-gold-cup", "#FFCC4D");
      root.style.setProperty("--rank-platinum1-stop", "#FFBB58");
      root.style.setProperty("--rank-platinum2-start", "#FFA755");
      root.style.setProperty("--rank-platinum2-stop", "#FF7A00");
      root.style.setProperty("--rank-platinum3-start", "#FF8686");
      root.style.setProperty("--rank-platinum3-stop", "#E72727");

      root.style.setProperty("--fade-invert", "0");
    } else {
      // going dark
      root.style.setProperty("--body-background", "#141414");
      root.style.setProperty("--text", "#DDDDDD");
      root.style.setProperty("--nav-hover-color", "#2F2F2F")
      root.style.setProperty("--background", "#171717");
      root.style.setProperty("--container-outline", "#36383D");
      root.style.setProperty("--news-border", "#1F1F1F");
      root.style.setProperty("--news-background", "#DDDDDD05");
      root.style.setProperty("--contest-blue", "#4CA2F3");
      root.style.setProperty("--tasks-red", "#F96161");
      root.style.setProperty("--courses-yellow", "#FFA96B");
      root.style.setProperty("--input-color", "#FFFFFF0D");
      root.style.setProperty("--input-outline", "#DDDDDD");
      root.style.setProperty("--input-text", "#E7E7E7");
      root.style.setProperty("--input-invalid-background", "#FB434326");
      root.style.setProperty("--input-invalid-border", "#FB434380");
      root.style.setProperty("--input-invalid-placeholder", "#FB434370");
      root.style.setProperty("--input-invalid-text", "#FA7171");
      root.style.setProperty("--button-color", "#2F80F2");
      root.style.setProperty("--additional-button-color-light", "#2F80F20D");
      root.style.setProperty("--additional-button-color-regular", "#5099FE1A");
      root.style.setProperty("--additional-button-text", "#5897EF");
      root.style.setProperty("--additional-button-color-red", "#F961611A");
      root.style.setProperty("--additional-button-text-red", "#F96161");
      root.style.setProperty("--news-date", "#DDDDDD80");
      root.style.setProperty("--difficulty", "#DDDDDD80");
      root.style.setProperty("--task-link-hover", "#DDDDDDC0");
      root.style.setProperty("--task-table-header", "#FFFFFF4D");
      root.style.setProperty("--subtask-background", "#111111");
      root.style.setProperty("--sample-background", "#141414");
      root.style.setProperty("--subtask-index", "#FFFFFF40");
      root.style.setProperty("--subtask-stroke", "#FFFFFF1A");
      root.style.setProperty("--code-editor-outline", "#272727");
      root.style.setProperty("--code-line", "#212121");
      root.style.setProperty("--code-numeration", "#404040");
      root.style.setProperty("--submission-green", "#4BB462");
      root.style.setProperty("--submission-red", "#F96161");
      root.style.setProperty("--alert-separator", "#DEDEDE1A");
      root.style.setProperty("--alert-background", "#00000099");
      root.style.setProperty("--compilation-error", "#FF8181");
      root.style.setProperty("--placeholder-text", "#DDDDDD4D");
      root.style.setProperty("--tasklist-id", "#DDDDDD40");
      root.style.setProperty("--highlighted-text", "#FFFFFF");
      root.style.setProperty("--courses-estimated-time", "#DDDDDDBF");
      root.style.setProperty("--rating-secondary", "#1D1D1D");
      root.style.setProperty("--rating-primary", "#2A314A");
      root.style.setProperty("--rating-frozen-text", "#88C9F9");
      root.style.setProperty("--rating-frozen-background", "#1F425B");
      root.style.setProperty("--button-disabled", "#234C85");
      root.style.setProperty("--rating-page", "#3C3C3C");
      root.style.setProperty("--rating-page-hover", "#2D2D2D");
      root.style.setProperty("--google-login-border", "#3C3C3C");
      root.style.setProperty("--google-login-disabled-text", "#EEEEEE");
      root.style.setProperty("--rank-gray-background", "#333333");
      root.style.setProperty("--rank-gray-text", "#5F5F5F");
      root.style.setProperty("--rank-silver-background", "#15252F");
      root.style.setProperty("--rank-silver-text", "#A8B5BC");
      root.style.setProperty("--rank-silver-inline", "#2C3942");
      root.style.setProperty("--rank-gold-background", "#462C09");
      root.style.setProperty("--rank-gold-text", "#FFB84D");
      root.style.setProperty("--rank-gold-inline", "#462C09");
      root.style.setProperty("--rank-gold-border", "#704300");
      root.style.setProperty("--rank-gold-cup", "#FFD467");
      root.style.setProperty("--rank-platinum1-stop", "#FFC875");
      root.style.setProperty("--rank-platinum2-start", "#FF7134 ");
      root.style.setProperty("--rank-platinum2-stop", "#FF6421");
      root.style.setProperty("--rank-platinum3-start", "#F05454");
      root.style.setProperty("--rank-platinum3-stop", "#EC3737");

      root.style.setProperty("--fade-invert", "0.909804");
    }
  }

  const global_data = useContext(GlobalContext);

  const [theme, setTheme] = useState(global_data.theme);

  updateTheme(theme);

  return (
    <Container classes={ {root: "navbar scalable",} }>
      <NavLink activeClassName="" to="/">
        <Button id="nav-title" variant="text" disableRipple={true}>
        <svg width="101" height="19" viewBox="0 0 101 19" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.31998 18.336C10.968 18.336 13.56 16.416 13.56 13.176C13.56 9.62402 10.704 8.73601 8.15998 7.96801C5.54398 7.17601 5.13598 6.64801 5.13598 5.88001C5.13598 5.20801 5.73598 4.60801 6.93598 4.60801C8.47198 4.60801 9.26398 5.35201 9.83998 6.55201L13.08 4.65601C11.856 2.18401 9.69598 0.864014 6.93598 0.864014C4.03198 0.864014 1.29598 2.73601 1.29598 5.97601C1.29598 9.19201 3.74398 10.392 6.23998 11.088C8.75998 11.784 9.71998 12.192 9.71998 13.224C9.71998 13.872 9.26398 14.592 7.43998 14.592C5.54398 14.592 4.51198 13.656 3.91198 12.264L0.599976 14.184C1.53598 16.656 3.79198 18.336 7.31998 18.336Z" fill="var(--text)" style={ {transition: "fill var(--transition-timing) var(--transition-function)",} } />
          <path d="M23.7379 18.336C28.5619 18.336 32.4739 14.544 32.4739 9.60001C32.4739 4.65601 28.5619 0.864014 23.7379 0.864014C18.9138 0.864014 15.0019 4.65601 15.0019 9.60001C15.0019 14.544 18.9138 18.336 23.7379 18.336ZM23.7379 14.592C21.0019 14.592 18.8419 12.6 18.8419 9.60001C18.8419 6.60001 21.0019 4.60801 23.7379 4.60801C26.4739 4.60801 28.6339 6.60001 28.6339 9.60001C28.6339 12.6 26.4739 14.592 23.7379 14.592Z" fill="var(--text)" style={ {transition: "fill var(--transition-timing) var(--transition-function)",} } />
          <path d="M43.8279 18H47.9559L44.3799 11.832C46.2279 10.896 47.4759 9.02401 47.4759 6.96001C47.4759 3.76801 44.9079 1.20001 41.7159 1.20001H34.9959V18H38.8359V12.48H40.6599L43.8279 18ZM38.8359 4.80001H41.7159C42.7719 4.80001 43.6359 5.73601 43.6359 6.96001C43.6359 8.18401 42.7719 9.12001 41.7159 9.12001H38.8359V4.80001Z" fill="var(--text)" style={ {transition: "fill var(--transition-timing) var(--transition-function)",} } />
          <path d="M61.05 1.20001H48.57V4.89601H52.89V18H56.73V4.89601H61.05V1.20001Z" fill="var(--text)" style={ {transition: "fill var(--transition-timing) var(--transition-function)",} } />
          <path d="M86.2509 1.20001H82.4109L77.8509 8.66401L73.2909 1.20001H69.4509V18H73.2909V8.23202L77.6349 15.36H78.0669L82.4109 8.23202V18H86.2509V1.20001Z" fill="var(--text)" style={ {transition: "fill var(--transition-timing) var(--transition-function)",} } />
          <path d="M93.3384 14.304V11.328H99.3384V7.68002H93.3384V4.89601H99.9384V1.20001H89.4984V18H100.058V14.304H93.3384Z" fill="var(--text)" style={ {transition: "fill var(--transition-timing) var(--transition-function)",} } />
        </svg>
        </Button>
      </NavLink>
      <NavLink activeClassName="active" to="/contest" id="nav-contest" className="nav-link">
        <Button classes={ {label: "nav-label", root: "nav-root",} } disableRipple={true}>Соревнования</Button>
      </NavLink>
      <NavLink activeClassName="active" to="/tasks" id="nav-tasks" className="nav-link">
        <Button classes={ {label: "nav-label", root: "nav-root",} } disableRipple={true}>Задачи</Button>
      </NavLink>
      {/* TODO: undo <NavLink activeClassName="active" to="/algorithms" id="nav-courses" className="nav-link">
        <Button classes={ {label: "nav-label", root: "nav-root",} } disableRipple={true}>Алгоритмы</Button>
      </NavLink>
      <NavLink activeClassName="active" to="/about" id="nav-about" className="nav-link">
        <Button classes={ {label: "nav-label", root: "nav-root",} } disableRipple={true}>О сайте</Button>
      </NavLink> */}
      <Button id="theme-switch" disableElevation disableRipple disableFocusRipple
        onMouseOver={() => {
          const icon = document.getElementsByClassName("theme-switch-icon")[0];

          icon.classList.add("theme-switch-rotated-in");
          icon.classList.remove("theme-switch-rotated-out");
        } }
        onMouseOut={() => {
          const icon = document.getElementsByClassName("theme-switch-icon")[0];

          icon.classList.remove("theme-switch-rotated-in");
          icon.classList.add("theme-switch-rotated-out");
        } }
        onClick={() => {
          const icon = document.getElementsByClassName("theme-switch-icon")[0];
          if (theme === "dark") {
            // convert to light
            setTheme("light");
            window.localStorage.setItem("theme", "light");
            icon.style.filter = "invert(0)";
            global_data.toggleTheme();
            updateTheme("light");
          } else {
            // convert to dark
            setTheme("dark");
            window.localStorage.setItem("theme", "dark");
            icon.style.filter = "invert(1)";
            global_data.toggleTheme();
            updateTheme("dark");
          }
          } }
      >
        <BrightnessMedium className="theme-switch-icon" style={ {filter: theme === "dark" ? "invert(1)" : "invert(0)"} } />
      </Button>
      <Link to="/profile">
        <UserAvatar src={global_data.avatar} size={36} id="nav-avatar" />
      </Link>
      <Link to="/profile">
        <span id="profile-name">{(global_data.login === undefined) ? "" : ((global_data.login === null) ? "Гость" : global_data.login)}</span>
      </Link>
    </Container>
  );
}

window.onresize = updateSize;

export default App;
