import { useCallback, useState, useEffect, useRef } from "react"
import { useDropzone } from "react-dropzone"
import styled from "styled-components"
import ArchiveUploaderContent from "./ArchiveUploaderContent"
import MainButton from "../../components/buttons"
import JSZip from "jszip"
import fetchApi from "../../utils/fetchApi"

const ZIP_MIME_TYPES = ["application/zip", "application/octet-stream", "application/x-zip-compressed", "multipart/x-zip"]

export default function ArchiveUploader({
  taskId,
  contestId,
  limits,
  submitCallback,
}) {
  const [state, setState] = useState("idle")
  const [loadedInfo, setLoadedInfo] = useState({})

  const currentFile = useRef(null)

  const readError = useCallback((file, error) => {
      setLoadedInfo({
        filename: file[0].name,
        size: file[0].size,
        count: 1,
        errorDetails: error,
      })
  }, [])

  const upload = useCallback(file => {
    setState("loading")

    const reader = new FileReader();

    reader.onabort = () => readError(file, "Произошла ошибка при чтении файла")
    reader.onerror = () => readError(file, "Произошла ошибка при чтении файла")
    reader.onload = async () => {
      if (!ZIP_MIME_TYPES.includes(file[0].type)) {
        readError(file, "Файл не является ZIP-архивом")
        return
      }
      const zip = new JSZip()
      await zip.loadAsync(reader.result)
      setLoadedInfo({
        filename: file[0].name,
        size: file[0].size,
        count: Object.keys(zip.files).length,
        errorDetails: null,
      })
      currentFile.current = file[0]
    }
    try {
      reader.readAsArrayBuffer(file[0])
    } catch (e) {
      setLoadedInfo({
        filename: "неизвестно",
        size: 0,
        count: 0,
        errorDetails: "Произошла ошибка при чтении файла",
      })
    } finally {
      setState("idle")
    }
  }, [readError])

  const send = useCallback(async () => {
    let formData = new FormData();
    formData.append("document", currentFile.current);

    const apiResponse = await fetchApi({
      url: "/submit_zip",
      method: "post",
      data: formData,
      params: {
        "task_id": taskId,
        "contest_id": contestId,
      },
      headers: { "Content-Type": "multipart/form-data" },
    }).catch(({ response: { data } }) => ({ error: data.error ?? "Ошибка при чтении файла на сервере" }))

    if (apiResponse.error) {
      setLoadedInfo(info => ({
        ...info,
        errorDetails: apiResponse.error,
      }))
      setState("idle")
      return
    }

    setState("idle")

    if (apiResponse.id) setLoadedInfo({})
    submitCallback(apiResponse.id)

    return apiResponse
  }, [contestId, submitCallback, taskId])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop: upload, multiple: false })

  useEffect(() => {
    if (isDragActive) {
      setState("hover")
    } else {
      setState("idle")
    }
  }, [isDragActive])

  return (
    <Wrapper>
      <Uploader {...getRootProps()}>
        <ArchiveUploaderContent
          state={state}
          loadedInfo={loadedInfo}
          taskId={taskId}
          contestId={contestId}
        />
        <input {...getInputProps({
          accept: "application/zip",
        })} />
      </Uploader>
      <SendButton
        onClick={send}
        disabled={!loadedInfo.filename || !!loadedInfo.errorDetails}
      >Отправить</SendButton>
      <Limits>
        <span>Лимит времени: {limits.time} мс</span>
        <span>Лимит памяти: {limits.memory} мб</span>
      </Limits>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: grid;
  grid-template-areas:
    "uploader uploader"
    "button limits";
  row-gap: 32px;
`

const Uploader = styled.div`
  grid-area: uploader;
  cursor: pointer;
  background-color: var(--body-background);
  border: 1px solid var(--code-editor-outline);
  border-radius: 4px;
  transition: border-color var(--transition-timing) var(--transition-function),
    background-color var(--transition-timing) var(--transition-function);

  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 600px;
  height: 204px;

  margin-top: 32px;
`

const SendButton = styled(MainButton)`
  grid-area: button;
  width: 214px;
  height: 40px;
  padding: 10px 8px;
`

const Limits = styled.div`
  grid-area: limits;
  display: flex;
  flex-direction: column;

  > span {
    font-family: PT-Root-UI-Medium, Tahoma, Sans-Serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-align: right;
    letter-spacing: 0.01em;
    color: var(--difficulty);
    transition: color var(--transition-timing) var(--transition-function);
  }
`
