function UnknownRank() {
  return <svg width="158" height="48" viewBox="0 0 158 48" fill="none" xmlns="http://www.w3.org/2000/svg" className="rank-badge">
    <rect width="158" height="48" rx="8" fill="var(--rank-gray-background)"/>
    <path d="M77.33 25.148C77.33 24.536 77.402 24.032 77.546 23.636C77.69 23.228 77.864 22.886 78.068 22.61C78.284 22.322 78.512 22.082 78.752 21.89C79.004 21.698 79.232 21.512 79.436 21.332C79.652 21.14 79.832 20.93 79.976 20.702C80.12 20.462 80.192 20.168 80.192 19.82C80.192 19.34 80.042 18.962 79.742 18.686C79.454 18.398 79.04 18.254 78.5 18.254C77.936 18.254 77.492 18.41 77.168 18.722C76.856 19.034 76.676 19.46 76.628 20H74.36C74.384 19.448 74.492 18.944 74.684 18.488C74.888 18.032 75.164 17.636 75.512 17.3C75.872 16.964 76.292 16.706 76.772 16.526C77.264 16.334 77.81 16.238 78.41 16.238C79.07 16.238 79.652 16.322 80.156 16.49C80.66 16.658 81.08 16.892 81.416 17.192C81.764 17.492 82.022 17.852 82.19 18.272C82.37 18.68 82.46 19.136 82.46 19.64C82.46 20.156 82.388 20.588 82.244 20.936C82.1 21.284 81.914 21.59 81.686 21.854C81.47 22.106 81.23 22.334 80.966 22.538C80.714 22.742 80.474 22.964 80.246 23.204C80.03 23.444 79.85 23.714 79.706 24.014C79.562 24.314 79.49 24.692 79.49 25.148H77.33ZM78.41 29.162C77.99 29.162 77.642 29.024 77.366 28.748C77.102 28.46 76.97 28.118 76.97 27.722C76.97 27.326 77.102 26.99 77.366 26.714C77.642 26.426 77.99 26.282 78.41 26.282C78.83 26.282 79.172 26.426 79.436 26.714C79.712 26.99 79.85 27.326 79.85 27.722C79.85 28.118 79.712 28.46 79.436 28.748C79.172 29.024 78.83 29.162 78.41 29.162Z" fill="var(--rank-gray-text)"/>
  </svg>;
}

function ExpiredRank() {
  return <svg width="158" height="48" viewBox="0 0 158 48" fill="none" xmlns="http://www.w3.org/2000/svg" className="rank-badge">
    <rect width="158" height="48" rx="8" fill="var(--rank-gray-background)"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M79 33C83.9706 33 88 28.9706 88 24C88 19.0294 83.9706 15 79 15C74.0294 15 70 19.0294 70 24C70 28.9706 74.0294 33 79 33ZM79 31C75.134 31 72 27.866 72 24C72 20.134 75.134 17 79 17C82.866 17 86 20.134 86 24C86 27.866 82.866 31 79 31ZM79 19C79.5523 19 80 19.4477 80 20V22.5858L81.7071 24.2929C82.0976 24.6834 82.0976 25.3166 81.7071 25.7071C81.3166 26.0976 80.6834 26.0976 80.2929 25.7071L78.2929 23.7071C78.1054 23.5196 78 23.2652 78 23V20C78 19.4477 78.4477 19 79 19Z" fill="var(--rank-gray-text)"/>
  </svg>;  
}

function SilverOneRank() {
  return <svg width="158" height="48" viewBox="0 0 158 48" fill="none" xmlns="http://www.w3.org/2000/svg" className="rank-badge">
    <rect width="158" height="48" rx="8" fill="var(--rank-silver-background)"/>
    <path d="M84.4617 33.1318C84.2251 33.1318 83.9884 33.0584 83.7851 32.9135L78.9988 29.4821L74.2142 32.9135C73.8059 33.2051 73.2593 33.2051 72.8543 32.9084C72.4494 32.6151 72.2794 32.0935 72.4294 31.6186L74.2142 25.859L69.4729 22.5176C69.0696 22.221 68.903 21.6977 69.0563 21.2211C69.2113 20.7461 69.6529 20.4228 70.1545 20.4195L76.0274 20.4111L77.8972 14.7949C78.0556 14.32 78.4989 14 79.0005 14C79.5004 14 79.9454 14.32 80.1037 14.7949L81.9419 20.4111L87.8448 20.4195C88.3464 20.4228 88.7897 20.7461 88.943 21.2211C89.098 21.6977 88.9297 22.2193 88.5264 22.5176L83.7834 25.859L85.5683 31.6186C85.7216 32.0952 85.5483 32.6151 85.145 32.9084C84.94 33.0584 84.7 33.1318 84.4617 33.1318Z" fill="var(--rank-silver-text)"/>
  </svg>;  
}

function SilverTwoRank() {
  return <svg width="158" height="48" viewBox="0 0 158 48" fill="none" xmlns="http://www.w3.org/2000/svg" className="rank-badge">
    <rect width="158" height="48" rx="8" fill="var(--rank-silver-background)"/>
    <path d="M68.4617 33.1318C68.2251 33.1318 67.9884 33.0584 67.7851 32.9135L62.9988 29.4821L58.2142 32.9135C57.8059 33.2051 57.2593 33.2051 56.8543 32.9084C56.4494 32.6151 56.2794 32.0935 56.4294 31.6186L58.2142 25.859L53.4729 22.5176C53.0696 22.221 52.903 21.6977 53.0563 21.2211C53.2113 20.7461 53.6529 20.4228 54.1545 20.4195L60.0274 20.4111L61.8972 14.7949C62.0556 14.32 62.4989 14 63.0005 14C63.5004 14 63.9454 14.32 64.1037 14.7949L65.9419 20.4111L71.8448 20.4195C72.3464 20.4228 72.7897 20.7461 72.943 21.2211C73.098 21.6977 72.9297 22.2193 72.5264 22.5176L67.7834 25.859L69.5683 31.6186C69.7216 32.0952 69.5483 32.6151 69.145 32.9084C68.94 33.0584 68.7 33.1318 68.4617 33.1318Z" fill="var(--rank-silver-text)"/>
    <path d="M100.462 33.1318C100.225 33.1318 99.9884 33.0584 99.7851 32.9135L94.9988 29.4821L90.2142 32.9135C89.8059 33.2051 89.2593 33.2051 88.8543 32.9084C88.4494 32.6151 88.2794 32.0935 88.4294 31.6186L90.2142 25.859L85.4729 22.5176C85.0696 22.221 84.903 21.6977 85.0563 21.2211C85.2113 20.7461 85.6529 20.4228 86.1545 20.4195L92.0274 20.4111L93.8972 14.7949C94.0556 14.32 94.4989 14 95.0005 14C95.5004 14 95.9454 14.32 96.1037 14.7949L97.9419 20.4111L103.845 20.4195C104.346 20.4228 104.79 20.7461 104.943 21.2211C105.098 21.6977 104.93 22.2193 104.526 22.5176L99.7834 25.859L101.568 31.6186C101.722 32.0952 101.548 32.6151 101.145 32.9084C100.94 33.0584 100.7 33.1318 100.462 33.1318Z" fill="var(--rank-silver-text)"/>
  </svg>;  
}

function SilverThreeRank() {
  return <svg width="158" height="48" viewBox="0 0 158 48" fill="none" xmlns="http://www.w3.org/2000/svg" className="rank-badge">
    <rect width="158" height="48" rx="8" fill="var(--rank-silver-background)"/>
    <path d="M52.4617 33.1318C52.2251 33.1318 51.9884 33.0584 51.7851 32.9135L46.9988 29.4821L42.2142 32.9135C41.8059 33.2051 41.2593 33.2051 40.8543 32.9084C40.4494 32.6151 40.2794 32.0935 40.4294 31.6186L42.2142 25.859L37.4729 22.5176C37.0696 22.221 36.903 21.6977 37.0563 21.2211C37.2113 20.7461 37.6529 20.4228 38.1545 20.4195L44.0274 20.4111L45.8972 14.7949C46.0556 14.32 46.4989 14 47.0005 14C47.5004 14 47.9454 14.32 48.1037 14.7949L49.9419 20.4111L55.8448 20.4195C56.3464 20.4228 56.7897 20.7461 56.943 21.2211C57.098 21.6977 56.9297 22.2193 56.5264 22.5176L51.7834 25.859L53.5683 31.6186C53.7216 32.0952 53.5483 32.6151 53.145 32.9084C52.94 33.0584 52.7 33.1318 52.4617 33.1318Z" fill="var(--rank-silver-text)"/>
    <path d="M84.4617 33.1318C84.2251 33.1318 83.9884 33.0584 83.7851 32.9135L78.9988 29.4821L74.2142 32.9135C73.8059 33.2051 73.2593 33.2051 72.8543 32.9084C72.4494 32.6151 72.2794 32.0935 72.4294 31.6186L74.2142 25.859L69.4729 22.5176C69.0696 22.221 68.903 21.6977 69.0563 21.2211C69.2113 20.7461 69.6529 20.4228 70.1545 20.4195L76.0274 20.4111L77.8972 14.7949C78.0556 14.32 78.4989 14 79.0005 14C79.5004 14 79.9454 14.32 80.1037 14.7949L81.9419 20.4111L87.8448 20.4195C88.3464 20.4228 88.7897 20.7461 88.943 21.2211C89.098 21.6977 88.9297 22.2193 88.5264 22.5176L83.7834 25.859L85.5683 31.6186C85.7216 32.0952 85.5483 32.6151 85.145 32.9084C84.94 33.0584 84.7 33.1318 84.4617 33.1318Z" fill="var(--rank-silver-text)"/>
    <path d="M116.462 33.1318C116.225 33.1318 115.988 33.0584 115.785 32.9135L110.999 29.4821L106.214 32.9135C105.806 33.2051 105.259 33.2051 104.854 32.9084C104.449 32.6151 104.279 32.0935 104.429 31.6186L106.214 25.859L101.473 22.5176C101.07 22.221 100.903 21.6977 101.056 21.2211C101.211 20.7461 101.653 20.4228 102.155 20.4195L108.027 20.4111L109.897 14.7949C110.056 14.32 110.499 14 111 14C111.5 14 111.945 14.32 112.104 14.7949L113.942 20.4111L119.845 20.4195C120.346 20.4228 120.79 20.7461 120.943 21.2211C121.098 21.6977 120.93 22.2193 120.526 22.5176L115.783 25.859L117.568 31.6186C117.722 32.0952 117.548 32.6151 117.145 32.9084C116.94 33.0584 116.7 33.1318 116.462 33.1318Z" fill="var(--rank-silver-text)"/>
  </svg>;  
}

function GoldOneRank() {
  return <svg width="158" height="48" viewBox="0 0 158 48" fill="none" xmlns="http://www.w3.org/2000/svg" className="rank-badge">
    <rect width="158" height="48" rx="8" fill="var(--rank-gold-background)"/>
    <path d="M84.4617 33.1318C84.2251 33.1318 83.9884 33.0584 83.7851 32.9135L78.9988 29.4821L74.2142 32.9135C73.8059 33.2051 73.2593 33.2051 72.8543 32.9084C72.4494 32.6151 72.2794 32.0935 72.4294 31.6186L74.2142 25.859L69.4729 22.5176C69.0696 22.221 68.903 21.6977 69.0563 21.2211C69.2113 20.7461 69.6529 20.4228 70.1545 20.4195L76.0274 20.4111L77.8972 14.7949C78.0556 14.32 78.4989 14 79.0005 14C79.5004 14 79.9454 14.32 80.1037 14.7949L81.9419 20.4111L87.8448 20.4195C88.3464 20.4228 88.7897 20.7461 88.943 21.2211C89.098 21.6977 88.9297 22.2193 88.5264 22.5176L83.7834 25.859L85.5683 31.6186C85.7216 32.0952 85.5483 32.6151 85.145 32.9084C84.94 33.0584 84.7 33.1318 84.4617 33.1318Z" fill="var(--rank-gold-text)"/>
    <path d="M17.84 18.308H17.174C16.466 18.308 15.95 18.476 15.626 18.812C15.302 19.136 15.14 19.664 15.14 20.396V21.926C15.14 22.202 15.122 22.466 15.086 22.718C15.062 22.958 15.002 23.186 14.906 23.402C14.81 23.618 14.672 23.822 14.492 24.014C14.312 24.206 14.072 24.374 13.772 24.518C14.072 24.662 14.312 24.83 14.492 25.022C14.672 25.202 14.81 25.4 14.906 25.616C15.002 25.832 15.062 26.066 15.086 26.318C15.122 26.558 15.14 26.81 15.14 27.074V28.604C15.14 29.336 15.302 29.864 15.626 30.188C15.95 30.524 16.466 30.692 17.174 30.692H17.84V32.6H16.634C14.198 32.6 12.98 31.382 12.98 28.946V27.326C12.98 26.678 12.842 26.216 12.566 25.94C12.302 25.664 11.96 25.496 11.54 25.436V23.564C11.96 23.504 12.302 23.342 12.566 23.078C12.842 22.802 12.98 22.34 12.98 21.692V20.054C12.98 17.618 14.198 16.4 16.634 16.4H17.84V18.308Z" fill="var(--rank-gold-text)"/>
    <path d="M139.342 30.692H140.008C140.716 30.692 141.232 30.524 141.556 30.188C141.88 29.864 142.042 29.336 142.042 28.604V27.074C142.042 26.798 142.054 26.54 142.078 26.3C142.114 26.048 142.18 25.814 142.276 25.598C142.372 25.382 142.51 25.178 142.69 24.986C142.87 24.794 143.11 24.626 143.41 24.482C143.11 24.338 142.87 24.176 142.69 23.996C142.51 23.804 142.372 23.6 142.276 23.384C142.18 23.168 142.114 22.94 142.078 22.7C142.054 22.448 142.042 22.19 142.042 21.926V20.396C142.042 19.664 141.88 19.136 141.556 18.812C141.232 18.476 140.716 18.308 140.008 18.308H139.342V16.4H140.548C142.984 16.4 144.202 17.618 144.202 20.054V21.674C144.202 22.322 144.334 22.784 144.598 23.06C144.874 23.336 145.222 23.504 145.642 23.564V25.436C145.222 25.496 144.874 25.664 144.598 25.94C144.334 26.204 144.202 26.66 144.202 27.308V28.946C144.202 31.382 142.984 32.6 140.548 32.6H139.342V30.692Z" fill="var(--rank-gold-text)"/>
  </svg>;  
}

function GoldTwoRank() {
  return <svg width="158" height="48" viewBox="0 0 158 48" fill="none" xmlns="http://www.w3.org/2000/svg" className="rank-badge">
    <rect width="158" height="48" rx="8" fill="var(--rank-gold-background)"/>
    <path d="M68.4617 33.1318C68.2251 33.1318 67.9884 33.0584 67.7851 32.9135L62.9988 29.4821L58.2142 32.9135C57.8059 33.2051 57.2593 33.2051 56.8543 32.9084C56.4494 32.6151 56.2794 32.0935 56.4294 31.6186L58.2142 25.859L53.4729 22.5176C53.0696 22.221 52.903 21.6977 53.0563 21.2211C53.2113 20.7461 53.6529 20.4228 54.1545 20.4195L60.0274 20.4111L61.8972 14.7949C62.0556 14.32 62.4989 14 63.0005 14C63.5004 14 63.9454 14.32 64.1037 14.7949L65.9419 20.4111L71.8448 20.4195C72.3464 20.4228 72.7897 20.7461 72.943 21.2211C73.098 21.6977 72.9297 22.2193 72.5264 22.5176L67.7834 25.859L69.5683 31.6186C69.7216 32.0952 69.5483 32.6151 69.145 32.9084C68.94 33.0584 68.7 33.1318 68.4617 33.1318Z" fill="var(--rank-gold-text)"/>
    <path d="M100.462 33.1318C100.225 33.1318 99.9884 33.0584 99.7851 32.9135L94.9988 29.4821L90.2142 32.9135C89.8059 33.2051 89.2593 33.2051 88.8543 32.9084C88.4494 32.6151 88.2794 32.0935 88.4294 31.6186L90.2142 25.859L85.4729 22.5176C85.0696 22.221 84.903 21.6977 85.0563 21.2211C85.2113 20.7461 85.6529 20.4228 86.1545 20.4195L92.0274 20.4111L93.8972 14.7949C94.0556 14.32 94.4989 14 95.0005 14C95.5004 14 95.9454 14.32 96.1037 14.7949L97.9419 20.4111L103.845 20.4195C104.346 20.4228 104.79 20.7461 104.943 21.2211C105.098 21.6977 104.93 22.2193 104.526 22.5176L99.7834 25.859L101.568 31.6186C101.722 32.0952 101.548 32.6151 101.145 32.9084C100.94 33.0584 100.7 33.1318 100.462 33.1318Z" fill="var(--rank-gold-text)"/>
    <path d="M17.84 18.308H17.174C16.466 18.308 15.95 18.476 15.626 18.812C15.302 19.136 15.14 19.664 15.14 20.396V21.926C15.14 22.202 15.122 22.466 15.086 22.718C15.062 22.958 15.002 23.186 14.906 23.402C14.81 23.618 14.672 23.822 14.492 24.014C14.312 24.206 14.072 24.374 13.772 24.518C14.072 24.662 14.312 24.83 14.492 25.022C14.672 25.202 14.81 25.4 14.906 25.616C15.002 25.832 15.062 26.066 15.086 26.318C15.122 26.558 15.14 26.81 15.14 27.074V28.604C15.14 29.336 15.302 29.864 15.626 30.188C15.95 30.524 16.466 30.692 17.174 30.692H17.84V32.6H16.634C14.198 32.6 12.98 31.382 12.98 28.946V27.326C12.98 26.678 12.842 26.216 12.566 25.94C12.302 25.664 11.96 25.496 11.54 25.436V23.564C11.96 23.504 12.302 23.342 12.566 23.078C12.842 22.802 12.98 22.34 12.98 21.692V20.054C12.98 17.618 14.198 16.4 16.634 16.4H17.84V18.308Z" fill="var(--rank-gold-text)"/>
    <path d="M140.135 30.692H140.801C141.509 30.692 142.025 30.524 142.349 30.188C142.673 29.864 142.835 29.336 142.835 28.604V27.074C142.835 26.798 142.847 26.54 142.871 26.3C142.907 26.048 142.973 25.814 143.069 25.598C143.165 25.382 143.303 25.178 143.483 24.986C143.663 24.794 143.903 24.626 144.203 24.482C143.903 24.338 143.663 24.176 143.483 23.996C143.303 23.804 143.165 23.6 143.069 23.384C142.973 23.168 142.907 22.94 142.871 22.7C142.847 22.448 142.835 22.19 142.835 21.926V20.396C142.835 19.664 142.673 19.136 142.349 18.812C142.025 18.476 141.509 18.308 140.801 18.308H140.135V16.4H141.341C143.777 16.4 144.995 17.618 144.995 20.054V21.674C144.995 22.322 145.127 22.784 145.391 23.06C145.667 23.336 146.015 23.504 146.435 23.564V25.436C146.015 25.496 145.667 25.664 145.391 25.94C145.127 26.204 144.995 26.66 144.995 27.308V28.946C144.995 31.382 143.777 32.6 141.341 32.6H140.135V30.692Z" fill="var(--rank-gold-text)"/>
  </svg>;  
}

function GoldThreeRank() {
  return <svg width="158" height="48" viewBox="0 0 158 48" fill="none" xmlns="http://www.w3.org/2000/svg" className="rank-badge">
    <rect width="158" height="48" rx="8" fill="var(--rank-gold-background)"/>
    <path d="M52.4617 33.1318C52.2251 33.1318 51.9884 33.0584 51.7851 32.9135L46.9988 29.4821L42.2142 32.9135C41.8059 33.2051 41.2593 33.2051 40.8543 32.9084C40.4494 32.6151 40.2794 32.0935 40.4294 31.6186L42.2142 25.859L37.4729 22.5176C37.0696 22.221 36.903 21.6977 37.0563 21.2211C37.2113 20.7461 37.6529 20.4228 38.1545 20.4195L44.0274 20.4111L45.8972 14.7949C46.0556 14.32 46.4989 14 47.0005 14C47.5004 14 47.9454 14.32 48.1037 14.7949L49.9419 20.4111L55.8448 20.4195C56.3464 20.4228 56.7897 20.7461 56.943 21.2211C57.098 21.6977 56.9297 22.2193 56.5264 22.5176L51.7834 25.859L53.5683 31.6186C53.7216 32.0952 53.5483 32.6151 53.145 32.9084C52.94 33.0584 52.7 33.1318 52.4617 33.1318Z" fill="var(--rank-gold-text)"/>
    <path d="M84.4617 33.1318C84.2251 33.1318 83.9884 33.0584 83.7851 32.9135L78.9988 29.4821L74.2142 32.9135C73.8059 33.2051 73.2593 33.2051 72.8543 32.9084C72.4494 32.6151 72.2794 32.0935 72.4294 31.6186L74.2142 25.859L69.4729 22.5176C69.0696 22.221 68.903 21.6977 69.0563 21.2211C69.2113 20.7461 69.6529 20.4228 70.1545 20.4195L76.0274 20.4111L77.8972 14.7949C78.0556 14.32 78.4989 14 79.0005 14C79.5004 14 79.9454 14.32 80.1037 14.7949L81.9419 20.4111L87.8448 20.4195C88.3464 20.4228 88.7897 20.7461 88.943 21.2211C89.098 21.6977 88.9297 22.2193 88.5264 22.5176L83.7834 25.859L85.5683 31.6186C85.7216 32.0952 85.5483 32.6151 85.145 32.9084C84.94 33.0584 84.7 33.1318 84.4617 33.1318Z" fill="var(--rank-gold-text)"/>
    <path d="M116.462 33.1318C116.225 33.1318 115.988 33.0584 115.785 32.9135L110.999 29.4821L106.214 32.9135C105.806 33.2051 105.259 33.2051 104.854 32.9084C104.449 32.6151 104.279 32.0935 104.429 31.6186L106.214 25.859L101.473 22.5176C101.07 22.221 100.903 21.6977 101.056 21.2211C101.211 20.7461 101.653 20.4228 102.155 20.4195L108.027 20.4111L109.897 14.7949C110.056 14.32 110.499 14 111 14C111.5 14 111.945 14.32 112.104 14.7949L113.942 20.4111L119.845 20.4195C120.346 20.4228 120.79 20.7461 120.943 21.2211C121.098 21.6977 120.93 22.2193 120.526 22.5176L115.783 25.859L117.568 31.6186C117.722 32.0952 117.548 32.6151 117.145 32.9084C116.94 33.0584 116.7 33.1318 116.462 33.1318Z" fill="var(--rank-gold-text)"/>
    <path d="M17.84 18.308H17.174C16.466 18.308 15.95 18.476 15.626 18.812C15.302 19.136 15.14 19.664 15.14 20.396V21.926C15.14 22.202 15.122 22.466 15.086 22.718C15.062 22.958 15.002 23.186 14.906 23.402C14.81 23.618 14.672 23.822 14.492 24.014C14.312 24.206 14.072 24.374 13.772 24.518C14.072 24.662 14.312 24.83 14.492 25.022C14.672 25.202 14.81 25.4 14.906 25.616C15.002 25.832 15.062 26.066 15.086 26.318C15.122 26.558 15.14 26.81 15.14 27.074V28.604C15.14 29.336 15.302 29.864 15.626 30.188C15.95 30.524 16.466 30.692 17.174 30.692H17.84V32.6H16.634C14.198 32.6 12.98 31.382 12.98 28.946V27.326C12.98 26.678 12.842 26.216 12.566 25.94C12.302 25.664 11.96 25.496 11.54 25.436V23.564C11.96 23.504 12.302 23.342 12.566 23.078C12.842 22.802 12.98 22.34 12.98 21.692V20.054C12.98 17.618 14.198 16.4 16.634 16.4H17.84V18.308Z" fill="var(--rank-gold-text)"/>
    <path d="M140.135 30.692H140.801C141.509 30.692 142.025 30.524 142.349 30.188C142.673 29.864 142.835 29.336 142.835 28.604V27.074C142.835 26.798 142.847 26.54 142.871 26.3C142.907 26.048 142.973 25.814 143.069 25.598C143.165 25.382 143.303 25.178 143.483 24.986C143.663 24.794 143.903 24.626 144.203 24.482C143.903 24.338 143.663 24.176 143.483 23.996C143.303 23.804 143.165 23.6 143.069 23.384C142.973 23.168 142.907 22.94 142.871 22.7C142.847 22.448 142.835 22.19 142.835 21.926V20.396C142.835 19.664 142.673 19.136 142.349 18.812C142.025 18.476 141.509 18.308 140.801 18.308H140.135V16.4H141.341C143.777 16.4 144.995 17.618 144.995 20.054V21.674C144.995 22.322 145.127 22.784 145.391 23.06C145.667 23.336 146.015 23.504 146.435 23.564V25.436C146.015 25.496 145.667 25.664 145.391 25.94C145.127 26.204 144.995 26.66 144.995 27.308V28.946C144.995 31.382 143.777 32.6 141.341 32.6H140.135V30.692Z" fill="var(--rank-gold-text)"/>
  </svg>;  
}

function PlatinumOneRank() {
  return <svg width="158" height="48" viewBox="0 0 158 48" fill="none" xmlns="http://www.w3.org/2000/svg" className="rank-badge">
    <rect x="1" y="1" width="156" height="46" rx="7" fill="var(--rank-gold-background)"/>
    <circle cx="79" cy="24" r="14" fill="var(--rank-gold-text)"/>
    <path d="M79 34.5C84.799 34.5 89.5 29.799 89.5 24C89.5 18.201 84.799 13.5 79 13.5C73.201 13.5 68.5 18.201 68.5 24C68.5 29.799 73.201 34.5 79 34.5Z" fill="var(--rank-gold-inline)"/>
    <path d="M82.8232 31C82.6575 31 82.4919 30.9463 82.3496 30.8402L78.9992 28.3293L75.6499 30.8402C75.3641 31.0537 74.9815 31.0537 74.698 30.8366C74.4145 30.622 74.2956 30.2402 74.4005 29.8927L75.6499 25.678L72.3311 23.2329C72.0487 23.0159 71.9321 22.6329 72.0394 22.2841C72.1479 21.9366 72.457 21.7 72.8082 21.6976L76.9192 21.6915L78.2281 17.5817C78.3389 17.2341 78.6492 17 79.0003 17C79.3503 17 79.6618 17.2341 79.7726 17.5817L81.0593 21.6915L85.1913 21.6976C85.5425 21.7 85.8528 21.9366 85.9601 22.2841C86.0686 22.6329 85.9508 23.0146 85.6685 23.2329L82.3484 25.678L83.5978 29.8927C83.7051 30.2415 83.5838 30.622 83.3015 30.8366C83.158 30.9463 82.99 31 82.8232 31Z" fill="var(--rank-gold-text)"/>
    <path d="M17.84 18.308H17.174C16.466 18.308 15.95 18.476 15.626 18.812C15.302 19.136 15.14 19.664 15.14 20.396V21.926C15.14 22.202 15.122 22.466 15.086 22.718C15.062 22.958 15.002 23.186 14.906 23.402C14.81 23.618 14.672 23.822 14.492 24.014C14.312 24.206 14.072 24.374 13.772 24.518C14.072 24.662 14.312 24.83 14.492 25.022C14.672 25.202 14.81 25.4 14.906 25.616C15.002 25.832 15.062 26.066 15.086 26.318C15.122 26.558 15.14 26.81 15.14 27.074V28.604C15.14 29.336 15.302 29.864 15.626 30.188C15.95 30.524 16.466 30.692 17.174 30.692H17.84V32.6H16.634C14.198 32.6 12.98 31.382 12.98 28.946V27.326C12.98 26.678 12.842 26.216 12.566 25.94C12.302 25.664 11.96 25.496 11.54 25.436V23.564C11.96 23.504 12.302 23.342 12.566 23.078C12.842 22.802 12.98 22.34 12.98 21.692V20.054C12.98 17.618 14.198 16.4 16.634 16.4H17.84V18.308Z" fill="var(--rank-gold-text)"/>
    <path d="M140.135 30.692H140.801C141.509 30.692 142.025 30.524 142.349 30.188C142.673 29.864 142.835 29.336 142.835 28.604V27.074C142.835 26.798 142.847 26.54 142.871 26.3C142.907 26.048 142.973 25.814 143.069 25.598C143.165 25.382 143.303 25.178 143.483 24.986C143.663 24.794 143.903 24.626 144.203 24.482C143.903 24.338 143.663 24.176 143.483 23.996C143.303 23.804 143.165 23.6 143.069 23.384C142.973 23.168 142.907 22.94 142.871 22.7C142.847 22.448 142.835 22.19 142.835 21.926V20.396C142.835 19.664 142.673 19.136 142.349 18.812C142.025 18.476 141.509 18.308 140.801 18.308H140.135V16.4H141.341C143.777 16.4 144.995 17.618 144.995 20.054V21.674C144.995 22.322 145.127 22.784 145.391 23.06C145.667 23.336 146.015 23.504 146.435 23.564V25.436C146.015 25.496 145.667 25.664 145.391 25.94C145.127 26.204 144.995 26.66 144.995 27.308V28.946C144.995 31.382 143.777 32.6 141.341 32.6H140.135V30.692Z" fill="var(--rank-gold-text)"/>
    <rect x="1" y="1" width="156" height="46" rx="7" stroke="url(#paint0_linear_4494:7866)" strokeWidth="2"/>
    <defs>
      <linearGradient id="paint0_linear_4494:7866" x1="158" y1="48" x2="-3.78942e-07" y2="1.24735e-06" gradientUnits="userSpaceOnUse">
        <stop stopColor="var(--rank-gold-text)"/>
        <stop offset="1" stopColor="var(--rank-platinum1-stop)"/>
      </linearGradient>
    </defs>
  </svg>;  
}

function PlatinumTwoRank() {
  return <svg width="158" height="48" viewBox="0 0 158 48" fill="none" xmlns="http://www.w3.org/2000/svg" className="rank-badge">
    <rect x="1" y="1" width="156" height="46" rx="7" fill="var(--rank-gold-background)"/>
    <path d="M22.8232 30.3922C22.6575 30.3922 22.4919 30.3409 22.3496 30.2394L18.9992 27.8374L15.6499 30.2394C15.3641 30.4436 14.9815 30.4436 14.698 30.2359C14.4145 30.0306 14.2956 29.6655 14.4005 29.333L15.6499 25.3013L12.3311 22.9623C12.0487 22.7547 11.9321 22.3884 12.0394 22.0548C12.1479 21.7223 12.457 21.496 12.8082 21.4936L16.9192 21.4878L18.2281 17.5565C18.3389 17.224 18.6492 17 19.0003 17C19.3503 17 19.6618 17.224 19.7726 17.5565L21.0593 21.4878L25.1913 21.4936C25.5425 21.496 25.8528 21.7223 25.9601 22.0548C26.0686 22.3884 25.9508 22.7535 25.6685 22.9623L22.3484 25.3013L23.5978 29.333C23.7051 29.6666 23.5838 30.0306 23.3015 30.2359C23.158 30.3409 22.99 30.3922 22.8232 30.3922Z" fill="var(--rank-gold-text)"/>
    <path d="M142.823 30.3922C142.658 30.3922 142.492 30.3409 142.35 30.2394L138.999 27.8374L135.65 30.2394C135.364 30.4436 134.982 30.4436 134.698 30.2359C134.415 30.0306 134.296 29.6655 134.401 29.333L135.65 25.3013L132.331 22.9623C132.049 22.7547 131.932 22.3884 132.039 22.0548C132.148 21.7223 132.457 21.496 132.808 21.4936L136.919 21.4878L138.228 17.5565C138.339 17.224 138.649 17 139 17C139.35 17 139.662 17.224 139.773 17.5565L141.059 21.4878L145.191 21.4936C145.542 21.496 145.853 21.7223 145.96 22.0548C146.069 22.3884 145.951 22.7535 145.668 22.9623L142.348 25.3013L143.598 29.333C143.705 29.6666 143.584 30.0306 143.301 30.2359C143.158 30.3409 142.99 30.3922 142.823 30.3922Z" fill="var(--rank-gold-text)"/>
    <circle cx="79" cy="24" r="14" fill="var(--rank-gold-text)"/>
    <path d="M79 34.5C84.799 34.5 89.5 29.799 89.5 24C89.5 18.201 84.799 13.5 79 13.5C73.201 13.5 68.5 18.201 68.5 24C68.5 29.799 73.201 34.5 79 34.5Z" fill="var(--rank-gold-inline)"/>
    <path d="M82.8232 31C82.6575 31 82.4919 30.9463 82.3496 30.8402L78.9992 28.3293L75.6499 30.8402C75.3641 31.0537 74.9815 31.0537 74.698 30.8366C74.4145 30.622 74.2956 30.2402 74.4005 29.8927L75.6499 25.678L72.3311 23.2329C72.0487 23.0159 71.9321 22.6329 72.0394 22.2841C72.1479 21.9366 72.457 21.7 72.8082 21.6976L76.9192 21.6915L78.2281 17.5817C78.3389 17.2341 78.6492 17 79.0003 17C79.3503 17 79.6618 17.2341 79.7726 17.5817L81.0593 21.6915L85.1913 21.6976C85.5425 21.7 85.8528 21.9366 85.9601 22.2841C86.0686 22.6329 85.9508 23.0146 85.6685 23.2329L82.3484 25.678L83.5978 29.8927C83.7051 30.2415 83.5838 30.622 83.3015 30.8366C83.158 30.9463 82.99 31 82.8232 31Z" fill="var(--rank-gold-text)"/>
    <rect x="1" y="1" width="156" height="46" rx="7" stroke="url(#paint0_linear_4494:7824)" strokeWidth="2"/>
    <defs>
      <linearGradient id="paint0_linear_4494:7824" x1="158" y1="48" x2="-3.78942e-07" y2="1.24735e-06" gradientUnits="userSpaceOnUse">
        <stop stopColor="var(--rank-platinum2-stop)"/>
        <stop offset="1" stopColor="var(--rank-platinum2-start)"/>
      </linearGradient>
    </defs>
  </svg>;  
}

function PlatinumThreeRank() {
  return <svg width="158" height="48" viewBox="0 0 158 48" fill="none" xmlns="http://www.w3.org/2000/svg" className="rank-badge">
    <rect x="1" y="1" width="156" height="46" rx="7" fill="var(--rank-gold-background)"/>
    <path d="M61.6875 15.5278H69.5208C70.9622 15.5278 71.9714 15.392 71.9714 16.8333V14.2222C71.9714 12.7809 70.9622 11.6111 69.5208 11.6111H59.0764C56.4653 11.6111 54.3986 14.3854 55.1597 18.1389C55.1597 18.1389 57.498 30.3784 57.6625 31.1396C58.0972 33.1528 60.4355 35.1111 62.9382 35.1111H72.0223C73.4649 35.1111 71.9714 32.6358 71.9714 31.1944V28.5833C71.9714 30.0246 70.9622 31.1944 69.5208 31.1944H64.2986C62.8573 31.1944 61.9055 29.834 61.6875 28.5833C61.4695 27.3326 59.6195 19.0097 59.6195 19.0097C59.1848 16.5069 60.2449 15.5278 61.6875 15.5278ZM95.3865 15.5278H87.5532C86.1118 15.5278 83.7214 15.392 83.7214 16.8333V14.2222C83.7214 12.7809 86.1118 11.6111 87.5532 11.6111H97.9976C100.609 11.6111 102.677 14.3854 101.914 18.1389C101.914 18.1389 99.7823 30.4359 99.6021 31.1944C99.14 33.1528 96.9362 35.1111 94.3799 35.1111H85.0504C83.6091 35.1111 83.7214 32.6358 83.7214 31.1944V28.5833C83.7214 30.0246 86.1118 31.1944 87.5532 31.1944H92.7754C94.2167 31.1944 95.1698 29.834 95.3865 28.5833C95.6032 27.3326 97.4545 19.0097 97.4545 19.0097C97.888 16.5069 96.8291 15.5278 95.3865 15.5278ZM82.1965 37.7222C82.1965 28.6381 78.6597 37.7222 78.6597 37.7222C78.6597 37.7222 74.7431 28.6381 74.7431 37.7222C74.7431 46.8063 70.4465 50.7778 70.4465 50.7778H86.4944C86.4931 50.7778 82.1965 46.8063 82.1965 37.7222Z" fill="var(--rank-gold-text)" style={{ transform: "scale(1, 0.94)", }} />
    <path d="M93.023 17.5867C93.023 26.4318 84.1844 41.3373 78.6619 41.3373C73.1381 41.3373 64.3008 26.4318 64.3008 17.5867C64.3008 10.8043 65.6063 9.33685 68.2174 9.33685C70.0113 9.33685 76.0834 9.3016 78.6619 9.3016L87.8008 9.30029C91.6091 9.29899 93.023 10.1933 93.023 17.5867Z" fill="var(--rank-gold-cup)"/>
    <path d="M21.8232 30.3922C21.6575 30.3922 21.4919 30.3409 21.3496 30.2394L17.9992 27.8374L14.6499 30.2394C14.3641 30.4436 13.9815 30.4436 13.698 30.2359C13.4145 30.0306 13.2956 29.6655 13.4005 29.333L14.6499 25.3013L11.3311 22.9623C11.0487 22.7547 10.9321 22.3884 11.0394 22.0548C11.1479 21.7223 11.457 21.496 11.8082 21.4936L15.9192 21.4878L17.2281 17.5565C17.3389 17.224 17.6492 17 18.0003 17C18.3503 17 18.6618 17.224 18.7726 17.5565L20.0593 21.4878L24.1913 21.4936C24.5425 21.496 24.8528 21.7223 24.9601 22.0548C25.0686 22.3884 24.9508 22.7535 24.6685 22.9623L21.3484 25.3013L22.5978 29.333C22.7051 29.6666 22.5838 30.0306 22.3015 30.2359C22.158 30.3409 21.99 30.3922 21.8232 30.3922Z" fill="var(--rank-gold-text)"/>
    <path d="M40.8232 30.3922C40.6575 30.3922 40.4919 30.3409 40.3496 30.2394L36.9992 27.8374L33.6499 30.2394C33.3641 30.4436 32.9815 30.4436 32.698 30.2359C32.4145 30.0306 32.2956 29.6655 32.4005 29.333L33.6499 25.3013L30.3311 22.9623C30.0487 22.7547 29.9321 22.3884 30.0394 22.0548C30.1479 21.7223 30.457 21.496 30.8082 21.4936L34.9192 21.4878L36.2281 17.5565C36.3389 17.224 36.6492 17 37.0003 17C37.3503 17 37.6618 17.224 37.7726 17.5565L39.0593 21.4878L43.1913 21.4936C43.5425 21.496 43.8528 21.7223 43.9601 22.0548C44.0686 22.3884 43.9508 22.7535 43.6685 22.9623L40.3484 25.3013L41.5978 29.333C41.7051 29.6666 41.5838 30.0306 41.3015 30.2359C41.158 30.3409 40.99 30.3922 40.8232 30.3922Z" fill="var(--rank-gold-text)"/>
    <path d="M122.823 30.3922C122.658 30.3922 122.492 30.3409 122.35 30.2394L118.999 27.8374L115.65 30.2394C115.364 30.4436 114.982 30.4436 114.698 30.2359C114.415 30.0306 114.296 29.6655 114.401 29.333L115.65 25.3013L112.331 22.9623C112.049 22.7547 111.932 22.3884 112.039 22.0548C112.148 21.7223 112.457 21.496 112.808 21.4936L116.919 21.4878L118.228 17.5565C118.339 17.224 118.649 17 119 17C119.35 17 119.662 17.224 119.773 17.5565L121.059 21.4878L125.191 21.4936C125.542 21.496 125.853 21.7223 125.96 22.0548C126.069 22.3884 125.951 22.7535 125.668 22.9623L122.348 25.3013L123.598 29.333C123.705 29.6666 123.584 30.0306 123.301 30.2359C123.158 30.3409 122.99 30.3922 122.823 30.3922Z" fill="var(--rank-gold-text)"/>
    <path d="M141.823 30.3922C141.658 30.3922 141.492 30.3409 141.35 30.2394L137.999 27.8374L134.65 30.2394C134.364 30.4436 133.982 30.4436 133.698 30.2359C133.415 30.0306 133.296 29.6655 133.401 29.333L134.65 25.3013L131.331 22.9623C131.049 22.7547 130.932 22.3884 131.039 22.0548C131.148 21.7223 131.457 21.496 131.808 21.4936L135.919 21.4878L137.228 17.5565C137.339 17.224 137.649 17 138 17C138.35 17 138.662 17.224 138.773 17.5565L140.059 21.4878L144.191 21.4936C144.542 21.496 144.853 21.7223 144.96 22.0548C145.069 22.3884 144.951 22.7535 144.668 22.9623L141.348 25.3013L142.598 29.333C142.705 29.6666 142.584 30.0306 142.301 30.2359C142.158 30.3409 141.99 30.3922 141.823 30.3922Z" fill="var(--rank-gold-text)"/>
    <rect x="1" y="1" width="156" height="46" rx="7" stroke="url(#paint0_linear_4494:7848)" strokeWidth="2"/>
    <defs>
      <linearGradient id="paint0_linear_4494:7848" x1="158" y1="48" x2="-3.78942e-07" y2="1.24735e-06" gradientUnits="userSpaceOnUse">
        <stop stopColor="var(--rank-platinum3-stop)"/>
        <stop offset="1" stopColor="var(--rank-platinum3-start)"/>
      </linearGradient>
    </defs>
  </svg>;  
}

function TopRank() {
  return <svg width="158" height="48" viewBox="0 0 158 48" fill="none" xmlns="http://www.w3.org/2000/svg" className="rank-badge">
    <rect x="1" y="1" width="156" height="46" rx="7" fill="#FFBB57"/>
    <path d="M81.0725 22.52C83.1125 22.52 84.5325 20.98 84.5325 19.12C84.5325 17.24 83.0725 15.72 81.0725 15.72C79.0725 15.72 77.6125 17.24 77.6125 19.12C77.6125 20.98 79.0725 22.52 81.0725 22.52ZM73.2125 16V23.6L67.8125 16H65.4125V30H68.6125V22.4L74.0125 30H76.4125V16H73.2125ZM81.0725 20.12C80.4925 20.12 80.0925 19.7 80.0925 19.12C80.0925 18.54 80.4925 18.12 81.0725 18.12C81.6525 18.12 82.0525 18.54 82.0525 19.12C82.0525 19.72 81.6725 20.12 81.0725 20.12ZM77.8925 26.32H84.3325V23.52H77.8925V26.32ZM89.5811 16L85.9211 17.32L86.7011 20.06L89.1811 19.38V30H92.3811V16H89.5811Z" fill="white"/>
    <rect x="1" y="1" width="156" height="46" rx="7" stroke="var(--rank-gold-text)" strokeWidth="2"/>
  </svg>;  
}

export { UnknownRank, ExpiredRank, SilverOneRank, SilverTwoRank, SilverThreeRank, GoldOneRank, GoldTwoRank, GoldThreeRank, PlatinumOneRank, PlatinumTwoRank, PlatinumThreeRank, TopRank };