import React from 'react';
import { Link } from 'react-router-dom';
import { LoaderNarrow } from '../../../components/loaders';
import _fetchApi from '../../../fetch';
import GlobalContext from '../../../global';
import { transformTaskCellScroll, getTaskIcon } from '../Tasks';


export class VisibleTaskList extends React.Component {
  static contextType = GlobalContext;

  constructor(props) {
    super(props);

    this.scroller = () => {
      if (this.state.tasks && !this.fetching && this.state.count > this.state.tasks.length) {
        try {
          const element = document.getElementById("task-" + this.state.tasks[this.state.tasks.length - 1].id);
          const rect = element.getBoundingClientRect();
          if (rect.top >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)) {
            this.getMoreTasks();
          }
        } catch (error) {
          if (!(error instanceof TypeError)) {
            throw error;
          }
        }
      }
    };

    this.state = {
      tasks: [],
      count: undefined,
      loading: true,
    };

    this.fetching = false;
    this.used_props = null;
    this.filter_query = null;
  }

  constructSearch(props, add_offset) {
    let result = "";
    if (props.hasOwnProperty("search")) {
      result += "?query=" + props.search;
    } else {
      result += "?categories=" + props.categories_selected.map((element, index) => element ? index + 1 : "").filter(element => element).join(",");
      result += "&difficulties=" + props.difficulty_selected.map((element, index) => element ? index + 1 : "").filter(element => element).join(",");
      result += "&hidesolved=" + (props.hide_solved ? "1" : "0");
      result += "&order=" + props.sort_by;
      result += "&reversed=" + (props.reversed ? "1" : "0");
      if (add_offset) {
        result += "&offset=" + (this.state.tasks.length > 0 ? this.state.tasks[this.state.tasks.length - 1].id : "0");
      }
    }
    return result;
  }

  getMoreTasks(props) {
    let was_scrolled = false;
    if (props) {
      this.setState({
        loading: true,
        tasks: [],
        count: undefined,
      });
    } else {
      props = this.used_props;
      was_scrolled = true;
      this.setState({
        loading: true,
      });
    }

    this.fetching = true;
    this.filter_query = this.constructSearch(props, was_scrolled);

    let response;

    if (props.hasOwnProperty("search")) {
      response = _fetchApi(`/getTaskPreviewsByText${this.filter_query}`, this.context.token ? {
        method: "GET",
        headers: { "Authorization": "Bearer " + this.context.token, },
      } : { method: "GET", }).then(response => response.json());
    } else {
      response = _fetchApi(`/getTaskPreviews${this.filter_query}`, this.context.token ? {
        method: "GET",
        headers: { "Authorization": "Bearer " + this.context.token, },
      } : { method: "GET", }).then(response => response.json());
    }

    response.then(result => {
      this.setState({
        tasks: was_scrolled ? this.state.tasks.concat(result.task_previews) : result.task_previews,
        loading: false,
        count: result.count,
      }, () => {
        this.fetching = false;
        this.used_props = props;
        this.scroller();
      });
    });
  }

  componentDidMount() {
    window.addEventListener("scroll", this.scroller);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scroller);
  }

  componentDidUpdate() {
    if (this.state.tasks.length > 0) {
      const cells = document.getElementsByClassName("tasklist-link-scrollable");
      for (const cell of cells) {
        cell.addEventListener('wheel', transformTaskCellScroll, { passive: false, });
      }
    }
  }

  render() {
    return <div className="task-list-visible">
      {this.state.tasks &&
        <table cellSpacing="0" style={{ borderCollapse: "separate", borderSpacing: "0 11.5px", }}>
          <thead>
            <tr className="tasklist-header">
              <th style={{ width: "78px", }}>ID</th>
              <th style={{ width: "358px", }}>Название</th>
              <th style={{ width: "303px", }}>Источник</th>
              <th style={{ width: "106px", }}>Решили</th>
              <th style={{ width: "102px", }}>Сложность</th>
            </tr>
          </thead>
          <tbody>
            {this.state.tasks.map((element, index) => {
              const bar = <svg width="6" height="20" viewBox="0 0 2 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 0V20" stroke="var(--subtask-stroke)" /></svg>;
              const check = <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className="task-check"><path fillRule="evenodd" clipRule="evenodd" d="M3.70711 9.2929C3.31658 8.90238 2.68342 8.90238 2.29289 9.2929C1.90237 9.68343 1.90237 10.3166 2.29289 10.7071L7.29289 15.7071C7.69802 16.1122 8.36002 16.0948 8.74329 15.669L17.7433 5.66897C18.1128 5.25846 18.0795 4.62617 17.669 4.25671C17.2585 3.88726 16.6262 3.92053 16.2567 4.33104L7.96181 13.5476L3.70711 9.2929Z" fill="var(--submission-green)" /></svg>;

              const params = {};
              for (const [key, value] of new URLSearchParams(this.filter_query).entries()) {
                params[key] = value;
              }

              const dest = {
                pathname: `/tasks/${element.id}`,
                state: {
                  category: "public",
                  ...params,
                },
              };

              return <tr className="tasklist-item" key={index} id={"task-" + element.id}>
                <td><Link to={dest} className="tasklist-link-scrollable" /><div className="tasklist-item-container tasklist-item-scrollable"><p style={{ width: "56px", }}>{element.id}</p></div></td>
                <td><Link to={dest} className="tasklist-link-scrollable" /><div className="tasklist-item-container tasklist-item-scrollable">{bar}{element.solved && check}<p style={{ marginLeft: "13px", width: "311px", color: element.solved ? "var(--submission-green)" : "" }}>{element.name}</p></div></td>
                <td><Link to={dest} className="tasklist-link-scrollable" /><div className="tasklist-item-container tasklist-item-scrollable">{bar}<p style={{ width: "273px", }}>{element.source}</p></div></td>
                <td><Link to={dest} className="tasklist-link-scrollable" /><div className="tasklist-item-container tasklist-item-scrollable">{bar}<p style={{ width: "76px", }}>{element.solved_by}</p></div></td>
                <td><Link to={dest} /><div className="tasklist-item-container">{bar}{getTaskIcon(element.difficulty)}<p style={{ marginLeft: "12px", }}>{element.difficulty}%</p></div></td>
              </tr>;
            })}
          </tbody>
        </table>}
      {this.state.loading && <LoaderNarrow width="60" height="60" className="tasklist-loader" />}
      {this.state.count === 0 && <p className="tasklist-end">Ничего не нашлось.</p>}
      {this.state.count === this.state.tasks.length && !this.state.loading && this.state.count > 100 && <p className="tasklist-end">Всему приходит конец. И списку задач тоже.</p>}
    </div>;
  }
}
