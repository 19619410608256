import React from 'react';
import { useTimer } from 'react-timer-hook';
import PropTypes from 'prop-types';

function addLeadingZero(number) {
  const string_number = number.toString();
  if (string_number.length < 2) {
    return "0" + string_number;
  } else {
    return string_number;
  }
}

const Timer = ({ expiryTimestamp, now = Date.now(), onExpire }) => {
  const { seconds, minutes, hours, days } = useTimer({expiryTimestamp: Date.now() + expiryTimestamp - now, onExpire: onExpire});

  return (
    days ?
    <>{`${days} д. ${addLeadingZero(hours)}:${addLeadingZero(minutes)}:${addLeadingZero(seconds)}`}</> :
    <>{`${hours ? addLeadingZero(hours) + ":" : ""}${addLeadingZero(minutes)}:${addLeadingZero(seconds)}`}</>
  )
}

Timer.propTypes = {
  expiryTimestamp: PropTypes.number.isRequired,
  now: PropTypes.number,
  onExpire: PropTypes.func
};

export default Timer;
