import { RadioGroup, FormControlLabel, FormControl, FormLabel, Radio } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles({
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  icon: {
    boxSizing: "border-box",
    borderRadius: 15,
    width: 24,
    height: 24,
    border: "1px solid var(--code-editor-outline)",
    backgroundColor: "var(--sample-background)",
    transitionProperty: "border, background",
    transitionDuration: "var(--transition-timing)",
    transitionTimingFunction: "var(--transition-function)",
    "input:hover ~ &": {
      backgroundColor: "rgba(0, 0, 0, 0.05)",
      transitionProperty: "border-color, background",
    },
  },
  checkedIcon: {
    backgroundColor: "#FFFFFF",
    border: "9px solid var(--button-color)",
    transitionProperty: "none",
    "input:hover ~ &": {
      borderColor: "#085FDB",
      backgroundColor: "#FFFFFF",
    },
  },
  formLabel: {
    color: "var(--text) !important",
    fontFamily: "PT-Root-UI-Medium",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "20px",
    paddingBottom: "10px",
  },
});

function MyRadio(props) {
  const classes = useStyles();

  return <FormControl className={props.containerClassName}>
    <FormLabel className={classes.formLabel}>{props.formLabel}</FormLabel>
    <RadioGroup value={props.value} onChange={props.onChange}>
      { props.radios.map((element, index) => 
        <FormControlLabel value={element.value} key={index} control={<Radio
          className={classes.root}
          checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
          icon={<span className={classes.icon} />}
          color="primary"
        />} label={element.label} />
      ) }
    </RadioGroup>
  </FormControl>;
}

export default MyRadio;
