import { useEffect } from 'react';
import { Link } from 'react-router-dom';

import { titleEnding } from '../global';

import '../static/css/404NotFound.css';

function NotFound(props) {
  useEffect(() => {
    document.title = "Страница не найдена" + titleEnding;
  });

  return <div className="not-found-container scalable">
    <h1>404</h1>
    <h2>Wrong answe... page</h2>
    <p>Пути совершенствования в спортивном программировании завели вас не туда. Пожалуй, вам стоит вернуться на <Link to="/">главную страницу</Link>.</p>
  </div>;
}

export default NotFound;
