import Button from "@material-ui/core/Button";
import clsx from "clsx";
import React from "react";
import emoji from 'react-easy-emoji'

class ChatBox extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      symbol_overflow: false,
      too_quick: false,
    };

    this.textarea = null;
    this.can_send = true;
    this.send_timeout = null;
  }

  componentDidMount() {
    const element = document.getElementById(this.props.textarea_id);
    this.textarea = element;
    this.textarea.addEventListener("keydown", this.handleKeydown);
    this.textarea.addEventListener("wheel", this.transformScroll);
  }

  componentWillUnmount() {
    this.textarea.removeEventListener("keydown", this.handleKeydown);
    this.textarea.removeEventListener("wheel", this.transformScroll);
  }

  handleKeydown = event => {
    const value = this.textarea.value.trim();

    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();

      if (value) {
        if (this.can_send) {
          this.props.sendMessage(value);
          if (this.state.too_quick) {
            this.setState({too_quick: false,});
          }
          this.textarea.value = "";
          this.can_send = false;
          this.send_timeout = setTimeout(() => this.can_send = true, 10000)
        } else {
          this.setState({too_quick: true,});
        }
      }
    }
  }

  handleTextChange() {
    const value = this.textarea.value.trim();
    if (value.length >= 512) {
      this.setState({symbol_overflow: true,});
    } else if (this.state.symbol_overflow) {
      this.setState({symbol_overflow: false,});
    }

    if (this.state.too_quick) {
      this.setState({too_quick: false,});
    }
  }

  transformScroll = event => {
    this.textarea.scrollTop += event.deltaY;
    event.preventDefault();
  }

  appendEmoji(code) {
    this.textarea.value = this.textarea.value + ":" + code + ":";
    this.textarea.focus();
    this.textarea.classList.toggle("emoji-press");
    this.textarea.setSelectionRange(this.textarea.value.length,this.textarea.value.length);
  }

  stabilizeFocus() {
    this.textarea.classList.toggle("emoji-press");
  }

  render() {
    return <div style={this.props.style} className={clsx("chatbox", this.props.className)}>
      <div style={ {display: "flex", alignItems: "flex-start", justifyContent: "space-between", width: "727px",} }>
        <textarea
          id={this.props.textarea_id}
          className={clsx("chatbox-input", this.state.symbol_overflow || this.state.too_quick ? "error" : "", this.props.textarea_class)}
          placeholder="Начните писать здесь..."
          onChange={() => this.handleTextChange()}
          maxLength={512}
        />
        {this.state.symbol_overflow || this.state.too_quick ? <label className="chatbox-error-label">{
          this.state.too_quick ? "Вы слишком часто пишете сообщения" : "Не более 512 символов"
        }</label> : <></>}
      </div>
      <div className="chatbox-footer">
        <div className="chatbox-button-container">
          <Button classes={ {root: "chatbox-emoji-button",} } onMouseDown={() => this.stabilizeFocus()} onClick={() => this.appendEmoji("thinking")}>{emoji("🤔")}</Button>
          <Button classes={ {root: "chatbox-emoji-button",} } onMouseDown={() => this.stabilizeFocus()} onClick={() => this.appendEmoji("sweating")}>{emoji("😰")}</Button>
          <Button classes={ {root: "chatbox-emoji-button",} } onMouseDown={() => this.stabilizeFocus()} onClick={() => this.appendEmoji("sh*t")}>{emoji("🤬")}</Button>
          <Button classes={ {root: "chatbox-emoji-button",} } onMouseDown={() => this.stabilizeFocus()} onClick={() => this.appendEmoji("clown")}>{emoji("🤡")}</Button>
          <Button classes={ {root: "chatbox-emoji-button",} } onMouseDown={() => this.stabilizeFocus()} onClick={() => this.appendEmoji("praying")}>{emoji("🙏")}</Button>
          <Button classes={ {root: "chatbox-emoji-button",} } onMouseDown={() => this.stabilizeFocus()} onClick={() => this.appendEmoji("coffin")}>{emoji("⚰️")}</Button>
        </div>
        <span>Shift+Enter - новая строка<br />Enter - отправить</span>
      </div>
    </div>;
  }
}

export default ChatBox;
