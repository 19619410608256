import clsx from 'clsx';
import React from 'react';
import { Link } from 'react-router-dom';
import separator_svg from '../../../static/other/separator.svg';

export class TaskSearchList extends React.Component {
  constructor(props) {
    super(props);

    this.search_context = new URLSearchParams(window.location.search);

    this.state = {
      active: props.location.state || this.search_context.get("archive") ? undefined : null,
    };

    this.seasons_selected = null;
  }

  componentDidUpdate() {
    if (this.props.search && this.props.task_data && this.props.task_data !== "bad_data" && this.state.active === undefined) {
      this.setState({ active: this.props.task_data.id, });
    }
    if (this.props.search?.type === "archive" && this.seasons_selected === null) {
      this.seasons_selected = Array(this.props.search.seasons.length).fill(false);
    }
  }

  render() {
    function Separator(props) {
      return <img src={separator_svg} alt="" {...props}></img>;
    }

    if (this.props.search) {
      return <div className="task-list-scroll-wrapper">
        {this.props.search.type === "archive" ? <Link to={"/contest" + (() => {
          let href = new URLSearchParams(this.search_context.toString());
          href.delete("archive");
          return href.toString() ? "?" + href.toString() : "";
        }).call()}>
          ← К соревнованиям
        </Link> : <Link to={{
          pathname: "/tasks",
          state: {
            ...(this.props.location.state || { category: "public", }),
          },
        }}>
          ← К списку задач
        </Link>}
        {(() => {
          if (this.props.search.type === "archive") {
            return (
              <>
                <p style={{ marginBottom: "6px", marginTop: "37px", fontFamily: "PT-Root-UI-Medium", fontSize: "17px", lineHeight: "21px", }}>{this.props.search.name}</p>
                {this.props.search.seasons.map((season, index) => <div
                  key={index}
                  className={"task-archive-dropdown" + (season.tasks.map(element => element.id).includes(this.state.active) || (this.seasons_selected?.[index]) || (Object.prototype.toString.call(this.state.active) === "[object String]" && this.state.active.startsWith("result") && season.source_contest === parseInt(this.state.active.slice(6))) ? (() => { this.seasons_selected[index] = true; return " open"; }).call() : "")}
                  id={"task-archive-dropdown-" + (index + 1)}
                >
                  <Separator />
                  <div className="task-archive-dropdown-title" onClick={() => {
                    const parent = document.getElementById("task-archive-dropdown-" + (index + 1));
                    parent.classList.toggle("open");
                    if (!parent.classList.contains("open")) {
                      setTimeout(() => parent.children[2].style.display = "none", 200);
                    } else {
                      parent.children[2].style.display = "block";
                    }
                    this.seasons_selected[index] = !this.seasons_selected[index];
                  }}>
                    <p>{season.name}</p>
                    <svg width="13" height="5" viewBox="0 0 13 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1 1.00012L6.5 4.00012L12 1.00012" stroke="var(--text)" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </div>
                  <div className="task-archive-dropped" style={{ display: season.tasks.map(element => element.id).includes(this.state.active) || (this.seasons_selected?.[index]) ? "block" : "none", }}>
                    {season.tasks.map((element, index) => {
                      const english_alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

                      if (this.state.active === element.id) {
                        return <ul key={index}><span className="task-link-current">{season.tasks.length <= 26 ? english_alphabet[index] : index}. {element.name}</span></ul>;
                      } else {
                        return <ul key={index}><Link
                          to={"/tasks/" + element.id + window.location.search}
                          onClick={() => {
                            this.props.updateTaskData(element.id);
                            this.setState({ active: element.id });
                          }}><div>{season.tasks.length <= 26 ? english_alphabet[index] : index}. {element.name}</div></Link></ul>;
                      }
                    })}
                    {season.hasOwnProperty("source_contest") && (
                      this.state.active === "result" + season.source_contest ?
                        <ul><span className={clsx(this.state.active === "result" + season.source_contest && "task-link-current")}>Результаты</span></ul> :
                        <ul><Link
                          to={"/tasks/result" + (() => {
                            this.search_context = new URLSearchParams(this.props.location.search);
                            if (this.search_context.get("result")) {
                              this.search_context.set("result", season.source_contest);
                              return "?" + this.search_context.toString();
                            } else {
                              return this.props.location.search + "&result=" + season.source_contest;
                            }
                          }).call()}
                          onClick={() => {
                            this.props.updateTaskData("result" + season.source_contest);
                            this.setState({ active: "result" + season.source_contest });
                          }}>Результаты</Link></ul>
                    )}
                  </div>
                </div>)}
              </>
            );
          } else if (this.props.location.state && this.props.search) {
            return (
              <>
                <p style={{ marginBottom: "16px", marginTop: "54px", fontFamily: "PT-Root-UI-Medium", }}>Результат поиска</p>
                {this.props.search.values.map((element, index) => {
                  const check = <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M1.70711 5.2929C1.31658 4.90238 0.683418 4.90238 0.292893 5.2929C-0.0976311 5.68343 -0.0976311 6.31659 0.292893 6.70712L5.29289 11.7071C5.69802 12.1122 6.36002 12.0948 6.74329 11.669L15.7433 1.66897C16.1128 1.25846 16.0795 0.626173 15.669 0.256714C15.2585 -0.112745 14.6262 -0.0794662 14.2567 0.331044L5.96181 9.5476L1.70711 5.2929Z" /></svg>;

                  if (this.state.active === element.id) {
                    return <ul key={index}><span className="task-link-current">{element.solved ? check : element.id + "."} {element.name}</span></ul>;
                  } else {
                    return <ul key={index}><Link
                      to={{
                        pathname: `/tasks/${element.id}`,
                        state: this.props.location.state,
                      }}
                      onClick={() => {
                        this.props.updateTaskData(element.id);
                        this.setState({ active: element.id, });
                      }}><div>{element.solved ? check : element.id + "."} {element.name}</div></Link></ul>;
                  }
                })}
              </>
            );
          } else {
            return (
              <>
                <p className="task-link-current">{this.props.task_data.id}. {this.props.task_data.name}</p>
                {this.props.search.values.length > 0 ? <>
                  <p style={{ marginBottom: "6px", fontFamily: "PT-Root-UI-Medium", }}>Похожие задачи</p>
                  {this.props.search.values.map((element, index) => <ul key={index}><Link to={"/tasks/" + element.id + window.location.search} onClick={() => {
                    this.props.refreshPage();
                  }}><div>{element.id}. {element.name}</div></Link></ul>)}
                </> : <></>}
              </>
            );
          }
        })()}
      </div>;
    } else {
      return <div className="task-list-scroll-wrapper">
        {this.search_context.get("archive") ? <Link to={"/contest" + (() => {
          let href = new URLSearchParams(this.search_context.toString());
          href.delete("archive");
          return href.toString() ? "?" + href.toString() : "";
        }).call()}>
          ← К соревнованиям
        </Link> : <Link to={{
          pathname: "/tasks",
          state: {
            ...(this.props.location.state || { category: "public", }),
          },
        }}>
          ← К списку задач
        </Link>}
      </div>;
    }
  }
}
