import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Home from './pages/Home';
import { ContestList, ContestWrapper } from './pages/Contest';
import { TaskWrapper, ContestTaskWrapper } from './pages/tasks/Tasks';
import TaskList from './pages/tasks/lists/TaskList';
import TaskEditWrapper from './pages/EasyTaskEdit';
// import Algorithms from './pages/Algorithms';
// import About from './pages/About';
// import News from './pages/News';
// import Articles, { ArticleWrapper } from './pages/Articles';
import ProfileWrapper from './pages/Profile';
import NotFound from './pages/404NotFound';
import PrivacyPolicy from './pages/PrivacyPolicy';

export default function Main(props) {
  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/contest" component={ContestList} />
      <Route exact path={"/contest/:contest_id(\\d+)"} component={ContestWrapper} />
      <Route exact path={"/contest/:contest_id(\\d+)/:id(\\d+|rating|announcements|messages|admin)"} component={ContestTaskWrapper} />
      <Route exact path="/tasks" component={TaskList} />
      <Route exact path={"/tasks/:id(\\d+|result)"} component={TaskWrapper} />
      <Route exact path={"/tasks/:id(\\d+)/easy-edit"} component={TaskEditWrapper} />
      {/* <Route exact path="/algorithms" component={Algorithms} /> */}
      {/* <Route exact path="/about" component={About} /> */}
      <Route exact path="/profile" component={ProfileWrapper} />
      <Route exact path={"/profile/:user_id(\\d+|edit)"} component={ProfileWrapper} />
      {/* <Route exact path="/news" component={News} /> */}
      {/* <Route exact path="/articles" component={Articles} /> */}
      {/* <Route exact path={["/algorithms/:id(\\d+)", "/articles/:id(\\d+)", "/news/:id(\\d+)"]} component={ArticleWrapper} /> */}
      <Route exact path={"/privacy-policy"} component={PrivacyPolicy} />
      <Redirect from="/smolymp2022" to="/contest/11" />
      <Route path="*" component={NotFound} />
    </Switch>
  )
}
