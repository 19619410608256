import { FormControlLabel, Switch } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  formControlRoot: {
    "& span.MuiTypography-root:last-child": {
      fontFamily: "PT-Root-UI-Medium",
      fontWeight: "500",
      fontSize: "16px",
      lineHeight: "20px",
      marginLeft: "6px",
    }
  },
  root: {
    width: 48,
    height: 24,
    padding: 0,
    marginLeft: "11px",
  },
  switchBase: {
    padding: 3,
    "&$checked": {
      transform: "translateX(24px)",
      border: "0px solid var(--code-editor-outline)",
      "& + $track": {
        backgroundColor: "var(--button-color)",
        border: "0px solid var(--code-editor-outline)",
        opacity: 1,
      },
    },
  },
  thumb: {
    boxSizing: "border-box",
    width: 18,
    height: 18,
    backgroundColor: "var(--background)",
    boxShadow: "none",
    border: "1px solid var(--code-editor-outline)",
    transitionProperty: "background-color, border",
    transitionDuration: "var(--transition-timing)",
    transitionTimingFunction: "var(--transition-function)",
  },
  track: {
    boxSizing: "border-box",
    borderRadius: "15px",
    border: "1px solid var(--code-editor-outline)",
    backgroundColor: "var(--sample-background)",
    transitionProperty: "background-color, border",
    transitionDuration: "var(--transition-timing)",
    transitionTimingFunction: "var(--transition-function)",
    opacity: 1,
  },
  checked: {},
  focusVisible: {},
});

function MySwitch(props) {
  const classes = useStyles();

  return <FormControlLabel style={props.style} className={classes.formControlRoot + " " + props.className} control={
    <Switch
      checked={props.checked}
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={ {
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      } }
      onChange={props.onChange}
    />
  } label={props.label}/>;
}

export default MySwitch;
