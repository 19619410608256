import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { YMInitializer } from 'react-yandex-metrika';

import './static/css/index.css';
import App from './App';

const root = document.getElementById("root");

if (root.hasChildNodes()) {
  ReactDOM.hydrate(
    <>
      <YMInitializer accounts={[85711564,]} options={ {webvisor: true, clickmap:true, trackLinks:true, accurateTrackBounce:true,} } version="2" />
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </>, 
  root);
} else {
  ReactDOM.render(
    <>
      <YMInitializer accounts={[85711564,]} options={ {webvisor: true, clickmap:true, trackLinks:true, accurateTrackBounce:true,} } version="2" />
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </>, 
  root);
}
