import avatar from '../static/other/avatar.svg';
import clsx from 'clsx';
import { useRef } from 'react';

function UserAvatar(props) {
  const ref = useRef();

  return <img
    ref={ref}
    onError={() => {ref.current.onError = null; ref.current.src = avatar}}
    src={props.src || (props.user_id ? "https://api.sort-me.org/getUserAvatar?id=" + props.user_id : avatar)}
    alt="avatar"
    id={props.id || ""}
    className={clsx("user-avatar", props.className)}
    width={props.size}
    height={props.size}
    style={{ objectFit: "cover", minWidth: props.size, minHeight: props.size, }}
  />;
}

export default UserAvatar;
