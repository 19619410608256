import Cookies from 'js-cookie';

export default function _fetchApi(url, params) {
  return fetch("https://api.sort-me.org" + url, {
    mode: "cors",
    credentials: "include",
    ...params,
  }).then(response => {
    if (response.status === 401 && Cookies.get("user_data")) {
      Cookies.remove("user_data", { path: "", });
      // window.location.reload();
    }
    return response;
  });
}
