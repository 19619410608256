import React, { Suspense } from 'react';
import { Container, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';

import '../static/css/Home.css';
import goblet_icon from '../static/other/goblet.png';
import magnifier_icon from '../static/other/magnifier.png';
import lamp_icon from '../static/other/lamp.png';

import sort_me_telegram from '../static/other/sort-me-telegram.jpg';
import sort_me_chat_telegram from '../static/other/sort-me-chat-telegram.jpg';

import GlobalContext, { updateSize } from '../global';
import _fetchApi from '../fetch';

import LoginContainer from '../components/login';

const Animation = React.lazy(() => import('../components/3dhomepage'));

class Home extends React.Component {
  static contextType = GlobalContext;

  componentDidMount() {
    document.title = "Sort Me - Лучшее место, чтобы учиться спортивному программированию";

    updateSize();
  }

  getContent() {
    const static_content = <>
      <div id="top-headers-table">
        <Link to="/contest">
          <Container classes={ {root: "container-small"} }>
            <img src={goblet_icon} alt=""></img>
            <h3 style={ {color: "var(--contest-blue)",} }>Соревнования</h3>
            <p>Проверьте свои навыки на архиве реальных олимпиад</p>
          </Container>
        </Link>
        <Link to="/tasks">
          <Container classes={ {root: "container-small"} }>
            <img src={magnifier_icon} alt=""></img>
            <h3 style={ {color: "var(--tasks-red)",} }>Задачи</h3>
            <p>Огромная база задач на любую тему и любой уровень</p>
          </Container>
        </Link>
        <div>
          <Container classes={ {root: "container-small"} }>
            <img style={ {filter: "grayscale(100%)",} } src={lamp_icon} alt=""></img>
            <h3 style={ {color: "var(--news-date)",} }>Алгоритмы</h3>
            <p>Скоро! Подпишитесь на <a href="https://t.me/sort_me" target="blank" style={ {color: "var(--news-date)",} }><ins>телеграм-канал</ins></a> и не пропустите релиз</p>
          </Container>
        </div>
      </div>
      {/* TODO: undo <HomeNewsFeed id="top-news" /> */}
        <div className="home-telegram-proposal">
          <h2>Присоединяйтесь к сообществу Sort Me</h2>
          <a href="https://t.me/sort_me" target="_blank" rel="noreferrer" className="home-telegram-block" style={ {marginLeft: "178px",} }>
            <img alt="telegram" src={sort_me_telegram} />
            <div>
              <h3>Sort Me</h3>
              <span>
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="14" height="14" fill="none" />
                  <path fillRule="evenodd" clipRule="evenodd" d="M1.14114 6.64537C4.51143 5.23178 6.75882 4.29986 7.88331 3.8496C11.094 2.56402 11.7611 2.3407 12.1959 2.33333C12.2916 2.33171 12.5054 2.35453 12.6439 2.46273C12.7609 2.55409 12.7931 2.67751 12.8085 2.76413C12.8239 2.85075 12.843 3.04808 12.8278 3.20227C12.6538 4.96213 11.901 9.23284 11.518 11.2039C11.3559 12.0379 11.0345 12.2165 10.7256 12.2438C10.0542 12.3033 9.5467 11.9178 8.89644 11.5075C7.87891 10.8654 7.5049 10.6924 6.51721 10.0659C5.37577 9.34173 5.99852 8.96271 6.64902 8.31228C6.81926 8.14206 9.79838 5.49041 9.85563 5.25543C9.86279 5.22605 9.76479 4.93222 9.69719 4.87438C9.62959 4.81654 9.52982 4.83632 9.45782 4.85205C9.35577 4.87435 7.73026 5.90866 4.58129 7.95497C4.11989 8.25998 3.70197 8.40859 3.32753 8.4008C2.91474 8.39221 2.1207 8.17611 1.53041 7.99139C0.806392 7.76483 0.539133 7.65813 0.589241 7.27335C0.615339 7.07293 0.799306 6.8636 1.14114 6.64537Z" />
                </svg>
                t.me/sort_me
              </span>
            </div>
          </a>
          <a href="https://t.me/sort_me_chat" target="_blank" rel="noreferrer" className="home-telegram-block" style={ {marginLeft: "138px",} }>
          <img alt="chat telegram" src={sort_me_chat_telegram} />
          <div>
            <h3>Sort Me Chat</h3>
            <span>
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="14" height="14" fill="none" />
                <path fillRule="evenodd" clipRule="evenodd" d="M1.14114 6.64537C4.51143 5.23178 6.75882 4.29986 7.88331 3.8496C11.094 2.56402 11.7611 2.3407 12.1959 2.33333C12.2916 2.33171 12.5054 2.35453 12.6439 2.46273C12.7609 2.55409 12.7931 2.67751 12.8085 2.76413C12.8239 2.85075 12.843 3.04808 12.8278 3.20227C12.6538 4.96213 11.901 9.23284 11.518 11.2039C11.3559 12.0379 11.0345 12.2165 10.7256 12.2438C10.0542 12.3033 9.5467 11.9178 8.89644 11.5075C7.87891 10.8654 7.5049 10.6924 6.51721 10.0659C5.37577 9.34173 5.99852 8.96271 6.64902 8.31228C6.81926 8.14206 9.79838 5.49041 9.85563 5.25543C9.86279 5.22605 9.76479 4.93222 9.69719 4.87438C9.62959 4.81654 9.52982 4.83632 9.45782 4.85205C9.35577 4.87435 7.73026 5.90866 4.58129 7.95497C4.11989 8.25998 3.70197 8.40859 3.32753 8.4008C2.91474 8.39221 2.1207 8.17611 1.53041 7.99139C0.806392 7.76483 0.539133 7.65813 0.589241 7.27335C0.615339 7.07293 0.799306 6.8636 1.14114 6.64537Z" />
              </svg>
              t.me/sort_me_chat
            </span>
          </div>
        </a>
      </div>
    </>;
    
    if (this.context.login === null) {
      const titles = ["Лучшее место, чтобы учиться спортивному программированию", "Новый способ учиться спортивному программированию",];

      return <>
        <Suspense fallback={<></>}>
          <Animation />
        </Suspense>

        <h1 className="header">{titles[Math.round(Math.random())]}</h1>
        <p style={ {width: 50 + "%"} }>Sort Me - быстрая, доступная и стабильная платформа для олимпиадного программирования.</p>
        <p style={ {width: 50 + "%"} }>Повторяйте и изучайте алгоритмы, тренируйтесь на огромной базе задач и соревнуйтесь с другими пользователями!</p>
        <LoginContainer id="top-login" title={"Войти или зарегистрироваться"} />
        {static_content}
      </>;
    } else if (this.context.login === undefined) {
      return <></>;
    } else {
      return <>
        <h1 className="header">Привет, {this.context.login}!</h1>
        {static_content}
      </>;
    }
  }

  render() {
    return (
      <div className="main-content scalable">
        <div className="top-text">
          {this.getContent()}
        </div>
      </div>
    );
  }
}

class HomeNewsFeed extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      news_feed: ["more-button", "end-div"],
    }
  }

  componentDidMount() {
    const element = document.getElementsByClassName("horizontal-scroll-container")[0];
    element.addEventListener('wheel', transformScroll, {passive: false});

    let response = _fetchApi("/getLastNews",  this.context.token ? {
      method: "GET",
      headers: {"Authorization": "Bearer " + this.context.token,},
    } : {method: "GET",}).then(response => response.json());
    response.then(result => this.setState({
      news_feed: result.concat(this.state.news_feed),
    }));
  }

  componentDidUpdate() {
    updateSize();
  }
  
  render() {
    return (
      <div id={this.props.id}>
        <h2 className="header">Новости</h2>
        <div className="horizontal-scroll-container">
          {this.state.news_feed.map((element, index) => {
            if (element === "more-button") {
              return <Link key={index} to="/news" className="news-more-button"><Button>Ещё</Button></Link>
            } else if (element === "end-div") {
              return <div key={index} style={ {minWidth: 4 + "%",} }></div>
            } else {
              return <Container key={index} classes={ {root: "container-news",} }>
                <img src={"https://api.sort-me.org" + element.picture} alt=""></img>
                <div style={ {marginLeft: 24 + "px", position: "relative",} }>
                  <h4 className="header-light" style= { {margin: 0 + "px",} }>{element.title}</h4>
                  <p className="news-preview-text">{element.preview_text}</p>
                  <span className="news-date">{element.date}</span>
                </div>
              </Container>
            }
          })}
        </div>
      <div className="news-fade-block"></div>
      </div>
    );
  }
}

function transformScroll(event) {
  event.currentTarget.scrollLeft += event.deltaY + event.deltaX;
  event.preventDefault();
}

export default Home;
