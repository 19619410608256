import { useCallback, useState, useMemo } from "react"
import styled from "styled-components"
import axios from "axios"

import { ReactComponent as ArchiveIcon } from "../../static/other/archive.svg"
import { ReactComponent as DownloadIcon } from "../../static/other/download.svg"
import { ReactComponent as FolderIcon } from "../../static/other/folder.svg"

const descriptionText = {
  "idle": "Щёлкните или перетащите ZIP-архив с ответами",
  "hover": "Отпускайте!",
  "loading": "Загрузка...",
  "error": "Не удалось прочитать файл",
}

export default function ArchiveUploaderContent({ state, loadedInfo, taskId, contestId }) {
  const [downloading, setDownloading] = useState(false)

  const downloadTests = useCallback(async event => {
    event.stopPropagation()
    setDownloading(true)
    
    const apiResponse = await axios({
      url: "/exportTests",
      baseURL: "https://api.sort-me.org",
      method: "get",
      params: {
        "task_id": taskId,
        "contest_id": contestId,
        "only_input": true,
      },
      responseType: "blob",
    });

    const filename = JSON.parse(apiResponse.headers["content-disposition"].split("filename=")[1])
    const fileDownload = require("js-file-download")
    await fileDownload(apiResponse.data, filename)

    setDownloading(false)
  }, [contestId, taskId])

  const sizeString = useMemo(() => {
    if (loadedInfo.size !== undefined) {
      if (loadedInfo.size / 1024 ** 2 < 1)
        return `${Math.round(loadedInfo.size / 1024)} КБ`
      return `${Math.round(loadedInfo.size / 1024 ** 2)} МБ`
    }
  }, [loadedInfo])

  if (loadedInfo.filename) { // file is loaded
    const filesWord =
      loadedInfo.count >= 11 && loadedInfo.count <= 19 ? "файлов"
        : loadedInfo.count % 10 === 1 ? "файл"
        : loadedInfo.count % 10 >= 2 && loadedInfo.count % 10 <= 4 ? "файла"
        : "файлов"

    if (loadedInfo.errorDetails) { // file is loaded and has errors
      return (
        <>
          <FileErrorDescription>
            <FolderIcon />
            <h4>{loadedInfo.filename}</h4>
            <span>{sizeString}, {loadedInfo.count} {filesWord}</span>
          </FileErrorDescription>

          <ErrorText>{loadedInfo.errorDetails}</ErrorText>
        </>
      )
    }

    return (
      <FileDescription>
        <FolderIcon />
        <h4>{loadedInfo.filename}</h4>
        <span>{sizeString}, {loadedInfo.count} {filesWord}</span>
      </FileDescription>
    )
  }

  return (
    <>
      <CustomArchiveIcon />
      <Description>{descriptionText[state]}</Description>
      <DownloadButton onClick={downloadTests}>
        <DownloadIcon />
        {downloading ? "Загрузка..." : "Скачать тесты"}
      </DownloadButton>
    </>
  )
}

const CustomArchiveIcon = styled(ArchiveIcon)`
  margin-top: 45px;
`

const Description = styled.span`
  font-family: PT-Root-UI-Medium, Tahoma, Sans-Serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #888888;

  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  width: 202px;
  height: 38px;

  margin-top: 12px;
`

const DownloadButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;

  display: flex;
  padding: 6px 8px;
  align-items: center;
  gap: 8px;
  margin-top: 28px;

  font-family: PT-Root-UI-Medium, Tahoma, Sans-Serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.01em;
  color: var(--button-color);
  transition: color var(--transition-timing) var(--transition-function);

  > svg path {
    fill: var(--button-color);
    transition: fill var(--transition-timing) var(--transition-function);
  }

  &:hover {
    > svg path {
      fill: #085FDB;
    }

    color: #085FDB;
  }
`

const FileDescription = styled.div`
  display: grid;
  grid-template-areas:
    "icon name"
    ". size";
  column-gap: 14px;
  margin: auto auto;

  color: #888888;

  > svg {
    grid-area: icon;
  }

  > h4 {
    margin: 0;

    grid-area: name;
    font-family: PT-Root-UI-Medium, Tahoma, Sans-Serif;
    font-weight: 700;
    font-size: 16px;
  }

  > span {
    grid-area: size;
    font-family: PT-Root-UI-Medium, Tahoma, Sans-Serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
  }
`

const FileErrorDescription = styled(FileDescription)`
  margin-top: 28px;
`

const ErrorText = styled.span`
  width: 388px;
  margin-bottom: 48px;

  font-family: PT-Root-UI-Medium, Tahoma, Sans-Serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 0.01em;
  color: var(--submission-red);
  transition: color var(--transition-timing) var(--transition-function);
`
