import { FormControlLabel, Checkbox, FormControl, FormLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useCheckboxStyles = makeStyles({
  root: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  formControlRoot: {
    marginRight: 0,
    "& span.MuiTypography-root:last-child": {
      fontFamily: "PT-Root-UI-Medium",
      fontWeight: "500",
      fontSize: "16px",
      lineHeight: "20px",
    }
  },
  icon: {
    borderRadius: 5,
    width: 24,
    height: 24,
    border: "1px solid var(--code-editor-outline)",
    backgroundColor: "var(--sample-background)",
    transitionProperty: "border, background-color",
    transitionDuration: "var(--transition-timing)",
    transitionTimingFunction: "var(--transition-function)",
    "input:hover ~ &": {
      backgroundColor: "rgba(0, 0, 0, 0.05)",
    },
  },
  checkedIcon: {
    backgroundColor: "var(--button-color)",
    border: "1px solid rgba(0, 0, 0, 0)",
    "&:before": {
      display: "block",
      width: 24,
      height: 24,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-3 -5 22 22' fill='none' %3E%3Cpath" +
        " d='M1 5.99999L5.81818 11L15.3182 1.5' stroke='white' stroke-width='2.2' stroke-linecap='round' stroke-linejoin='round' /%3E%3C/svg%3E\")",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "#085FDB",
    },
  },
  formLabel: {
    color: "var(--text) !important",
    fontFamily: "PT-Root-UI-Medium",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "20px",
    paddingBottom: "10px",
  },
});

function MyCheckBox(props) {
  const classes = useCheckboxStyles();
  
  return (<FormControlLabel className={classes.formControlRoot + " " + (props.className || "")} control={<Checkbox
    className={classes.root}
    checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
    icon={<span className={classes.icon} />}
    color="primary"
    checked={props.checked}
    onChange={props.onChange}
  />} label={props.label} />);
}

function CheckBoxGroup(props) {
  const classes = useCheckboxStyles();

  return <FormControl className={props.containerClassName}>
    <FormLabel className={classes.formLabel}>{props.formLabel}</FormLabel>
    { props.checkboxes.map((element, index) => {
      return <MyCheckBox {...element} key={index} />
    }) }
  </FormControl>;
}

export default MyCheckBox;
export { CheckBoxGroup, };
