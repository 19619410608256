import { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import Markdown from "./markdown";
import clsx from "clsx";
import TextareaAutosize from "react-textarea-autosize";

export default function MarkdownInput({
  className,
  value,
  setValue,
  placeholder,
  placeholderClassName,
  inputClassName,
  markdownClassName
}) {
  const [focused, setFocused] = useState(false);

  const inputRef = useRef();

  const handleClick = () => {
    setFocused(true);
  };
  const handleBlur = () => {
    setFocused(false);
  };

  useEffect(() => {
    const input = inputRef.current;
    if (focused) {
      input?.setSelectionRange(input?.value.length, input?.value.length);
      input?.focus();
    }
  }, [ focused, ]);

  return <div
    className={clsx(className)}
    onClick={handleClick}
    onBlur={handleBlur}
  >
    {(() => {
      if (!focused && !value) {
        return <span className={clsx("markdown-input__placeholder", placeholderClassName)}>{placeholder}</span>
      } else if (focused) {
        return <TextareaAutosize
          spellCheck={false}
          className={clsx("markdown-input__input", "auto-resize", inputClassName)}
          ref={inputRef}
          placeholder={placeholder}
          value={value}
          onChange={event => setValue(event.target.value)}
        />;
      } else {
        return <Markdown className={clsx("markdown-input__markdown", markdownClassName)}>{value}</Markdown>;
      }
    })()}
  </div>;
}

MarkdownInput.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  placeholderClassName: PropTypes.string,
  inputClassName: PropTypes.string,
  markdownClassName: PropTypes.string,
}
