import React, { useState } from 'react';
import { Select, MenuItem, FormControl } from '@material-ui/core';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { ExpandMore } from '@material-ui/icons';

const useSelectStyles = makeStyles({
  formControl: {
    width: 232,
    border: "none",
    outline: "none",
    "&:hover": {
      borderRadius: "5px",
    }
  },
  selectRoot: {
    fontFamily: "PT-Root-UI-Medium",
    fontSize: "15px",
    fontWeight: "400",
    letterSpacing: "0.01em",
    textAlign: "left",
    padding: "7.5px 20px 7.5px 12px",
    background: "var(--sample-background)",
    border: "1px solid var(--code-editor-outline)",
    borderRadius: "5px",
    minHeight: "20px",
    outline: "none",
    color: "var(--text)",
    transitionProperty: "border, background, color",
    transitionDuration: "var(--transition-timing)",
    transitionTimingFunction: "var(--transition-function)",
    "&:focus": {
      background: "var(--sample-background)",
      border: "1px solid var(--code-editor-outline)",
      borderRadius: "5px",
      minHeight: "20px",
      outline: "none",
    }
  },
});

function SelectList(props) {
  const classes = useSelectStyles();
  const [index, setIndex] = useState(props.index || 0);

  if (props.index !== index) {
    setIndex(props.index);
  }

  function handleChange(event) {
    setIndex(event.target.value);
    props.onChange(props.options[event.target.value], event.target.value);
  }

  return <FormControl variant="outlined" className={clsx(classes.formControl, props.className)} id={clsx(props.id)}>
    <Select
      inputProps={{ "aria-label": "Without label", }}
      value={index}
      onChange={handleChange}
      IconComponent={ExpandMore}
      classes={{ root: classes.selectRoot, }}
    >
      {(props.options.length === 0 ? ["⠀"] : props.options).map((element, index) => 
        <MenuItem value={index} key={index}>{element}</MenuItem>
      )}
    </Select>
  </FormControl>;
}

export default SelectList;
