import Lottie from 'react-lottie';

import '../static/css/Components.css';

import * as animationData from '../static/other/cubes.json';

function Loader(props) {
  return <div className="loader-container">
    <Lottie options={ {
        loop: true,
        autoplay: true, 
        animationData: animationData.default,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        },
      } }
      height={200}
      width={200}
    />
    <p>Загрузка...</p>
  </div>;
}

function LoaderSmall(props) {
  return <div className="loader-container loader-small">
    <Lottie options={ {
        loop: true,
        autoplay: true, 
        animationData: animationData.default,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        },
      } }
      height={props.height * 3}
      width={props.width * 3}
    />
    <p style={ {fontSize: props.height - 6, lineHeight: "none",} }>Загрузка...</p>
  </div>;
}

function LoaderNarrow(props) {
  return <div className={"loader-container " + (props.className || "")}>
    <Lottie options={ {
        loop: true,
        autoplay: true, 
        animationData: animationData.default,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        },
      } }
      height={props.height * 3}
      width={props.width * 3}
    />
  </div>;
}

export default Loader;
export { LoaderSmall, LoaderNarrow, };
