import clsx from 'clsx';
import React from 'react';
import GlobalContext, { titleEnding, updateSize } from '../../../global';
import { VisibleTaskList } from "./VisibleTaskList";
import { PrivateTaskList } from "./PrivateTaskList";
import { ListFilter } from "./ListFilter";

export default class TaskList extends React.Component {
  static contextType = GlobalContext;

  constructor(props) {
    super(props);

    this.filter_ref = React.createRef();
    this.list_ref = React.createRef();

    this.state = {
      category: props.location.state?.category === "private" ? "private" : "public",
    };
  }

  componentDidMount() {
    document.title = "Список задач" + titleEnding;

    window.scrollTo(0, 0);
    updateSize();
  }

  updateTasks(props) {
    this.list_ref.current?.getMoreTasks(props);
  }

  render() {
    return (
      <div className="main-content scalable">
        <div className="top-text">
          <div className="task-title--flex">
            <h1 className="header">Задачи</h1>
            {this.context.token && <div className="task-title__switch">
              <button
                className={clsx("task-category-button", this.state.category === "public" && "selected")}
                onClick={() => this.setState({ category: "public", })}
              >
                Публичные
              </button>
              <button
                className={clsx("task-category-button", this.state.category === "private" && "selected")}
                onClick={() => this.setState({ category: "private", })}
              >
                Мои
              </button>
            </div>}
          </div>
          <p style={{ marginBottom: "32px", }}>{this.state.category === "private" ? "Это задачи, которые вы загрузили на Sort Me." : "Это огромная база задач по всем существующим темам."}</p>
          <div className="task-list-container">
            <ListFilter category={this.state.category} ref={this.filter_ref} updateTasks={props => this.updateTasks(props)} {...this.props} />
            {this.state.category === "private" ?
              <PrivateTaskList ref={this.list_ref} {...this.props} /> :
              <VisibleTaskList ref={this.list_ref} />}
          </div>
        </div>
      </div>
    );
  }
}
