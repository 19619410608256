import { useContext, useState } from 'react';
import clsx from 'clsx';
import Cookies from 'js-cookie';
import { useGoogleLogin } from 'react-google-login';
import Button from "@material-ui/core/Button";

import GlobalContext, { parseToken } from '../global.js';
import _fetchApi from '../fetch.js';

import MainButton from './buttons.jsx';

async function loginIntoTelegram(setLogining) {
  let response = new Promise((resolve, reject) => {
    setLogining?.(true);
    window.Telegram.Login.auth(
      {
        bot_id: 1756957630,
        request_access: true,
      },
      data => resolve(data)
    );
  });

  await response.then(async result => {
    if (result) {
      const auth_response = await _fetchApi("/oauth?provider=telegram", {
        method: "POST",
        headers: {"Content-Type": "application/json",},
        body: JSON.stringify(result),
      }).then(response => response.json());

      if (!auth_response.hasOwnProperty("error")) {
        const parsed_token = parseToken(auth_response.token);
        Cookies.set("user_data", {
          token: auth_response.token,
          login: auth_response.user.login,
          uid: auth_response.user.uid,
        }, {expires: new Date(parsed_token.exp * 1000), path: "/",});

        window.location.reload();
      } else {
        console.log(auth_response);
      }
    }
  });
  setLogining?.(false);
  return response;
}

async function connectTelegram(props) {
  let response = new Promise((resolve, reject) => {
    window.Telegram.Login.auth(
      {
        bot_id: 1756957630,
        request_access: true,
      },
      data => resolve(data)
    );
  });

  await response.then(async result => {
    if (result) {
      const auth_response = await _fetchApi("/oauth/connect?provider=telegram", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + props.token,
        },
        body: JSON.stringify(result),
      }).then(response => response.json());

      if (!auth_response.hasOwnProperty("error")) {
        props.onSuccess?.(auth_response);
      } else {
        props.onFailure?.(auth_response);
      }
    } else {
      props.onFailure?.("Telegram api error");
    }
  });

  return response;
}

function GoogleLoginButton(props) {
  const { signIn } = useGoogleLogin({
    clientId: "867876921972-dn8tnsvq7d7ggh49rre89sjrso4oiqiu.apps.googleusercontent.com",
    onSuccess: handleGoogleResponse,
    onFailure: handleGoogleResponse,
  });
  const [is_loading, setLoading] = useState(false);
  const [children, setChildren] = useState(props.children);

  async function handleGoogleResponse(response) {
    if (response.hasOwnProperty("error")) {
      console.log(response);
      setChildren(props.error);
    } else {
      let auth_response = await _fetchApi("/oauth?provider=google", {
        method: "POST",
        headers: {"Content-Type": "application/json",},
        body: JSON.stringify({token: response.tokenId,}),
      }).then(response => response.json());

      if (!auth_response.hasOwnProperty("error")) {
        const parsed_token = parseToken(auth_response.token);
        Cookies.set("user_data", {
          token: auth_response.token,
          login: auth_response.user.login,
          uid: auth_response.user.uid,
        }, {expires: new Date(parsed_token.exp * 1000), path: "/",});

        window.location.reload();
      }
    }
    
    props.setLogining?.(false);
    setLoading(false);
  }

  return <Button
    classes={ {disabled: "submit-disabled",} }
    className={clsx("submit-button", "google-auth-button", props.className)}
    onClick={async () => {
      props.setLogining?.(true);
      setLoading(true);

      signIn();
    }}
    id={clsx(props.id)}
    disabled={is_loading || props.disabled}
  >{is_loading ? props.loading || "Входим..." : children}</Button>;
}

function ConnectGoogleButton(props) {
  const { signIn } = useGoogleLogin({
    clientId: "867876921972-dn8tnsvq7d7ggh49rre89sjrso4oiqiu.apps.googleusercontent.com",
    onSuccess: handleGoogleResponse,
    onFailure: handleGoogleResponse,
  });
  const [is_loading, setLoading] = useState(false);
  const [children, setChildren] = useState(props.children);

  async function handleGoogleResponse(response) {
    if (response.hasOwnProperty("error")) {
      setChildren("Ошибка");
    } else {
      let auth_response = await _fetchApi("/oauth/connect?provider=google", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + props.token,
        },
        body: JSON.stringify({token: response.tokenId,}),
      }).then(response => response.json());

      if (auth_response.hasOwnProperty("error")) {
        setChildren("Аккаунт уже привязан");
      } else {
        setChildren(props.children);
        props.onSuccess?.();
      }
    }
    
    setLoading(false);
  }

  return <button
    disabled={is_loading}
    className={clsx(props.className)}
    onClick={() => {
      setLoading(true);
      signIn();
    }}
  >{is_loading ? "Загрузка..." : children}</button>
}

function LoginContainer(props) {
  const [logining, setLogining] = useState(false);

  return <div
    id={clsx(props.id)}
    className={clsx("login-container", props.className, logining && "disabled")}
  >
    <h2>{props.title || ""}</h2>
    <MainButton 
      className="telegram-auth-button"
      error={<>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M1.95617 11.3922C7.73382 8.96891 11.5865 7.37133 13.5142 6.59946C19.0181 4.39561 20.1618 4.01278 20.9072 4.00013C21.0712 3.99735 21.4378 4.03647 21.6752 4.22196C21.8757 4.37858 21.9309 4.59016 21.9573 4.73865C21.9837 4.88715 22.0166 5.22542 21.9904 5.48974C21.6922 8.50665 20.4016 15.8279 19.745 19.2069C19.4672 20.6366 18.9162 20.9426 18.3866 20.9896C17.2357 21.0915 16.3657 20.4307 15.251 19.7273C13.5066 18.6265 12.8655 18.33 11.1723 17.2559C9.21554 16.0145 10.2831 15.3648 11.3983 14.2498C11.6901 13.958 16.7972 9.41227 16.8953 9.00946C16.9076 8.95908 16.7396 8.45538 16.6237 8.35622C16.5078 8.25706 16.3368 8.29097 16.2133 8.31794C16.0384 8.35616 13.2518 10.1293 7.85356 13.6372C7.0626 14.1601 6.34617 14.4149 5.70427 14.4015C4.99663 14.3868 3.63541 14.0163 2.62348 13.6997C1.38232 13.3113 0.924159 13.1284 1.01006 12.4687C1.0548 12.1252 1.37017 11.7663 1.95617 11.3922Z" fill="white" />
        </svg>
        Ошибка
      </>}
      loading={<>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M1.95617 11.3922C7.73382 8.96891 11.5865 7.37133 13.5142 6.59946C19.0181 4.39561 20.1618 4.01278 20.9072 4.00013C21.0712 3.99735 21.4378 4.03647 21.6752 4.22196C21.8757 4.37858 21.9309 4.59016 21.9573 4.73865C21.9837 4.88715 22.0166 5.22542 21.9904 5.48974C21.6922 8.50665 20.4016 15.8279 19.745 19.2069C19.4672 20.6366 18.9162 20.9426 18.3866 20.9896C17.2357 21.0915 16.3657 20.4307 15.251 19.7273C13.5066 18.6265 12.8655 18.33 11.1723 17.2559C9.21554 16.0145 10.2831 15.3648 11.3983 14.2498C11.6901 13.958 16.7972 9.41227 16.8953 9.00946C16.9076 8.95908 16.7396 8.45538 16.6237 8.35622C16.5078 8.25706 16.3368 8.29097 16.2133 8.31794C16.0384 8.35616 13.2518 10.1293 7.85356 13.6372C7.0626 14.1601 6.34617 14.4149 5.70427 14.4015C4.99663 14.3868 3.63541 14.0163 2.62348 13.6997C1.38232 13.3113 0.924159 13.1284 1.01006 12.4687C1.0548 12.1252 1.37017 11.7663 1.95617 11.3922Z" fill="white" />
        </svg>
        Входим...
      </>}
      onClick={() => loginIntoTelegram(setLogining)}
    >
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M1.95617 11.3922C7.73382 8.96891 11.5865 7.37133 13.5142 6.59946C19.0181 4.39561 20.1618 4.01278 20.9072 4.00013C21.0712 3.99735 21.4378 4.03647 21.6752 4.22196C21.8757 4.37858 21.9309 4.59016 21.9573 4.73865C21.9837 4.88715 22.0166 5.22542 21.9904 5.48974C21.6922 8.50665 20.4016 15.8279 19.745 19.2069C19.4672 20.6366 18.9162 20.9426 18.3866 20.9896C17.2357 21.0915 16.3657 20.4307 15.251 19.7273C13.5066 18.6265 12.8655 18.33 11.1723 17.2559C9.21554 16.0145 10.2831 15.3648 11.3983 14.2498C11.6901 13.958 16.7972 9.41227 16.8953 9.00946C16.9076 8.95908 16.7396 8.45538 16.6237 8.35622C16.5078 8.25706 16.3368 8.29097 16.2133 8.31794C16.0384 8.35616 13.2518 10.1293 7.85356 13.6372C7.0626 14.1601 6.34617 14.4149 5.70427 14.4015C4.99663 14.3868 3.63541 14.0163 2.62348 13.6997C1.38232 13.3113 0.924159 13.1284 1.01006 12.4687C1.0548 12.1252 1.37017 11.7663 1.95617 11.3922Z" fill="white" />
      </svg>
      Войти через Telegram
    </MainButton>

    <GoogleLoginButton
      setLogining={setLogining}
      error={<>
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M17.64 9.2C17.64 8.563 17.583 7.949 17.476 7.36H9V10.841H13.844C13.635 11.966 13.001 12.919 12.048 13.558V15.816H14.956C16.658 14.249 17.64 11.942 17.64 9.201V9.2Z" fill="#4285F4"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M9.00296 18C11.433 18 13.47 17.194 14.959 15.82L12.05 13.56C11.244 14.1 10.214 14.42 9.00296 14.42C6.65896 14.42 4.67496 12.836 3.96696 10.709H0.959961V13.041C2.43996 15.983 5.48496 18 9.00296 18Z" fill="#34A853"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M3.964 10.712C3.784 10.172 3.682 9.59501 3.682 9.00201C3.682 8.40901 3.784 7.83201 3.964 7.29201V4.96001H0.957C0.347 6.17501 0 7.55001 0 9.00201C0 10.454 0.348 11.829 0.957 13.044L3.964 10.712Z" fill="#FBBC05"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M9.00296 3.58C10.324 3.58 11.511 4.034 12.443 4.925L15.025 2.345C13.464 0.891 11.428 0 9.00196 0C5.48496 0 2.43996 2.017 0.959961 4.958L3.96696 7.29C4.67496 5.163 6.65896 3.58 9.00296 3.58Z" fill="#EA4335"/>
        </svg>
        Ошибка
      </>}
      loading={<>
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M17.64 9.2C17.64 8.563 17.583 7.949 17.476 7.36H9V10.841H13.844C13.635 11.966 13.001 12.919 12.048 13.558V15.816H14.956C16.658 14.249 17.64 11.942 17.64 9.201V9.2Z" fill="#4285F4"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M9.00296 18C11.433 18 13.47 17.194 14.959 15.82L12.05 13.56C11.244 14.1 10.214 14.42 9.00296 14.42C6.65896 14.42 4.67496 12.836 3.96696 10.709H0.959961V13.041C2.43996 15.983 5.48496 18 9.00296 18Z" fill="#34A853"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M3.964 10.712C3.784 10.172 3.682 9.59501 3.682 9.00201C3.682 8.40901 3.784 7.83201 3.964 7.29201V4.96001H0.957C0.347 6.17501 0 7.55001 0 9.00201C0 10.454 0.348 11.829 0.957 13.044L3.964 10.712Z" fill="#FBBC05"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M9.00296 3.58C10.324 3.58 11.511 4.034 12.443 4.925L15.025 2.345C13.464 0.891 11.428 0 9.00196 0C5.48496 0 2.43996 2.017 0.959961 4.958L3.96696 7.29C4.67496 5.163 6.65896 3.58 9.00296 3.58Z" fill="#EA4335"/>
        </svg>
        Входим...
      </>}
    >
      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M17.64 9.2C17.64 8.563 17.583 7.949 17.476 7.36H9V10.841H13.844C13.635 11.966 13.001 12.919 12.048 13.558V15.816H14.956C16.658 14.249 17.64 11.942 17.64 9.201V9.2Z" fill="#4285F4"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M9.00296 18C11.433 18 13.47 17.194 14.959 15.82L12.05 13.56C11.244 14.1 10.214 14.42 9.00296 14.42C6.65896 14.42 4.67496 12.836 3.96696 10.709H0.959961V13.041C2.43996 15.983 5.48496 18 9.00296 18Z" fill="#34A853"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M3.964 10.712C3.784 10.172 3.682 9.59501 3.682 9.00201C3.682 8.40901 3.784 7.83201 3.964 7.29201V4.96001H0.957C0.347 6.17501 0 7.55001 0 9.00201C0 10.454 0.348 11.829 0.957 13.044L3.964 10.712Z" fill="#FBBC05"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M9.00296 3.58C10.324 3.58 11.511 4.034 12.443 4.925L15.025 2.345C13.464 0.891 11.428 0 9.00196 0C5.48496 0 2.43996 2.017 0.959961 4.958L3.96696 7.29C4.67496 5.163 6.65896 3.58 9.00296 3.58Z" fill="#EA4335"/>
      </svg>
      Войти через Google
  </GoogleLoginButton>
  </div>;
}

function DisconnectButton(props) {
  const [is_loading, setLoading] = useState(false);
  const [children, setChildren] = useState(props.children);
  const context = useContext(GlobalContext);

  return <button
    disabled={is_loading}
    className={clsx(props.className)}
    onClick={async () => {
      setLoading(true);

      let response = await _fetchApi("/oauth/disconnect?provider=" + props.provider, {
        method: "POST",
        headers: {"Authorization": "Bearer " + context.token,},
      }).then(response => response.json());

      if (response.hasOwnProperty("error")) {
        setChildren("Ошибка")
        setLoading(false);
      } else {
        setLoading(false);
        props.onClick();
      }
    }}
  >{is_loading ? "Загрузка..." : children}</button>
}

export default LoginContainer;
export { loginIntoTelegram, connectTelegram, DisconnectButton, ConnectGoogleButton };
