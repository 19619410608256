import clsx from "clsx";
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from "react";

export function LabeledInput(props) {
  const [focused, setFocused] = useState(false);
  const [value, setValue] = useState(props.defaultValue ?? "");

  const hasError = (props.hasError === undefined ? Boolean(props.errorText) : props.hasError);

  return <div className={clsx("input-container", props.containerClassName, hasError && "error", focused && "focus")}>
    <span>{props.title}</span>
    <div className={clsx("input-main", props.mainClassName)}>
      <input
        type="text"
        id={clsx(props.id)}
        placeholder={props.placeholder}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        maxLength={props.maxLength || 256}
        value={value}
        spellCheck={false}
        autoComplete="off"
        onInput={data => props.onChange?.(data)}
        onChange={event => setValue(event.target.value)}
      />
      <span className="input-error-label">{props.errorText}</span>
    </div>
  </div>;
}

export function LabeledNumberInput({
  defaultValue,
  hasError,
  errorText,
  containerClassName,
  title,
  mainClassName,
  id,
  placeholder,
  max,
  onChange
}) {
  const [focused, setFocused] = useState(false)
  const [value, setValue] = useState(defaultValue ?? "")

  hasError = (hasError === undefined ? Boolean(errorText) : hasError)

  return (
    <div className={clsx("input-container", containerClassName, hasError && "error", focused && "focus")}>
      <span>{title}</span>
      <div className={clsx("input-main", mainClassName)}>
        <input
          type="number"
          id={clsx(id)}
          placeholder={placeholder}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          value={value}
          max={max}
          autoComplete="off"
          onInput={data => onChange?.(data)}
          onChange={event => setValue(event.target.value)}
        />
        <span className="input-error-label">{errorText}</span>
      </div>
    </div>
  )
}

export function DatetimeLabeledInput(props) {
  const [focused, setFocused] = useState(false);
  const [value, setValue] = useState(props.defaultValue ?? "");

  const hasError = (props.hasError === undefined ? Boolean(props.errorText) : props.hasError);

  return <div className={clsx("input-container", props.containerClassName, hasError && "error", focused && "focus")}>
    <span>{props.title}</span>
    <div className={clsx("input-main", props.mainClassName)}>
      <input
        type="datetime-local"
        id={clsx(props.id)}
        placeholder={props.placeholder}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        maxLength={props.maxLength || 256}
        value={value}
        spellCheck={false}
        autoComplete="off"
        onInput={data => props.onChange?.(data)}
        onChange={event => setValue(event.target.value)}
      />
      <span className="input-error-label">{props.errorText}</span>
    </div>
  </div>;
}

export function TextLabeledInput(props) {
  const [focused, setFocused] = useState(false);
  const [value, setValue] = useState(props.defaultValue ?? "");

  const ref = useRef();

  const transformScroll = event => {
    ref.current.scrollTop += event.deltaY;
    event.preventDefault();
  }

  useEffect(() => {
    const textarea = ref.current;
    textarea.addEventListener("wheel", transformScroll);
    return () => {
      textarea.removeEventListener("wheel", transformScroll);
    }
  }, []);

  const hasError = (props.hasError === undefined ? Boolean(props.errorText) : props.hasError);

  return <div className={clsx("input-container", props.containerClassName, hasError && "error", focused && "focus")}>
    <span>{props.title}</span>
    <div className={clsx("input-main", "textarea", props.mainClassName)}>
      <textarea
        ref={ref}
        id={clsx(props.id)}
        placeholder={props.placeholder}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        maxLength={props.maxLength || 1024}
        value={value}
        onInput={data => props.onChange?.(data)}
        onChange={event => setValue(event.target.value)}
      />
      <span className="input-error-label">{props.errorText}</span>
    </div>
  </div>;
}

export function UnlabeledInput(props) {
  const [focused, setFocused] = useState(false);

  return <input
    type={props.type ?? "text"}
    id={clsx(props.id)}
    className={clsx("input-unlabeled", props.className, props.hasError && "error", focused && "focus")}
    placeholder={props.placeholder}
    onFocus={() => setFocused(true)}
    onBlur={() => setFocused(false)}
    maxLength={props.maxLength || 256}
    defaultValue={props.defaultValue ?? ""}
    spellCheck={false}
    autoComplete="off"
    onInput={data => props.onChange?.(data)}
  />;
}

LabeledInput.propTypes = {
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  mainClassName: PropTypes.string,
  containerClassName: PropTypes.string,
  errorText: PropTypes.string,
  hasError: PropTypes.bool,
  title: PropTypes.string.isRequired,
  maxLength: PropTypes.number,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
  validate: PropTypes.func,
}

LabeledNumberInput.propTypes = {
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.number,
  mainClassName: PropTypes.string,
  containerClassName: PropTypes.string,
  errorText: PropTypes.string,
  hasError: PropTypes.bool,
  title: PropTypes.string.isRequired,
  max: PropTypes.number,
  defaultValue: PropTypes.number,
  onChange: PropTypes.func,
}

TextLabeledInput.propTypes = {
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  mainClassName: PropTypes.string,
  containerClassName: PropTypes.string,
  errorText: PropTypes.string,
  hasError: PropTypes.bool,
  title: PropTypes.string.isRequired,
  maxLength: PropTypes.number,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
}

UnlabeledInput.propTypes = {
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  hasError: PropTypes.bool,
  maxLength: PropTypes.number,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
  validate: PropTypes.func,
  type: PropTypes.string,
}
